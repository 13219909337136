const Joi = require('joi');
const body = $('body');

//Document ready
$(document).ready(function() {
    //Validate login form (if loaded in)
    checkAccountLoginForm();

    //Validate registration form (if loaded in)
    checkAccountRegisterForm();

    //Start the resend timer refresh
    startAccountEmailVerificationResendTimer();

    //Start checker timer
    startAccountEmailVerificationCheckTimer();

    //Start checker timer
    startAccountPaymentConfirmationCheckTimer();

    //Validate password reset form (if loaded in)
    checkAccountPasswordReset();
});

//#region Login form

//Check login form when inputs change
body.on("input", "#account-login-form .input input[name=loginEmail], #account-login-form .input input[name=loginPassword]", function(e) {
    //Check form (e.g. disable submit button if a field is empty)
    checkAccountLoginForm();
});

//Submit login form
body.on("submit", "#account-login-form form", function(e) {
    e.preventDefault();

    //Get form data
    let formData = $(this).serializeArray();

    //Validation schema
    const schema = Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }).max(32).required().messages({
            'string.email': 'Please enter a valid email address',
        }),
        password: Joi.string().max(32).required()
    });

    //Validate inputs
    const { error } = schema.validate({
        email: $("#account-login-form .input input[name=loginEmail]").val(),
        password: $("#account-login-form .input input[name=loginPassword]").val()
    });

    //Hide any existing error messages
    $("#account-login-form .input .error").addClass("hide");

    //Handle errors
    if(typeof error !== "undefined"){
        let errorMessageElement = null;
        const errorMessage = error.details[0].message;
        const fieldName = error.details[0].path[0];

        //Get correct error message element
        switch(fieldName){
            case "password":
                errorMessageElement = $("#account-login-form .input input[name=loginPassword]").siblings(".error");
                break;
            case "email":
            default:
                errorMessageElement = $("#account-login-form .input input[name=loginEmail]").siblings(".error");
                break;
        }

        //Change error message
        errorMessageElement.text(errorMessage);

        //Show error message
        errorMessageElement.removeClass("hide");
    } else {
        //Clear an existing error messages
        $("#account-login-form .error-block").addClass("hide");
        $("#account-login-form .error-block .title").text("");
        $("#account-login-form .error-block .description").text("");

        //Set button state
        let submitButton = $(this).find("button[type=submit]");
        submitButton.addClass("loading");

        //Set input states
        $("#account-login-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-login-form .input input").removeAttr('disabled');

                if(data.success){
                    document.location.href = data.returnUrl;
                } else {
                    $("#account-login-form .error-block .title").text(data.title);
                    $("#account-login-form .error-block .description").append(data.message);
                    $("#account-login-form .error-block").removeClass("hide");
                }
            }
        });
    }
});

//#endregion

//#region Registration form

//Check the form every time a change is made to the inputs
body.on("input", "#account-register-form .input input[name=registerEmail], #account-register-form .input input[name=registerPassword], #account-register-form .input input[name=registerConfirmPassword]", function(e) {
    //Check login form
    checkAccountRegisterForm();
});

//AJAX form submit
body.on("submit", "#account-register-form form", function(e) {
    e.preventDefault();

    //Get form data
    let formData = $(this).serializeArray();

    //Validation schema
    const schema = Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }).max(32).required().messages({
            'string.email': 'Please enter a valid email address',
        }),
        password: Joi.string().min(6).max(32).required().messages({
            'string.min': 'Password must be at least 6 characters long'
        }),
        confirmPassword: Joi.string().max(32).required().valid(Joi.ref('password')).messages({
            'any.only': 'Passwords do not match'
        })
    });

    //Validate inputs
    const { error } = schema.validate({
        email: $("#account-register-form .input input[name=registerEmail]").val(),
        password: $("#account-register-form .input input[name=registerPassword]").val(),
        confirmPassword: $("#account-register-form .input input[name=registerConfirmPassword]").val()
    });

    //Hide any existing error messages
    $("#account-register-form .input .error").addClass("hide");

    //Handle errors
    if(typeof error !== "undefined") {
        let errorMessageElement = null;
        const errorMessage = error.details[0].message;
        const fieldName = error.details[0].path[0];

        //Get correct error message element
        switch (fieldName) {
            case "confirmPassword":
                errorMessageElement = $("#account-register-form .input input[name=registerConfirmPassword]").siblings(".error");
                break;
            case "password":
                errorMessageElement = $("#account-register-form .input input[name=registerPassword]").siblings(".error");
                break;
            case "email":
            default:
                errorMessageElement = $("#account-register-form .input input[name=registerEmail]").siblings(".error");
                break;
        }

        //Change error message
        errorMessageElement.text(errorMessage);

        //Show error message
        errorMessageElement.removeClass("hide");
    } else {
        //Set button state
        let submitButton = $(this).find("button[type=submit]");
        submitButton.addClass("loading");

        //Set input states
        $("#account-register-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                console.log(data);

                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-register-form .input input").removeAttr('disabled');

                $("#account-register-form .error-block").addClass("hide");
                $("#account-register-form .error-block .title").text("");
                $("#account-register-form .error-block .description").text("");

                if(data.success){
                    if(data.reason === "account_made_go_to_verification"){
                        window.location.replace(data.url);
                    }
                } else {
                    $("#account-register-form .error-block .description").append(data.message);
                    $("#account-register-form .error-block").removeClass("hide");
                }

            }
        });
    }
});

//#endregion



//Password reset page
$(document).ready(function() {
    //Enable jQuery validate on login form
    // $("#account-password-reset-form form").validate();

    //Check the form every time a change is made to the inputs
    body.on("input", "#account-password-reset-form .input input[name=registeredEmail]", function(e) {
        //Check login form
        checkAccountPasswordReset();
    });

    //AJAX form submit
    body.on("submit", "#account-password-reset-form form", function(e) {
        e.preventDefault();

        $("#account-password-reset-form .success-block").addClass("hide");

        //Get form data
        let formData = $(this).serializeArray();
        formData = formData.concat([
            {
                name: "action",
                value: "send"
            }
        ]);

        //Set button state
        let submitButton = $("#account-password-reset-form .password-reset-button");
        submitButton.addClass("loading");
        submitButton.attr("disabled", true);

        //Set input states
        $("#account-password-reset-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-password-reset-form .input input").removeAttr('disabled');
                $("#account-password-reset-form .error-block").addClass("hide");
                $("#account-password-reset-form .error-block .title").text("");
                $("#account-password-reset-form .error-block .description").text("");

                if(data.success){
                    //Reset all fields
                    $("#account-password-reset-form .input input").val("");

                    $("#account-password-reset-form .success-block").removeClass("hide");
                } else {
                    $("#account-password-reset-form .error-block .title").append(data.title);
                    $("#account-password-reset-form .error-block .description").append(data.message);
                    $("#account-password-reset-form .error-block").removeClass("hide");
                }
                console.log(data);
            }
        });
    });
});

//Password reset change page
body.on("submit", "#account-password-reset-change-form form", function(e) {
    e.preventDefault();

    //Validation schema
    const schema = Joi.object({
        password: Joi.string().min(6).max(32).required().messages({
            'string.min': 'Password must be at least 6 characters long'
        }),
        confirmPassword: Joi.string().max(32).required().valid(Joi.ref('password')).messages({
            'any.only': 'Passwords do not match'
        })
    });

    //Validate inputs
    const { error } = schema.validate({
        password: $("#account-password-reset-change-form .input input[name=newPassword]").val(),
        confirmPassword: $("#account-password-reset-change-form .input input[name=newConfirmPassword]").val()
    });

    //Hide any existing error messages
    $("#account-password-reset-change-form .input .error").addClass("hide");

    //Handle errors
    if(typeof error !== "undefined") {
        let errorMessageElement = null;
        const errorMessage = error.details[0].message;
        const fieldName = error.details[0].path[0];

        //Get correct error message element
        switch (fieldName) {
            case "password":
                errorMessageElement = $("#account-password-reset-change-form .input input[name=newPassword]").siblings(".error");
                break;
            case "confirmPassword":
            default:
                errorMessageElement = $("#account-password-reset-change-form .input input[name=newConfirmPassword]").siblings(".error");
                break;
        }

        //Change error message
        errorMessageElement.text(errorMessage);

        //Show error message
        errorMessageElement.removeClass("hide");
    } else {

        //Get elements
        let successBlock = $("#account-password-reset-change-form .success-block");
        let submitButton = $(this).find("button[type=submit]");

        //Get form data
        let formData = $(this).serializeArray();
        formData = formData.concat([
            {
                name: "action",
                value: "reset"
            },
            {
                name: "token",
                value: $("#account-password-reset-change-form #token").val()
            }
        ]);

        //Set button state
        submitButton.addClass("loading");

        //Set block states
        successBlock.addClass("hide");

        //Set input states
        $("#account-password-reset-change-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-password-reset-change-form .input input").removeAttr('disabled');

                //Hide submit button
                $("#account-password-reset-change-form .password-reset-change-button").hide();

                //Hide and reset the error message
                $("#account-password-reset-change-form .error-block").addClass("hide");
                $("#account-password-reset-change-form .error-block .title").text("");
                $("#account-password-reset-change-form .error-block .description").text("");

                if(data.success){
                    //Show success message
                    successBlock.removeClass("hide");

                    //Show account link
                    $("#account-password-reset-change-form .account-link-button").removeClass("hide");

                    //Hide fields
                    $("#account-password-reset-change-form form .block").hide();
                } else {
                    $("#account-password-reset-change-form .error-block .description").append(data.message);
                    $("#account-password-reset-change-form .error-block").removeClass("hide");
                }

            }
        });
    }
});

//Account page - password reset
$(document).ready(function() {
    //Enable jQuery form validate
    // $("#account-page-change-password").validate();

    //AJAX form submit
    body.on("submit", "#account-page-change-password", function(e) {
        e.preventDefault();

        //Get form data
        let formData = $(this).serializeArray();
        formData = formData.concat([
            {
                name: "action",
                value: "changePassword"
            }
        ]);

        //Hide and reset the error message
        $("#account-page-change-password .error-block").addClass("hide");
        $("#account-page-change-password .error-block .description").text("");

        //Hide the success message
        $("#account-page-change-password .success-block").addClass("hide");

        //Set input states
        $("#account-page-change-password input, #account-page-change-password button").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set input states
                $("#account-page-change-password input, #account-page-change-password button").removeAttr('disabled');

                if(data.success){
                    //Show success message
                    $("#account-page-change-password .success-block").removeClass("hide");

                    //Clear all inputs
                    $("#account-page-change-password input").val("");
                } else {
                    //Hide and reset the error message
                    $("#account-page-change-password .error-block").removeClass("hide");
                    $("#account-page-change-password .error-block .description").text(data.message);
                }
            }
        });
    });

});

//Resend email verification email
body.on("click", ".email-verification-page .resend-button", function(e) {
    e.preventDefault();

    //Get elements
    let resendNotice = $(".email-verification-page .resend-notice");
    let button = $(this);

    //Disable the button
    button.prop('disabled', true);

    //Clear existing notice
    resendNotice.text("");
    resendNotice.removeClass("error");
    resendNotice.removeClass("success");

    let formData = {
        action: "resend",
        publicToken: $(".email-verification-page #public-token").val()
    };

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: formData,
        success: function (data) {
            //Enable button again
            button.removeAttr("disabled");

            //Handle response
            if(data.success){
                resendNotice.text("We have resent your verification email.");
                resendNotice.addClass("success");
                $(".email-verification-page .resend-button").data("resend-timestamp", data.resend_available);

                if(data.can_resend){
                    startAccountEmailVerificationResendTimer();
                } else {
                    button.prop('disabled', true);
                }

            } else {
                switch(data.reason){
                    case "missing_token":
                        resendNotice.text("Missing public token. Refresh page and try again.");
                        resendNotice.addClass("error");
                        break;
                    case "not_found":
                        resendNotice.text("Email verification not found. Refresh page and try again.");
                        resendNotice.addClass("error");
                        break;
                    case "not_needing_verified":
                        resendNotice.text("Email is not waiting to be verified. Refresh page and try again.");
                        resendNotice.addClass("error");
                        break;
                    case "too_soon":
                        resendNotice.text("Attempting to resend too soon. Please wait and try again later.");
                        resendNotice.addClass("error");
                        break;
                    case "too_many_emails":
                        resendNotice.text("You have reached the maximum number of email resends. Please contact us.");
                        resendNotice.addClass("error");
                        break;
                }
            }
        }
    });
});

//Account page
body.on("click", ".account-page .nav-tabs-row .nav-tabs .nav-item button", function(e){
    //Skip if already active
    if($(this).parent().hasClass("active")) return;

    //Remove the existing active state
    $(".account-page .nav-tabs-row .nav-tabs .nav-item.active").removeClass("active");

    //Add active state to clicked button
    $(this).parent().addClass("active");

    //Get the active page name
    let page = $(this).data("page");

    //Close an existing open pages
    $(".account-page .pages-container .page.open").removeClass("open");

    //Open the clicked button
    $(".account-page .pages-container .page[data-page='" + page + "']").addClass("open");

    //Add url fragment
    window.location.hash = page;
});

$(document).ready(function() {
    //Check to see if on accounts page
    if($(".account-page").length < 1) return;

    //Handle opening tabs if in URL
    if(window.location.hash){
        let page = window.location.hash.replace('#','');

        //Close open page
        $(".account-page .pages-container .page.open").removeClass("open");

        //Deactivate open button
        $(".account-page .nav-tabs-row .nav-tabs .nav-item.active").removeClass("active");

        //Open page content
        $(".account-page .pages-container .page[data-page='" + page + "']").addClass("open");

        //Active open page button
        $(".account-page .nav-tabs-row .nav-tabs .nav-item button[data-page='" + page + "']").parent().addClass("active");
    }
});

//Invoice table pagination
body.on("click", ".account-page .page[data-page='billing'] .table-pagination .page-links button", function(e) {
    e.preventDefault();

    //Destination
    let destination = $(this).data("go-to-page");

    //No destination found
    if(destination === undefined){
        return;
    }

    //Clear existing
    $(".account-page .page[data-page='billing'] .invoices-table tbody tr").not(".loading").remove();

    //Enable loader
    $(".account-page .page[data-page='billing'] .invoices-table tbody .loading").removeClass("hide");

    //Disable all pagination buttons
    $(".account-page .page[data-page='billing'] .table-pagination .page-links button").attr("disabled", true);

    //Get next page info
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: [
            {
                name: "action",
                value: "invoicesTablePage"
            },
            {
                name: "page",
                value: destination
            }
        ],
        success: function (data) {
            //Enable all pagination buttons
            $(".account-page .page[data-page='billing'] .table-pagination .page-links button").prop("disabled", false);

            //Disable loader
            $(".account-page .page[data-page='billing'] .invoices-table tbody .loading").addClass("hide");

            //Load rows
            let invoiceCount = data.invoices.length;
            for (let i = 0; i < invoiceCount; i++) {
                let invoice = data.invoices[i];
                $(".account-page .page[data-page='billing'] .invoices-table tbody").append("<tr><td>" + invoice.reference + "</td><td><span class='paid-badge'>" + invoice.status + "</span></td><td>" + invoice.total + "</td><td>" + invoice.timestamp  + " - " + invoice.timeSince + "</td><td></td></tr>");
            }

            //Page number
            let pageNumber = parseInt(data.pageNumber);

            //Update results count
            $(".account-page .page[data-page='billing'] .table-pagination .results-count").text("Showing " + data.fromCount + "-" + (data.fromCount + invoiceCount - 1) + " of " + data.invoiceCount);

            //Get pagination elements
            let previousPreviousButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .previousPrevious");
            let previousButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .previous");
            let nextButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .next");
            let nextNextButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .nextNext");
            let backTwoButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .back-two");
            let backOneButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .back-one");
            let forwardOneButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .forward-one");
            let forwardTwoButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .forward-two");

            //Update current button number
            $(".account-page .page[data-page='billing'] .table-pagination .page-links .number-button.current").text(pageNumber);

            //Update pagination buttons
            if(data.pagination.previousPrevious){
                previousPreviousButton.prop("disabled", false);
                previousPreviousButton.data("go-to-page", pageNumber - 2);
            } else {
                previousPreviousButton.attr("disabled", true);
            }

            if(data.pagination.previous){
                previousButton.prop("disabled", false);
                previousButton.data("go-to-page", pageNumber - 1);
            } else {
                previousButton.attr("disabled", true);
            }

            if(data.pagination.next){
                nextButton.prop("disabled", false);
                nextButton.data("go-to-page", pageNumber + 1);
            } else {
                nextButton.attr("disabled", true);
            }

            if(data.pagination.nextNext){
                nextNextButton.prop("disabled", false);
                nextNextButton.data("go-to-page", pageNumber + 2);
            } else {
                nextNextButton.attr("disabled", true);
            }

            //Update pagination numbers
            if(pageNumber > 2){
                backTwoButton.removeClass("hide");
                backTwoButton.text(pageNumber - 2);
                backTwoButton.data("go-to-page", pageNumber - 2);
            } else {
                backTwoButton.addClass("hide");
            }

            if(pageNumber > 1){
                backOneButton.removeClass("hide");
                backOneButton.text(pageNumber - 1);
                backOneButton.data("go-to-page", pageNumber - 1);
            } else {
                backOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 1){
                forwardOneButton.removeClass("hide");
                forwardOneButton.text(pageNumber + 1);
                forwardOneButton.data("go-to-page", pageNumber + 1);
            } else {
                forwardOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 2){
                forwardTwoButton.removeClass("hide");
                forwardTwoButton.text(pageNumber + 2);
                forwardTwoButton.data("go-to-page", pageNumber + 2);
            } else {
                forwardTwoButton.addClass("hide");
            }
        }
    });
});

//Survey table pagination
body.on("click", ".account-page .page[data-page='surveys'] .table-pagination .page-links button", function(e) {
    e.preventDefault();

    //Destination
    let destination = $(this).data("go-to-page");

    //No destination found
    if(destination === undefined){
        return;
    }

    //Clear existing
    $(".account-page .page[data-page='surveys'] .surveys-table tbody tr").not(".loading").remove();

    //Enable loader
    $(".account-page .page[data-page='surveys'] .surveys-table tbody .loading").removeClass("hide");

    //Disable all pagination buttons
    $(".account-page .page[data-page='surveys'] .table-pagination .page-links button").attr("disabled", true);

    //Get next page info
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: [
            {
                name: "action",
                value: "surveysTablePage"
            },
            {
                name: "page",
                value: destination
            }
        ],
        success: function (data) {
            //Enable all pagination buttons
            $(".account-page .page[data-page='surveys'] .table-pagination .page-links button").prop("disabled", false);

            //Disable loader
            $(".account-page .page[data-page='surveys'] .surveys-table tbody .loading").addClass("hide");

            //Load rows
            let surveyCount = data.surveys.length;
            for (let i = 0; i < surveyCount; i++) {
                let survey = data.surveys[i];
                $(".account-page .page[data-page='surveys'] .surveys-table tbody").append("<tr><td>" + survey.id + "</td><td>" + survey.timestamp  + " - " + survey.timeSince + "</td><td><a href=\"./overview?id=" + survey.id + "\" target=\"_blank\" class=\"view-survey\">View results</a></td></tr>");
            }

            //Page number
            let pageNumber = parseInt(data.pageNumber);

            //Update results count
            $(".account-page .page[data-page='surveys'] .table-pagination .results-count").text("Showing " + data.fromCount + "-" + (data.fromCount + surveyCount - 1) + " of " + data.surveyCount);

            //Get pagination elements
            let previousPreviousButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .previousPrevious");
            let previousButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .previous");
            let nextButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .next");
            let nextNextButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .nextNext");
            let backTwoButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .back-two");
            let backOneButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .back-one");
            let forwardOneButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .forward-one");
            let forwardTwoButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .forward-two");

            //Update current button number
            $(".account-page .page[data-page='surveys'] .table-pagination .page-links .number-button.current").text(pageNumber);

            //Update pagination buttons
            if(data.pagination.previousPrevious){
                previousPreviousButton.prop("disabled", false);
                previousPreviousButton.data("go-to-page", pageNumber - 2);
            } else {
                previousPreviousButton.attr("disabled", true);
            }

            if(data.pagination.previous){
                previousButton.prop("disabled", false);
                previousButton.data("go-to-page", pageNumber - 1);
            } else {
                previousButton.attr("disabled", true);
            }

            if(data.pagination.next){
                nextButton.prop("disabled", false);
                nextButton.data("go-to-page", pageNumber + 1);
            } else {
                nextButton.attr("disabled", true);
            }

            if(data.pagination.nextNext){
                nextNextButton.prop("disabled", false);
                nextNextButton.data("go-to-page", pageNumber + 2);
            } else {
                nextNextButton.attr("disabled", true);
            }

            //Update pagination numbers
            if(pageNumber > 2){
                backTwoButton.removeClass("hide");
                backTwoButton.text(pageNumber - 2);
                backTwoButton.data("go-to-page", pageNumber - 2);
            } else {
                backTwoButton.addClass("hide");
            }

            if(pageNumber > 1){
                backOneButton.removeClass("hide");
                backOneButton.text(pageNumber - 1);
                backOneButton.data("go-to-page", pageNumber - 1);
            } else {
                backOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 1){
                forwardOneButton.removeClass("hide");
                forwardOneButton.text(pageNumber + 1);
                forwardOneButton.data("go-to-page", pageNumber + 1);
            } else {
                forwardOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 2){
                forwardTwoButton.removeClass("hide");
                forwardTwoButton.text(pageNumber + 2);
                forwardTwoButton.data("go-to-page", pageNumber + 2);
            } else {
                forwardTwoButton.addClass("hide");
            }
        }
    });
});

//Purchase credits page
body.on("click", "#purchase-credits .quantity-field .increment-buttons .add, #purchase-credits .quantity-field .increment-buttons .subtract", function(e){
    //Get quantity input
    let input = $("#purchase-credits .quantity-field .quantity-input");

    //Current quantity
    let currentQuantity = parseInt(input.val());
    if(isNaN(currentQuantity)) {
        currentQuantity = 0;
    }

    //Quick button has been pressed
    if($(this).hasClass("add")){
        input.val( currentQuantity + 1);
    } else {
        if(currentQuantity > 0){
            input.val( currentQuantity - 1);
        }
    }

    //Refresh the purchase credit form
    refreshPurchaseCreditForm();
});

body.on("input", "#purchase-credits .quantity-field .quantity-input, #purchase-credits .payment-methods .radio-input", function(e) {
    //Refresh the purchase credit form
    refreshPurchaseCreditForm();
});

body.on("input", "#purchase-credits .promo-input", function(e) {
    //Get promo code input value
    let promoCode = $(this).val();

    //Disable apply button if no code inputted
    if (promoCode === ""){
        $("#purchase-credits .apply-discount").prop("disabled", true);
    } else {
        $("#purchase-credits .apply-discount").removeAttr('disabled');
    }
});

body.on("keypress", "#purchase-credits .promo-input", function(e) {
    if (e.which === 13) {
        $("#purchase-credits .apply-discount").click();
    }
});

body.on("click", "#purchase-credits .apply-discount", function(e) {
    //Get elements
    let discountMessage = $("#purchase-credits .discount-message");
    let discountInput = $("#purchase-credits .promo-input");
    let applyButton = $(this);

    //Disable apply button until request is complete
    applyButton.prop("disabled", true);

    //Get promo code input value
    let promoCode = discountInput.val();

    //Hide any existing messages
    discountMessage.addClass("hide");
    discountMessage.removeClass("error");
    discountMessage.removeClass("success");
    discountMessage.text("");

    let formData = [
        {
            name: "action",
            value: "applydiscount"
        },
        {
            name: "code",
            value: promoCode
        }
    ];

    $.ajax({
        type: "POST",
        url: window.location.href,
        data: formData,
        success: function (data) {
            //Re-enable apply button
            applyButton.removeAttr('disabled');

            if(data.success){
                //Save discount details
                discountInput.data("type", data.type);
                discountInput.data("amount", data.amount);

                //Set success message
                discountMessage.text(data.message);
                discountMessage.removeClass("hide");
                discountMessage.addClass("success");

                //Refresh the purchase credit form
                refreshPurchaseCreditForm();
            } else {
                //Set error message
                discountMessage.text(data.message);
                discountMessage.removeClass("hide");
                discountMessage.addClass("error");

                //Clear input
                discountInput.val("");
            }
        }
    });
});

body.on("click", "#purchase-credits .remove-discount", function(e) {
    //Get elements
    let discountInput = $("#purchase-credits .discount-field .promo-input");
    let discountMessage = $("#purchase-credits .discount-message");

    //Remove discount from input
    discountInput.val("");

    //Delete discount info
    discountInput.data('type', '');
    discountInput.data('amount', '');

    //Hide any existing messages
    discountMessage.addClass("hide");
    discountMessage.removeClass("error");
    discountMessage.removeClass("success");
    discountMessage.text("");

    //Clear breakdown row
    $("#purchase-credits #account-credit-discount").siblings(".left").text("Discount");

    //Refresh credit form
    refreshPurchaseCreditForm();
});

body.on("click", "#purchase-credits .checkout-button", function(e) {
    //Get elements
    let quantityInput = $("#purchase-credits .quantity-input");
    let quantityButtons = $("#purchase-credits .quantity-field .increment-buttons .add, #purchase-credits .quantity-field .increment-buttons .subtract")
    let discountInput = $("#purchase-credits .discount-field .promo-input");
    let discountButtons = $("#purchase-credits .discount-field .apply-discount, #purchase-credits .discount-field .remove-discount");
    let paymentMethodRadio= $("#purchase-credits .payment-methods .radio-input");
    let checkoutButton = $(this);

    //Get values
    let quantity = quantityInput.val();
    let paymentMethod = $("#purchase-credits .payment-methods .radio-input:checked").val();
    let discountCode = "";

    //Retrieve discount code if one is set
    if($("#purchase-credits .discount-field").hasClass("discount-applied")){
        discountCode = discountInput.val();
    }

    //Disable inputs
    quantityInput.prop("disabled", true);
    discountInput.prop("disabled", true);
    discountButtons.prop("disabled", true);
    quantityButtons.prop("disabled", true);
    paymentMethodRadio.prop("disabled", true);
    checkoutButton.prop("disabled", true);

    //Hide any existing error messages
    $("#purchase-credits .error-message .message").text("");
    $("#purchase-credits .error-message").addClass("hide");

    let formData = [
        {
            name: "action",
            value: "checkout"
        },
        {
            name: "quantity",
            value: quantity
        },
        {
            name: "discountCode",
            value: discountCode
        },
        {
            name: "paymentMethod",
            value: paymentMethod
        }
    ];

    $.ajax({
        type: "POST",
        url: window.location.href,
        data: formData,
        success: function (data) {
            console.log(data);
            if(data.success){
                window.location.replace(data.paymentUrl);
            } else {
                switch(data.reason){
                    case "invalid_discount_code":
                        //Show error message
                        $("#purchase-credits .error-message .message").text(data.message);
                        $("#purchase-credits .error-message").removeClass("hide");

                        //Remove discount
                        $("#purchase-credits .discount-field").removeClass("discount-applied");
                        $("#purchase-credits .discount-field .promo-input").removeAttr('disabled').val("").data("type", "").data("amount", "");

                        //Hide any existing messages
                        $("#purchase-credits .discount-message").addClass("hide").removeClass("error").removeClass("success").text("");
                        break;
                    default:
                        //Show error message
                        $("#purchase-credits .error-message .message").text(data.message);
                        $("#purchase-credits .error-message").removeClass("hide");
                        break;
                }

                //Enable inputs
                quantityInput.removeAttr('disabled');
                discountInput.removeAttr('disabled');
                discountButtons.removeAttr('disabled');
                quantityButtons.removeAttr('disabled');
                paymentMethodRadio.removeAttr('disabled');
                checkoutButton.removeAttr('disabled');

                //Refresh credit form
                refreshPurchaseCreditForm();
            }
        }
    });
});

//Redeem credits code
body.on("input", "#redeem-credits-code .code-field .code-section input", function(e) {
    let dataSection = $(this).data("section");
    let inputValueLength = $(this).val().length;

    if(inputValueLength === 5 && dataSection !== 4){
        //Move cursor to next input
        $("#redeem-credits-code .code-field .code-section input[data-section=" + (dataSection+1) + "]").focus();
    }

    checkAccountRedeemCreditsCodeForm();
});


body.on("click", "#redeem-credits-code .redeem-code-button", function(e){
    let redeemCodeButton = $(this);
    let codeInputs = $("#redeem-credits-code .code-field .code-section input");
    let code = "";

    //Disable submit button
    redeemCodeButton.prop('disabled', true);

    //Hide error message
    $("#redeem-credits-code .error-message .description").text("");
    $("#redeem-credits-code .error-message").addClass("hide");

    //Hide success message
    $("#redeem-credits-code .success-block").addClass("hide");

    //Loop inputs
    codeInputs.each(function() {
        code = code + $(this).val();
    });

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            action: "redeemCode",
            code: code
        },
        success: function (data) {
            if(data.success){
                $("#redeem-credits-code .success-block .creditAmount").text(data.creditsGiven);
                $("#redeem-credits-code .success-block").removeClass("hide");
            } else {
                //Show error message
                $("#redeem-credits-code .error-message .description").text(data.message);
                $("#redeem-credits-code .error-message").removeClass("hide");
            }

            //Clear inputs
            codeInputs.val("");
        }
    });
});

//Functions
function checkAccountRegisterForm() {
    //Skip when no register form exists
    if($("#account-register-form").length === 0) return;

    //Input elements
    let emailInput = $("#account-register-form .input input[name=registerEmail]");
    let passwordInput = $("#account-register-form .input input[name=registerPassword]");
    let confirmPasswordInput = $("#account-register-form .input input[name=registerConfirmPassword]");
    let submitButton = $("#account-register-form .register-button");

    //Variables
    let submitButtonDisable = false;

    //Validate email input
    if(emailInput.val() === ""){
        submitButtonDisable = true;
    }

    //Validate password input
    if(passwordInput.val() === ""){
        submitButtonDisable = true;
    }

    //Validate confirm password input
    if(confirmPasswordInput.val() === ""){
        submitButtonDisable = true;
    }

    //Make state changes
    if(submitButtonDisable){
        submitButton.prop('disabled', true);
    } else if(!submitButton.hasClass("loading")) {
        submitButton.removeAttr('disabled');
    }
}

function checkAccountLoginForm() {
    //Skip when no login form exists
    if($("#account-login-form").length === 0) return;

    //Variables
    let submitButtonDisable = false;

    //Input elements
    let emailInput = $("#account-login-form .input input[name=loginEmail]");
    let passwordInput = $("#account-login-form .input input[name=loginPassword]");
    let submitButton = $("#account-login-form .login-button");

    //Validate email input
    if(emailInput.val() === ""){
        submitButtonDisable = true;
    }

    //Validate password input
    if(passwordInput.val() === ""){
        submitButtonDisable = true;
    }

    //Make state changes
    if(submitButtonDisable){
        submitButton.prop('disabled', true);
    } else {
        submitButton.removeAttr('disabled');
    }
}


function startAccountEmailVerificationResendTimer() {
    //Skip when no login form exists
    if($(".email-verification-page").length === 0 || $(".email-verification-page .resend-button").hasClass("noresend")) return;

    //Resend button
    let resendButton = $(".email-verification-page .resend-button");

    //Amount of seconds remaining till the email can be resent
    let resendAvailable = resendButton.data("resend-timestamp");

    if(resendAvailable > 0){
        //Disable button
        resendButton.attr("disabled", true);

        //Change button text
        resendButton.text("Resend email (" + resendAvailable + " secs)");

        //Start update time
        let loop = setInterval(updateResendButton, 1000);
        function updateResendButton() {
            resendAvailable -= 1;
            if(resendAvailable < 1){
                resendButton.removeAttr('disabled');
                resendButton.text("Resend email");

                //Clear existing notice
                let resendNotice = $(".email-verification-page .resend-notice");
                resendNotice.text("");
                resendNotice.removeClass("error");
                resendNotice.removeClass("success");

                clearInterval(loop);
            } else {
                resendButton.text("Resend email (" + resendAvailable + " secs)");
            }
        }
    }
}

function startAccountPaymentConfirmationCheckTimer(){
    //Skip when no login form exists
    if($(".account-generic-container .waiting-confirmation").length === 0) return;

    //Start update time
    let loop = setInterval(checkStatus, 5000);
    function checkStatus() {
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: {
                action: "paymentcheck",
                stripeId: $(".account-generic-container .waiting-confirmation #stripeId").val()
            },
            success: function (data) {
                console.log(data);


                if(data.success){
                    if(data.paid === 1){
                        //Stop looping
                        clearInterval(loop);

                        //Hide confirming payment screen
                        $(".account-generic-container .waiting-confirmation").hide();

                        //Show payment confirmed screen
                        $(".account-generic-container .payment-confirmed").css("display", "flex");
                    }
                } else {

                }
            }
        });
    }
}

function startAccountEmailVerificationCheckTimer() {
    //Skip when no login form exists
    if($(".email-verification-page").length === 0) return;

    //Start update time
    let loop = setInterval(checkStatus, 5000);
    function checkStatus() {
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: {
                action: "check",
                publicToken: $(".email-verification-page #public-token").val()
            },
            success: function (data) {
                if(data.success){
                    window.location.replace(data.url);
                } else {
                    if(data.reason !== "unverified"){
                        clearInterval(loop);
                        alert("An error with your email has occurred.")
                    }
                }
            }
        });
    }
}

function checkAccountPasswordReset() {
    //Skip when no login form exists
    if($("#account-password-reset-form").length === 0) return;

    //Input elements
    let emailInput = $("#account-password-reset-form .input input[name=registeredEmail]");
    let submitButton = $("#account-password-reset-form .password-reset-button");

    //Variables
    let submitButtonDisable = false;

    //Validate email input
    if(emailInput.val() === ""){
        submitButtonDisable = true;
    }

    //Make state changes
    if(submitButtonDisable){
        submitButton.prop('disabled', true);
    } else {
        submitButton.removeAttr('disabled');
    }
}

function refreshPurchaseCreditForm(){
    //Pricing brackets
    let brackets = [
        {
            unit_price: 15.00,
            quantity: {
                start: 0,
                end: 99
            }
        },
        {
            unit_price: 12.50,
            quantity: {
                start: 100,
                end: 999
            }
        },
        {
            unit_price: 10.00,
            quantity: {
                start: 1000
            }
        }
    ];

    //Quantity
    let quantity = parseInt($("#purchase-credits .quantity-field .quantity-input").val());
    if(isNaN(quantity)) {
        quantity = 0;
    }

    //Find pricing bracket
    let bracket = brackets[0];
    let bracketNumber = 0;
    let arrayLength = brackets.length;
    for (let i = 0; i < arrayLength; i++) {
        if('end' in brackets[i].quantity){
            //Check to see if the quantity is in the pricing bracket
            if(quantity >= brackets[i].quantity.start && quantity <= brackets[i].quantity.end){
                bracket = brackets[i];
                bracketNumber = i + 1;
            }
        } else {
            //Check to see if the quantity is in the pricing bracket
            if(quantity >= brackets[i].quantity.start){
                bracket = brackets[i];
                bracketNumber = i + 1;
            }
        }
    }

    //Activate the correct pricing tier in the interface
    $("#purchase-credits .pricing-tiers .tier.active").removeClass("active");
    $("#purchase-credits .pricing-tiers .tier[data-bracket='" + bracketNumber + "']").addClass("active");


    //Calculate price figures
    let unitPrice = bracket.unit_price;
    let subtotal = quantity * unitPrice;

    //Find discount amount
    let discount = 0
    let discountInput = $("#purchase-credits .discount-field .promo-input");
    let discountType = discountInput.data('type');
    let discountAmount = discountInput.data('amount');
    if(discountType !== ""){
        $("#purchase-credits .discount-field").addClass("discount-applied");
        discountInput.prop('disabled', true);
        if(discountType === "flat"){
            discount = discountAmount;
            //Stop discount causing negative totals
            if(discount > subtotal){
                discount = subtotal;
            }
            $("#purchase-credits #account-credit-discount").siblings(".left").text("Discount (" + discountInput.val() + " - " + discountAmount.toLocaleString("en-GB", {style:"currency", currency:"GBP"}) + " off)");
        } else {
            discount = subtotal * (discountAmount/100);
            $("#purchase-credits #account-credit-discount").siblings(".left").text("Discount (" + discountInput.val() + " - " + discountAmount.toFixed(2) + "% off)");
        }
    } else {
        $("#purchase-credits .discount-field").removeClass("discount-applied");
        discountInput.prop('disabled', false);
    }
    let tax = (subtotal - discount) * 0.2;
    let grandTotal = (subtotal - discount) + tax;

    //Update price figures in interface
    $("#purchase-credits #account-credit-subtotal").text(subtotal.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));

    if(discount > 0){
        $("#purchase-credits #account-credit-discount").text("-" + discount.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));
    } else {
        $("#purchase-credits #account-credit-discount").text(discount.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));
    }

    $("#purchase-credits #account-credit-tax").text(tax.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));
    $("#purchase-credits #account-credit-grand-total").text(grandTotal.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));

    //Check to see if a payment method has been selected
    let paymentMethodElements = $("#purchase-credits .payment-methods input[name=payment_method]");
    let paymentMethodSelected = false;
    paymentMethodElements.each(function( i ) {
        if($(this).is(':checked')){
            paymentMethodSelected = true;
        }
    });

    //If zero quantity or no payment method is selected
    if(quantity > 0 && paymentMethodSelected){
        $("#purchase-credits .checkout-button").prop('disabled', false);
    } else {
        $("#purchase-credits .checkout-button").prop('disabled', true);
    }
}

function checkAccountRedeemCreditsCodeForm() {
    //Skip when no login form exists
    if($("#redeem-credits-code").length === 0) return;

    //Input elements
    let codeInputs = $("#redeem-credits-code .code-field .code-section input");
    let redeemCodeButton = $("#redeem-credits-code .redeem-code-button");

    //Variables
    let submitButtonDisable = false;

    //Loop inputs
    codeInputs.each(function() {
        if($(this).val().length < 5){
            submitButtonDisable = true;
        }
    });

    //Make state changes
    if(submitButtonDisable){
        redeemCodeButton.prop('disabled', true);
    } else {
        redeemCodeButton.removeAttr('disabled');
    }
}

