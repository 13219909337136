/**
 *
 * Energy Monitoring Module JavaScript
 *
 */

let body = $("body");

//Open input form
body.on('input', '.module-form .form-pages .page .input-form input[name=smartMeters]', function(e) {
    const numberOfSupplyPoints = $(".module-form .form-pages .page .input-form input[name=incomingSupplyPoints]").val();
    let errorMessage = $(".module-form .form-pages .page .input-form .smart-meter-error-message");

    if($(this).val() > numberOfSupplyPoints){
        //Set error message
        errorMessage.text("You can't enter a number higher than the number of supply points (" + numberOfSupplyPoints + ").");

        //Show error message
        errorMessage.show();
        $();
    } else {
        //Hide error message
        errorMessage.hide();
    }
});
