const body = $('body');

//#region Supplier Additional Services Modal
//Show lease dates if leasehold selected
body.on('click', '.supplier-additional-services-form .dropdown#propertyOwnership .dropdown-content li', function(e) {
    if($(this).data("value") === "leasehold"){
        $(".supplier-additional-services-form #propertyLeaseEndDate").closest(".text-field").show();
    } else {
        $(".supplier-additional-services-form #propertyLeaseEndDate").closest(".text-field").hide();
    }
});

body.on('click', '.supplier-permission-modal[data-modal=solarSupplierAdditionalServices] .dismiss-button', function(e) {
    $(this).closest(".supplier-permission-modal").removeClass("open");
});

//File upload validation
body.on('change', '.supplier-additional-services-form #siteMapsImage', function(e) {
    //Upload input field
    let inputField = $(this).closest(".field");

    //Remove any existing file upload errors
    $(".supplier-additional-services-form .file-upload-error-message").remove();

    //Remove field error state
    inputField.removeClass("has-error");

    //Get uploaded file
    let file = this.files[0];

    //Max file size (Mbs)
    const maxFileSize = 10;

    //File details
    const fileName = file.name;
    const fileMbs = Math.round((file.size/1024/1024));
    const fileType = file.type;
    let hasError = false;

    //Check file type
    if(fileType !== "image/png" && fileType !== "image/jpg"){
        hasError = true;

        //Add error message
        inputField.after('<span class="field-error-message file-upload-error-message"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>Wrong file type, only JPG or PNG</span>');
    }

    //Check size
    if(fileMbs > maxFileSize && !hasError){
        hasError = true;

        //Add error message
        inputField.after('<span class="field-error-message file-upload-error-message"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>File too big, 10Mb max size.</span>');
    }

    if(hasError){
        //Add error state to field
        inputField.addClass("has-error");

        //Set file upload label back to default
        $('.supplier-additional-services-form .upload-field #file-chosen').html("Click to choose a file");

        //Remove file from input
        $(this).val("");
    } else {
        //Set file upload label
        $('.supplier-additional-services-form .upload-field #file-chosen').html(fileName);
    }
});

//Submit form
body.on('click', '#submitSupplierAdditionalServicesForm', function(e) {
    //Remove existing error messages (excluding file upload)
    $(".supplier-additional-services-form .field-error-message:not(.file-upload-error-message)").remove();

    //Remove existing field error states (excluding file upload)
    $(".supplier-additional-services-form .field.has-error:not(.upload-field)").removeClass("has-error");

    //Variables
    let hasErrors = false;

    //Validate Registered Company Address
    let registeredCompanyAddressInput = $(".supplier-additional-services-form #registeredCompanyAddress");
    let registeredCompanyAddressField = registeredCompanyAddressInput.closest(".field");
    let registeredCompanyAddressValue = registeredCompanyAddressInput.val();

    if(!registeredCompanyAddressValue.trim().length){
        hasErrors = true;

        //Add error message
        registeredCompanyAddressField.after('<span class="field-error-message"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>This field is required</span>');

        //Add error state
        registeredCompanyAddressField.addClass("has-error");
    }

    //Validate Property Ownership
    const propertyOwnershipInput = $(".supplier-additional-services-form #propertyOwnership");
    const propertyOwnershipField = propertyOwnershipInput.closest(".field");
    const propertyOwnershipValue = $(".supplier-additional-services-form #propertyOwnership .button .selected").text();

    if(propertyOwnershipValue === "Select property ownership"){
        hasErrors = true;

        //Add error message
        propertyOwnershipField.after('<span class="field-error-message"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>This field is required</span>');

        //Add error state
        propertyOwnershipField.addClass("has-error");
    }

    //Validate Leasehold Dates
    let propertyLeaseEndDateValue = null;
    if(propertyOwnershipValue === "Leasehold"){
        const propertyLeaseEndDateInput = $(".supplier-additional-services-form #propertyLeaseEndDate");
        const propertyLeaseEndDateField = propertyLeaseEndDateInput.closest(".field");
        propertyLeaseEndDateValue = propertyLeaseEndDateInput.val();

        if(!propertyLeaseEndDateValue.trim().length){
            hasErrors = true;

            //Add error message
            propertyLeaseEndDateField.after('<span class="field-error-message"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>This field is required</span>');

            //Add error state
            propertyLeaseEndDateField.addClass("has-error");
        }
    }

    //Validate System Size Method
    const systemSizeMethodInput = $(".supplier-additional-services-form #systemSizeMethod");
    const systemSizeMethodField = systemSizeMethodInput.closest(".field");
    const systemSizeMethodValue = $(".supplier-additional-services-form #systemSizeMethod .button .selected").text();

    if(systemSizeMethodValue === "How should the system be sized?"){
        hasErrors = true;

        //Add error message
        systemSizeMethodField.after('<span class="field-error-message"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>This field is required</span>');

        //Add error state
        systemSizeMethodField.addClass("has-error");
    }

    //AJAX submit
    if(!hasErrors){
        //Get the current survey's id
        const urlParams = new URLSearchParams(window.location.search);
        const surveyID = urlParams.get('id');

        //File upload
        let siteMapsImage = null;
        if($('#siteMapsImage')[0].files.length > 0){
            siteMapsImage = $('#siteMapsImage')[0].files[0];
        }

        //Format data
        let formData = new FormData();
        formData.append('action', "additionalServicesSupplierContact");
        formData.append('service', "eEnergy");
        formData.append('surveyId', surveyID);
        formData.append('installationIncluded', $("#installationIncluded").is(":checked"));
        formData.append('registeredCompanyAddress', registeredCompanyAddressValue);
        formData.append('registeredCompanyNumber', $("#registeredCompanyNumber").val());
        formData.append('propertyOwnership', propertyOwnershipValue);
        formData.append('propertyLeaseEndDate', propertyLeaseEndDateValue);
        formData.append('preferredInstallationWindow', $("#preferredInstallationWindow").val());
        formData.append('systemSizeMethod', $(".supplier-additional-services-form #systemSizeMethod .button .selected").text());
        formData.append('pvSystem', $(".supplier-additional-services-form #pvSystem").val());
        formData.append('preferredSystemSize', $("#preferredSystemSize").val());
        formData.append('file', $('#siteMapsImage')[0].files[0]);

        let submitButton = $(".supplier-permission-modal .modal-footer #submitSupplierAdditionalServicesForm");

        //Change button text
        submitButton.html("Loading...");

        //Disable button
        submitButton.prop('disabled', true);

        //Send AJAX Request
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: formData,
            contentType: false,
            processData: false,
            cache: false,
            success: function(data){
                console.log(data);
                // if(data === "success"){
                    //Show alert
                    $(".modal.supplier-permission-modal[data-modal=solarSupplierAdditionalServices]").addClass("success");

                    //Change button text back to default
                    submitButton.html("Submit");

                    //Re-enable button
                    submitButton.prop('disabled', false);

                    //Enable supplier contact alert
                    $(".moduleResults[data-technology-name=solarPower] .additional-supplier-permission-granted").addClass("show");

                    //Hide buy buttons
                    $(".moduleResults[data-technology-name=solarPower] .primary-button.supplier-additional-services-contact").addClass("hide");
                // } else {
                //     alert(data);
                // }
            }
        });
    }

});
//#endregion

//#region Extras Costs
body.on('click', 'button#openExtrasCostsModal', function(e) {
    $(".modal.extrasCosts[data-modal='" + $(this).data("type") + "ExtrasCosts']").addClass("open");
});

body.on('click', 'button#saveExtrasCosts', function(e) {
    e.preventDefault()
    let extras = {};
    let module = $(this).data("module");

    let button = $(this);

    //Disable button
    button.prop("disabled", true);
    button.text("Saving, please wait...");

    switch (module) {
        case "lighting":
            $(`.modal.extrasCosts form[data-module=${module}]`).children(".extra-cost").each(function(i, obj) {
                extras[$(this).data("id")] = {
                    cost: $(this).find("input.cost").val(),
                }
            });
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveLightingExtrasCosts",
                        extras: extras,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
    }
});

//#endregion


