const body = $('body');

body.on('click', '#heatingConsumptionCalculator', function(e) {
    //Open modal
    $(".modal.heating-consumption-calculator-modal").addClass("open");
});


body.on('click', '#submitHeatingConsumptionCalculator', function(e){
    const buildingTypeId = $(".modal.heating-consumption-calculator-modal #building-type .button .selected").data("value");
    const mainHeatingFuelType = $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type .button .selected").data("value");
    const totalFuelConsumptionElement = $(".modal.heating-consumption-calculator-modal #total-fuel-consumption");
    const totalFuelConsumption = totalFuelConsumptionElement.val();

    let errors = false;

    //Check inputs
    if(buildingTypeId === 0){
        $(".modal.heating-consumption-calculator-modal #building-type").closest(".input").addClass("error");
        errors = true;
    } else {
        $(".modal.heating-consumption-calculator-modal #building-type").closest(".input").removeClass("error");
    }

    if(totalFuelConsumption === ""){
        totalFuelConsumptionElement.closest(".input").addClass("error");
        errors = true;
    } else {
        totalFuelConsumptionElement.closest(".input").removeClass("error");
    }

    if(mainHeatingFuelType === 0){
        $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type").closest(".input").addClass("error");
        errors = true;
    } else {
        $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type").closest(".input").removeClass("error");
    }

    //If no errors, submit
    if(!errors){
        //Close modal
        $(".modal.heating-consumption-calculator-modal").removeClass("open");

        //Send AJAX Request
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "heatingConsumptionCalculator",
                    buildingTypeId: buildingTypeId,
                    totalFuelConsumption: totalFuelConsumption,
                    mainHeatingFuelType: mainHeatingFuelType
                })
            },
            success: function (data) {
                //Update heating fuel consumption field
                $("#heatingConsumption").val(data);

                //Clear fields
                $(".modal.heating-consumption-calculator-modal #building-type .button .selected").data("value", 0).text("Select an option");
                $(".modal.heating-consumption-calculator-modal #main-heating-fuel-type .button .selected").data("value", 0).text("Select an option");
                totalFuelConsumptionElement.val("");
            }
        });
    }
});

body.on('input', '.modal .input.error input:not(.searchbar-input)', function(e) {
    //Remove error
    $(this).closest(".input").removeClass("error");
});
