

const body = $('body');
let offCanvasOpen = false;

body.on('click', '#burger-nav', function(e) {
    e.stopPropagation();
    if(!offCanvasOpen){
        $('.off-canvas').show();
        offCanvasOpen = true;
        //Disable scrolling
        $("body").css("overflow", "hidden");

    } else {
        $('.off-canvas').hide();
        offCanvasOpen = false;
        //Re-enable scrolling
        $("body").css("overflow", "auto");
    }

});

body.on('click', '.off-canvas', function(e) {
    e.stopPropagation();
});

//Menu close button
body.on('click', '.off-canvas .close', function(e) {
    $('.off-canvas').hide();

    //Re-enable scrolling
    $("body").css("overflow", "auto");

    offCanvasOpen = false;
});

//Dropdown
body.on('click', '.navbar .dropdown .trigger', function(e) {
    $(this).siblings(".dropdown-content").toggleClass("open");
    $(".navbar-dropdown-overlay").toggleClass("open");
});

body.on('click', '.navbar .off-canvas .dropdown >a', function(e) {
    $(this).closest(".dropdown").toggleClass("open");
});

$(document).on('click', function (e) {
    // Off-canvas menu
    if(offCanvasOpen){
        $('.off-canvas').animate({right: "-275px"}, 100, "swing");
        offCanvasOpen = false;
    }

    // Dropdowns
    $(".navbar .dropdown-content.open").removeClass("open");
    $(".navbar-dropdown-overlay").removeClass("open");
});


$(document).on('click', function (e) {
    // Off-canvas menu
    if(offCanvasOpen){
        $('.off-canvas').animate({right: "-275px"}, 100, "swing");
        offCanvasOpen = false;
    }
});