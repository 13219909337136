/**
 *
 * Lighting Module JavaScript
 *
 */

let body = $("body");

//Emergency light quantity toggle
body.on('click', '#emergencyQuantityToggle', function(e) {
    if($("#emergencyQuantityToggle").prop("checked")){
        //Check to see if normal quantity field has been entered into
        if(!$("#standardQuantity").closest(".input").hasClass("disabled")){
            if($("#standardQuantity").val() != "" && $("#standardQuantity").closest(".input").hasClass("next")){

            }
            //Highlight the emergency quantity field
            $("#emergencyQuantity").closest(".input").removeClass("disabled").addClass("next");

            //Remove the highlight from the colour temperature
            $("#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
        }


        //Show emergency quantity field
        $("#emergencyQuantity").show();
        $("#emergencyQuantity").prop('disabled', false);
    } else {

        //Check to see if the emergency quantity field is highlighted
        if($("#emergencyQuantity").closest(".input").hasClass("next")){
            //Remove highlight
            $("#emergencyQuantity").closest(".input").removeClass("next");
            $("#emergencyQuantity").val("").prop("disabled", true);

            //Highlight the appropriate field
            $("#colourTemperature").closest(".input").removeClass("disabled").addClass("next");
        }


        //Hide emergency quantity field
        $("#emergencyQuantity").hide();
        $("#emergencyQuantity").prop('disabled', true);
    }

});

//Daylight sensor quantity toggle
body.on('click', '#daylightSensorQuantityToggle', function(e) {
    const presenceDetectorsQuantityToggled = $("#presenceDetectorsQuantityToggle").prop("checked");
    if($(this).prop("checked")){
        //Show quantity field
        $("#daylightSensorQuantity").show();

        $(".roomTypeInput").show();
    } else {
        //Hide quantity field
        $("#daylightSensorQuantity").hide();

        if(!presenceDetectorsQuantityToggled){
            $(".roomTypeInput").hide();
        }
    }
});

//Presence detectors quantity toggle
body.on('click', '#presenceDetectorsQuantityToggle', function(e) {
    const daylightSensorQuantityToggled = $("#daylightSensorQuantityToggle").prop("checked");
    if($(this).prop("checked")){
        //Show quantity field
        $("#presenceDetectorsQuantity").show();

        //Show building type input
        $(".roomTypeInput").show();
    } else {
        //Hide quantity field
        $("#presenceDetectorsQuantity").hide();

        if(!daylightSensorQuantityToggled){
            $(".roomTypeInput").hide();
        }
    }
});

