import { checkTechnologyData, loadTechnologyPage } from './functions.js';


let body = $('body');

window.loadingPage = false;
let errors = [];
let actionBarLocation = 0;


//Check action bar when page is loaded, resized or scrolled
$(window).on('load resize scroll', function () {
    checkActionBar();
})

//Reset survey button
body.on('click', '#resetSurvey', function(e) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "resetSurvey"
            })
        },
        success: function() {
            window.location.replace("./survey");
        }
    });
});


//#region Generic Page

body.on('click', 'button#nextTechnology', function(e) {
    checkTechnologyData(true);
});

//Next page
body.on('click', '.actions-bottom-bar button.next', function(e) {
    if($(this).hasClass("disabled")) return;
    switch ($("#currentPage").val()) {
        case "index":
            loadContent("technologies?headless=true", "./technologies", "Technologies - Savings Calculator WhatsThePayback", function(){
                $('.technology-nav .slide-container .swiper-slide').first().children(".item").addClass("active");
                loadTechnologyPage($('.technology-nav .slide-container .swiper-slide').first().children(".item").data("technology"));
                checkTechnologyData();
            });
            break;
        case "data":
            loadContent("details?headless=true", "./details", "Details - Savings Calculator WhatsThePayback");
            break;
        case "details":
            let errors = [];
            let requiredFields = [
                {
                    id: "#firstName",
                    message: "Empty field First name"
                },
                {
                    id: "#lastName",
                    message: "Empty field Last name"
                },
                {
                    id: "#emailAddress",
                    message: "Empty field Email address"
                },
                {
                    id: "#phoneNumber",
                    message: "Empty field Phone number"
                },
                {
                    id: "#addressLine1",
                    message: "Empty field Address line 1"
                },
                {
                    id: "#city",
                    message: "Empty field City"
                },
                {
                    id: "#postalcode",
                    message: "Empty field Postal/zip code"
                },
                {
                    id: "#dayRate",
                    message: "Empty field Day rate"
                },
                {
                    id: "#nightRate",
                    message: "Empty field Night rate"
                },
                {
                    id: "#gasRate",
                    message: "Empty field Gas unit rate"
                }
            ];

            if (typeof additionalValidation !== 'undefined') {
                let useAlternativeFuel = false;
                additionalValidation.forEach(function (field) {
                    if (typeof field["isDropdown"] !== "undefined" && field["isDropdown"]) {
                        if ($(field.id).find(".selected").text() === "" && $(field.id).find(".selected").text() !== "None") {
                            errors.push(field.message);
                        }
                    } else {
                        requiredFields.push(field);
                    }
                    if(field.id === "#alternativeFuelType" && $("#alternativeFuelType .selected").text() !== "" && $("#alternativeFuelType .selected").text() !== "None"){
                        useAlternativeFuel = true;
                    }
                });

                if(useAlternativeFuel){
                    if($("#alternativeFuelRate").val() === ""){
                        errors.push("Empty field Alternative Fuel Rate");
                    }
                    if($("#oilLPGConsumption").val() === ""){
                        errors.push("Empty field Oil/LPG consumption consumption");
                    }
                }
            }


            requiredFields.forEach(function(field){
                if($(field.id).val() === ""){
                    errors.push(field.message);
                }
            });

            if(!validateEmail($("input#emailAddress").val())){
                errors.push("Invalid Email address");
            }

            if($("#assessmentStartDate").length && $("#assessmentStartDate").val() === ""){
                errors.push("Empty Assessment Start date");
            }

            if($("#assessmentEndDate").length && $("#assessmentEndDate").val() === ""){
                errors.push("Empty Assessment End date");
            }


            if(!$('#useManualDegreeDays').is(":checked")){
                $("#degreeDaysManual").val("")
                $("#manualDegreeDaysManual").val("")
            } else {
                if(typeof $("#degreeDaysManual") === "undefined" ||$("#degreeDaysManual").val() === ""){
                    errors.push("Empty Manual Degree Days");
                }
                if(typeof $("#standardDegreeDaysManual") === "undefined" || $("#standardDegreeDaysManual").val() === ""){
                    errors.push("Empty Standardised Degree Days");
                }
            }

            let brokerConsent = 0;
            if($('#brokeringConsent').is(":checked")){
                brokerConsent = 1;
            }

            let powerPurchaseAgreementConsent = 0;
            if($('#powerPurchaseAgreementConsent').is(":checked")){
                powerPurchaseAgreementConsent = 1;
            }

            let gasConsumption = $("#gasConsumption").val();
            let electricityConsumption = $("#electricityConsumption").val();
            if($('#toggleEmissionsConsumptionFields').is(":checked")){

                //If no gas consumption field, uses emissions
                if($("#gasConsumption").length < 1){
                    gasConsumption = $("#emissionsGasConsumption").val();
                }

                //If no electricity consumption field, uses emissions
                if($("#electricityConsumption").length < 1){
                    electricityConsumption = $("#emissionsElectricityConsumption").val();
                }
            }

            if($("#assessmentStartDate").length && ($("#degreeDaysManual").val() === 0 || $("#degreeDaysManual").val() === "")){
                let startDate = Date.parse($("#assessmentStartDate").val());
                let endDate = Date.parse($("#assessmentEndDate").val());

                if(startDate >= endDate){
                    errors.push("Assessment Start date must be before Assessment End date");
                }

                //Disable button
                $(".actions-bar-container .buttons-row .next").attr("disabled", true);

                $.ajax({
                    type: 'POST',
                    url: window.location.href,
                    data: { data: JSON.stringify({
                            action: "checkBoilerReplacementPeriod",
                            period: {
                                postcode: $("#postalcode").val(),
                                startDate: $("#assessmentStartDate").val(),
                                endDate: $("#assessmentEndDate").val(),
                            }
                        })},
                    success: function (data) {
                        //Enable button
                        $(".actions-bar-container .buttons-row .next").removeAttr("disabled");

                        if(data !== "VALID"){
                            switch(data){
                                case "MISSING_DATES":
                                    errors.push("Assessment Start and End dates needs to be entered before your postcode can be checked.");
                                    break;
                                case "BAD_POSTCODE":
                                    errors.push("Couldn't find data for that postcode. Try entering a Manual Degree Days figure.");
                                    break;
                                case "START_DATE_BEFORE_END":
                                    errors.push("Assessment Start date must be before Assessment End date");
                                    break;
                                case "BAD_DATES":
                                    errors.push("No Degree Days found for those Assessment Dates. Try entering a Manual Degree Days figure.");
                                    break;
                                default:
                                    errors.push("Unknown error. Try entering a Manual Degree Days figure.");
                                    break;
                            }
                        }
                        $(".error-area.details").empty();
                        if(errors.length > 0){
                            let list = "";
                            errors.forEach(function(value){
                                list = list + "<li>" + value + "</li>";
                            });
                            $(".error-area.details").append("<div class=\"error-banner\"><span>There were the following error(s):</span><ul>" + list + "</ul></div>");
                            $("html, body").animate({ scrollTop: 0 }, "slow");
                        } else {
                            $.ajax({
                                type: 'POST',
                                url: window.location.href,
                                data: { data: JSON.stringify({
                                        action: "saveDetails",
                                        details: {
                                            firstName: $("#firstName").val(),
                                            lastName: $("#lastName").val(),
                                            company: $("#company").val(),
                                            emailAddress: $("#emailAddress").val(),
                                            phoneNumber: $("#phoneNumber").val(),
                                            currency: $(".dropdown#currency .button .selected").text(),
                                            ownerEmail: $("#ownerEmail").val(),
                                            addressLine1: $("#addressLine1").val(),
                                            addressLine2: $("#addressLine2").val(),
                                            city: $("#city").val(),
                                            state: $("#state").val(),
                                            postalCode: $("#postalcode").val(),
                                            country: $(".wtp-dropdown#country .button .selected").text(),
                                            dayRate: $("#dayRate").val(),
                                            nightRate: $("#nightRate").val(),
                                            gasRate: $("#gasRate").val(),
                                            gasConsumption: gasConsumption,
                                            electricityConsumption: electricityConsumption,
                                            oilLPGConsumption: $("#oilLPGConsumption").val(),
                                            heatingConsumption: $("#heatingConsumption").val(),
                                            alternativeFuelType: $(".wtp-dropdown#alternativeFuelType .button .selected").text(),
                                            alternativeFuelRate: $("#alternativeFuelRate").val(),
                                            buildingType: $(".wtp-dropdown#buildingType .button .selected").data("building-type-id"),
                                            assessmentStartDate: $("#assessmentStartDate").val(),
                                            assessmentEndDate: $("#assessmentEndDate").val(),
                                            degreeDaysManual: $("#degreeDaysManual").val(),
                                            standardDegreeDaysManual: $("#standardDegreeDaysManual").val(),
                                            brokerConsent: brokerConsent,
                                            powerPurchaseAgreementConsent: powerPurchaseAgreementConsent,
                                        }
                                    })},
                                success: function (data) {
                                    window.location.href = "overview";
                                }
                            });
                        }
                    }
                });
            } else {
                $(".error-area.details").empty();
                if(errors.length > 0){
                    let list = "";
                    errors.forEach(function(value){
                        list = list + "<li>" + value + "</li>";
                    });
                    $(".error-area.details").append("<div class=\"error-banner\"><span>There were the following error(s):</span><ul>" + list + "</ul></div>");
                    $("html, body").animate({ scrollTop: 0 }, "slow");
                } else {
                    //Disable button
                    $(".actions-bar-container .buttons-row .next").attr("disabled", true);

                    $.ajax({
                        type: 'POST',
                        url: window.location.href,
                        data: { data: JSON.stringify({
                                action: "saveDetails",
                                details: {
                                    firstName: $("#firstName").val(),
                                    lastName: $("#lastName").val(),
                                    company: $("#company").val(),
                                    emailAddress: $("#emailAddress").val(),
                                    phoneNumber: $("#phoneNumber").val(),
                                    currency: $(".wtp-dropdown#currency .button .selected").text(),
                                    ownerEmail: $("#ownerEmail").val(),
                                    addressLine1: $("#addressLine1").val(),
                                    addressLine2: $("#addressLine2").val(),
                                    city: $("#city").val(),
                                    state: $("#state").val(),
                                    postalCode: $("#postalcode").val(),
                                    country: $(".wtp-dropdown#country .button .selected").text(),
                                    dayRate: $("#dayRate").val(),
                                    nightRate: $("#nightRate").val(),
                                    gasRate: $("#gasRate").val(),
                                    gasConsumption: gasConsumption,
                                    electricityConsumption: electricityConsumption,
                                    oilLPGConsumption: $("#oilLPGConsumption").val(),
                                    heatingConsumption: $("#heatingConsumption").val(),
                                    buildingType: $(".wtp-dropdown#buildingType .button .selected").data("building-type-id"),
                                    assessmentStartDate: $("#assessmentStartDate").val(),
                                    assessmentEndDate: $("#assessmentEndDate").val(),
                                    degreeDaysManual: $("#degreeDaysManual").val(),
                                    standardDegreeDaysManual: $("#standardDegreeDaysManual").val(),
                                    brokerConsent: brokerConsent,
                                    powerPurchaseAgreementConsent: powerPurchaseAgreementConsent,
                                }
                            })},
                        success: function (data) {
                            window.location.href = "overview";
                        }
                    });
                }
            }
            break;
    }

});

//Previous page
body.on('click', '.actions-bottom-bar button.previous', function(e) {
    switch ($("#currentPage").val()) {
        case "technologies":
            loadContent("survey?headless=true", "./survey", "Savings Calculator WhatsThePayback");
            break;
        case "details":
            loadContent("technologies?headless=true", "./technologies", "Savings Calculator WhatsThePayback");
            break;
    }
});

//#endregion

//#region Generic Technology







// Technology back link
body.on('click', '.back-link', function(e) {
    if(!loadingPage) {
        loadContent("index?headless=true", "./", "Savings Calculator WhatsThePayback")
    }
});

body.on('click', '.module-modal .close-modal', function(e) {
    $(".module-modal-overlay").removeClass("active");
    $(".module-modal").removeClass("open");
});

//#endregion

//#region Dropdown

// Toggle dropbox via button
body.on('click', '.dropdown .button', function(e) {
    e.preventDefault();
    e.stopPropagation();
    if($(this).closest(".input").hasClass("disabled")) return;
    $(".dropdown .dropdown-content ul .no-data").addClass("hidden");
    $('.dropdown .searchbar input').val("");
    searchDropdown($(this));
    if(!$(this).siblings(".dropdown-content").hasClass("open")){
        $(".dropdown-content.open").removeClass("open");
        $(this).siblings(".dropdown-content").addClass("open");
    } else {
        $(".dropdown-content.open").removeClass("open");
    }
    $(this).siblings(".dropdown-content").children(".searchbar").children("input").focus();
    return false
});

body.on('click', '.dropdown#currency .dropdown-content li', function(e) {
    let match = /\((.)\)$/g.exec($(this).text());
    $(".input .input-badge .badge").text(match[1] + "/kWh");
});

body.on('click', '.dropdown#roofType .dropdown-content li', function(e) {
    if($(this).text() !== "Select an option"){
        $('.dropdown#roofType').removeClass("error");
    }
});

body.on('click', '.wtp-dropdown#buildingType .dropdown-content li', function(e) {
    $(".wtp-dropdown#buildingType .button .selected").data("building-type-id", $(this).data("building-type-id"));
});

body.on('click', '.report-generator-panel .dropdown .dropdown-content li, .supplier-additional-services-form .dropdown .dropdown-content li', function(e) {
    $(this).closest(".dropdown-field").removeClass("dropdown-placeholder");
});

// Option clicked
body.on('click', '.dropdown .dropdown-content li', function(e) {
    const selectedText = $(this).text();
    const hiddenValue = $(this).data('value');
    let buttonSelected = $(this).parents().siblings(".button").children(".selected");

    //Set button text
    buttonSelected.text(selectedText);

    //Set hidden value
    if(typeof hiddenValue !== 'undefined' && hiddenValue !== false){
        buttonSelected.data("value", hiddenValue);
    }

    //Close dropdown menu
    $(this).parents(".dropdown-content").removeClass("open");

    e.stopPropagation();
});

// Stop dropbox closing if clicked
body.on('click', '.dropdown', function(e) {
    e.stopPropagation();
});

// Search dropdown on input
body.on('keyup', '.dropdown .searchbar input', function(e) {
    searchDropdown($(this));
});


//#endregion

body.on('click', '#tooltipLightingFittingType', function(e) {
    e.preventDefault()
    $("#lightFittingPDF").addClass("open");
});

body.on('click', '.pdf-modal .dismiss', function(e) {
    $(this).closest(".pdf-modal").removeClass("open");
});

//#region Modal

// Modal close
body.on('click', '.modal .cancel', function(e) {
    e.preventDefault()
    $(this).parents(".modal.open").removeClass("open");
});

//#endregion

body.on('click', '.embed-form .cancel', function(e) {
    $(this).closest(".embed-form").slideToggle("slow");
});

//#region BMU Page

body.on('click', '#addBoiler', function(e) {
    $(".bmuForm.embed-form").slideToggle("slow");
    $(".listNoData").slideToggle("slow");
});


body.on('click', '.deleteBMURow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this boiler?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "removeBMU",
                    id: $(this).data("id")
                })},
            success: function (data) {
                importBoilerRefresh("bmu");
                checkTechnologyData();
            }
        });
        $(this).closest("tr").remove();
        if($("#bmuList tbody tr").length < 1){
            $(".panel").append("<style>#lightsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing boilers</h3><h4>and we will automatically find you the BMU to match.</h4><button class=\"action\" id=\"addBoiler\">Add Boiler</button></div>")

            //Add action bar mask
            $(".actions-bar-container .error-mask").addClass("active");
        }
    }
});

//#endregion

//#region Boiler Replacement Page

body.on('click', '.deleteBoilerReplacementRow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this boiler?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "removeReplacementBoiler",
                    id: $(this).data("id")
                })},
            success: function (data) {
                importBoilerRefresh("replacementBoiler");
                checkTechnologyData();
            }
        });
        $(this).closest("tr").remove();
        if($("#boilerReplacementList tbody tr").length < 1){
            $(".panel").append("<style>#lightsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing boilers</h3><h4>and we will automatically find you a replacement to match.</h4><button class=\"action\" id=\"addReplacementBoiler\">Add Boiler</button></div>")

            //Add action bar mask
            $(".actions-bar-container .error-mask").addClass("active");
        }
    }
});

body.on('click', '#addReplacementBoiler', function(e) {
    $(".boilerInputForm.embed-form").slideToggle("slow");
    $(".listNoData").slideToggle("slow");
});

body.on('click', '.manualDegreeDays', function(e) {
    $(".inner-embed-form-page[data-page-name='measurement-period'] .subpage[data-subpage-name='automatic']").removeClass("active");
    $(".inner-embed-form-page[data-page-name='measurement-period'] .subpage[data-subpage-name='manual']").addClass("active");
});

body.on('click', '.boilerInputForm #next-step', function(e) {
    let errorArea = $(".error-area");
    errorArea.empty();
    e.preventDefault();
    let page = $(".boilerInputForm").data("form-page");
    switch(page){
        case "existing-boiler-input":
            let subpage = $(".inner-embed-form-page[data-page-name='existing-boiler-input']").children(".subpage.active").data("subpage-name");
            switch(subpage) {
                case "method-selector":
                    let methodSelected = $('input[name="alternativeMethod"]:checked').val();
                    let methodSelectorPage = $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']");
                    let submitButton = $(".boilerInputForm .actions .submit");

                    switch(methodSelected){
                        case "season-efficiency":
                            methodSelectorPage.removeClass("active");
                            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-2']").addClass("active");
                            submitButton.removeClass("hidden");
                            break;
                        case "manufactures-efficiency":
                            methodSelectorPage.removeClass("active");
                            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-3']").addClass("active");
                            submitButton.removeClass("hidden");
                            break;
                        case "boiler-input-output":
                            methodSelectorPage.removeClass("active");
                            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-4']").addClass("active");
                            submitButton.removeClass("hidden");
                            break;
                        case "energy-rating":
                            methodSelectorPage.removeClass("active");
                            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-5']").addClass("active");
                            submitButton.removeClass("hidden");
                            break;
                    }

                    boilerInputFieldWizard();
                    break;
                case "method-5":
                    if($(".dropdown#existingBoilerEnergyRating .button .selected").text() === "Select an option"){
                        errors.push("Select an Energy Rating");
                    }
                    errorArea.empty();
                    if(errors.length > 0){
                        let list = "";
                        errors.forEach(function(value){
                            list = list + "<li>" + value + "</li>";
                        });
                        $(".error-area").append("<div class=\"error-banner\"><span>There were the following error(s):</span><ul>" + list + "</ul></div>");
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        errors = [];
                    } else {
                        $(".boilerInputForm").data("boiler-method", "5");
                        replacementBoilerSerialize();
                    }
                    break;
                case "method-4":
                    if($(".dropdown#existingBoilerInputOutputDetails .button .selected").text() === "Select an option"){
                        errors.push("Select what details your input and output is based on");
                    }
                    if($("#existingBoilerInputLoad100").val() == "" || $("#existingBoilerInputLoad100").val() == "0"){
                        errors.push("No Boiler input at 100% of load entered");
                    }
                    if($("#existingBoilerOutputLoad100").val() == "" || $("#existingBoilerOutputLoad100").val() == "0"){
                        errors.push("No Boiler output at 100% of load entered");
                    }
                    if($("#existingBoilerInputLoad30").val() == "" || $("#existingBoilerInputLoad30").val() == "0"){
                        errors.push("No Boiler input at 30% of load entered");
                    }
                    if($("#existingBoilerOutputLoad30").val() == "" || $("#existingBoilerOutputLoad30").val() == "0"){
                        errors.push("No Boiler output at 30% of load entered");
                    }

                    errorArea.empty();
                    if(errors.length > 0){
                        let list = "";
                        errors.forEach(function(value){
                            list = list + "<li>" + value + "</li>";
                        });
                        $(".error-area").append("<div class=\"error-banner\"><span>There were the following error(s):</span><ul>" + list + "</ul></div>");
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        errors = [];
                    } else {
                        $(".boilerInputForm").data("boiler-method", "4");
                        replacementBoilerSerialize();
                    }
                    break;
                case "method-3":
                    if($(".dropdown#boilerManufacturerEfficiencyBase .button .selected").text() === "Select an option"){
                        errors.push("Select what details your manufacturers efficiency data is based on");
                    }
                    if($("#boilerManufacturerEfficiency100").val() == "" || $("#boilerManufacturerEfficiency100").val() == "0"){
                        errors.push("No Efficiency at 100% of load entered");
                    }
                    if($("#boilerManufacturerEfficiency30").val() == "" || $("#boilerManufacturerEfficiency30").val() == "0"){
                        errors.push("No Efficiency at 30% of load entered");
                    }
                    errorArea.empty();
                    if(errors.length > 0){
                        let list = "";
                        errors.forEach(function(value){
                            list = list + "<li>" + value + "</li>";
                        });
                        $(".error-area").append("<div class=\"error-banner\"><span>There were the following error(s):</span><ul>" + list + "</ul></div>");
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        errors = [];
                    } else {
                        //Success
                        $(".boilerInputForm").data("boiler-method", "3");
                        replacementBoilerSerialize();
                    }
                    break;
                case "method-2":
                    if($("#seasonalEfficiency").val() == ""){
                        errors.push("No valid Seasonal Efficiency entered");
                    }

                    errorArea.empty();
                    if(errors.length > 0){
                        let list = "";
                        errors.forEach(function(value){
                            list = list + "<li>" + value + "</li>";
                        });
                        $(".error-area").append("<div class=\"error-banner\"><span>There were the following error(s):</span><ul>" + list + "</ul></div>");
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        errors = [];
                    } else {
                        //Success
                        $(".boilerInputForm").data("boiler-method", "2");
                        replacementBoilerSerialize();
                    }
                    break;
                case "method-1":
                    $(".embed-form .input.error").css("z-index", "");

                    if($("#boilerManufacturer").val() === ""){
                        errors.push("#boilerManufacturer");
                    }

                    if($("#boilerModelName").val() === ""){
                        errors.push("#boilerModelName");
                    }

                    if($("#boilerModelIdentifier").val() === ""){
                        errors.push("#boilerModelIdentifier");
                    }

                    if($("#boilerOutput").val() === ""){
                        errors.push("#boilerOutput");
                    }

                    if($(".dropdown#boilerFuelType .button .selected").text() === "Select an option"){
                        errors.push("#boilerFuelType");
                    }

                    if($("input#boilerQuantity").val() === ""){
                        errors.push("#boilerQuantity");
                    }

                    if($(".boilerInputForm").data("boiler-form-module") === "endoTherm"){
                        if($(".dropdown#boilerSystemType .button .selected").text() === "Select an option"){
                            errors.push("#boilerSystemType");
                        }
                    }

                    errorArea.empty();
                    if(errors.length > 0){
                        errors = [];
                    } else {
                        let identifier = "";
                        if($(".subpage[data-subpage-name='method-1'] #boilerModelIdentifier").val() != "None") {
                            identifier = $(".subpage[data-subpage-name='method-1'] #boilerModelIdentifier").val();
                        }
                        $.ajax({
                            type: 'POST',
                            url: window.location.href,
                            data: {
                                data: JSON.stringify({
                                    action: "checkBoilerExists",
                                    boiler: {
                                        manufacturer: $(".subpage[data-subpage-name='method-1'] #boilerManufacturer").val(),
                                        modelName: $(".subpage[data-subpage-name='method-1'] #boilerModelName").val(),
                                        modelIdentifier: identifier,
                                        output: $(".subpage[data-subpage-name='method-1'] #boilerOutput").val(),
                                    },
                                })
                            },
                            success: function(data) {
                                if(data === "true"){
                                    $(".boilerInputForm").data("boiler-method", "1");
                                    $(".inner-embed-form-page[data-page-name='existing-boiler-input']").removeClass("active");
                                    $(".boilerInputForm .actions .cancel").addClass("hidden");
                                    $(".boilerInputForm .actions .previous-step").removeClass("hidden");
                                    $(".boilerInputForm .actions .submit").removeClass("hidden");
                                    replacementBoilerSerialize();
                                } else {
                                    //Change to Method Selection page
                                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-1']").removeClass("active");
                                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").addClass("active");
                                    $(".boilerInputForm .actions .cancel").addClass("hidden");
                                    $(".boilerInputForm .actions .previous-step").removeClass("hidden");
                                    $(".boilerInputForm .actions .submit").removeClass("hidden");
                                }
                            }
                        });
                    }
                    break;
            }
            break;
    }
});

body.on('click', '.boilerInputForm #previous-step', function(e) {
    $(".error-area").empty();
    e.preventDefault();
    let page = $(".boilerInputForm").data("form-page");
    switch(page){
        case "existing-boiler-input":
            let subpage = $(".inner-embed-form-page[data-page-name='existing-boiler-input']").children(".subpage.active").data("subpage-name");
            switch(subpage){
                case "method-selector":
                    //Direct from boiler entry options
                    if($(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").hasClass("from-boiler-entry-options")){
                        $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").removeClass("from-boiler-entry-options")
                        $('.boilerInputForm .subpage[data-subpage-name="boiler-entry-options"]').addClass("active")
                        $(".boilerInputForm .actions .submit").addClass("hidden");
                    } else {
                        $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-1']").addClass("active");
                        $(".boilerInputForm .actions .submit").removeClass("hidden");
                    }
                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").removeClass("active");
                    $(".boilerInputForm .actions .cancel").addClass("hidden");
                    $(".boilerInputForm .actions .previous-step").removeClass("hidden");
                    break;
                case "method-1":
                    //Change to boiler entry options
                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='boiler-entry-options']").addClass("active");
                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-1']").removeClass("active");
                    $(".boilerInputForm .actions .cancel").removeClass("hidden");
                    $(".boilerInputForm .actions .previous-step").addClass("hidden");
                    $(".boilerInputForm .actions .submit").addClass("hidden");
                    break;
                case "method-2":
                case "method-3":
                case "method-4":
                case "method-5":
                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage.active").removeClass("active");
                    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").addClass("active");
                    $(".boilerInputForm .actions .submit").removeClass("hidden");
                    break;
            }
            break;
    }
});

body.on('click', '.boilerInputForm .subpage[data-subpage-name="boiler-entry-options"] .manual-entry', function(e) {
    //Change to Method Selection page
    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='boiler-entry-options']").removeClass("active");
    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").addClass("active").addClass("from-boiler-entry-options");
    $(".boilerInputForm .actions .cancel").addClass("hidden");
    $(".boilerInputForm .actions .previous-step").removeClass("hidden");
    $(".boilerInputForm .actions .submit").removeClass("hidden");

    boilerInputFieldWizard();
});

body.on('click', '.boilerInputForm .subpage[data-subpage-name="boiler-entry-options"] .boiler-search', function(e) {
    //Change to Method Selection page
    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='boiler-entry-options']").removeClass("active");
    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-1']").addClass("active");
    $(".boilerInputForm .actions .cancel").addClass("hidden");
    $(".boilerInputForm .actions .previous-step").removeClass("hidden");
    $(".boilerInputForm .actions .submit").removeClass("hidden");

    boilerInputFieldWizard();
});

body.on('click', '.boiler-replacement-methods button', function(e) {
    let methodSelectorPage = $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']");
    let submitButton = $(".boilerInputForm .actions .submit");

    switch($(this).attr('id')){
        case "season-efficiency":
            methodSelectorPage.removeClass("active");
            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-2']").addClass("active");
            submitButton.removeClass("hidden");
            break;
        case "manufactures-efficiency":
            methodSelectorPage.removeClass("active");
            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-3']").addClass("active");
            submitButton.removeClass("hidden");
            break;
        case "boiler-input-output":
            methodSelectorPage.removeClass("active");
            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-4']").addClass("active");
            submitButton.removeClass("hidden");
            break;
        case "energy-rating":
            methodSelectorPage.removeClass("active");
            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-5']").addClass("active");
            submitButton.removeClass("hidden");
            break;
    }

    boilerInputFieldWizard();
});

body.on('click', '#boilerMethodSelectorButton', function(e) {
    //Change to Method Selection page
    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-1']").removeClass("active");
    $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-selector']").addClass("active");
    $(".boilerInputForm .actions .cancel").addClass("hidden");
    $(".boilerInputForm .actions .previous-step").removeClass("hidden");
    $(".boilerInputForm .actions .submit").removeClass("hidden");

    //Hide Alert
    $(".no-data-alert").removeClass("show");
    $(".no-data-mask").removeClass("show");

    boilerInputFieldWizard();
});


//#endregion

//#region EndoTherm

body.on('click', '.deleteEndoThermRow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this boiler?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "removeEndoTherm",
                    id: $(this).data("id")
                })},
            success: function (data) {
                importBoilerRefresh("endoTherm");
            }
        });
        $(this).closest("tr").remove();
        if($("#endoThermList tbody tr").length < 1){
            $(".panel").append("<style>#lightsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing boilers</h3><h4>and we will automatically find you the correct upgrades.</h4><button class=\"action\" id=\"addReplacementBoiler\">Add Boiler</button></div>")
        }
        checkTechnologyData();
    }
});

//#endregion

body.on('click', '#saveCHPUnit', function(e) {
    e.preventDefault();
    let data = chpUnitModalSerialize();

    let errors = [];

    if(data.usage.weekdays.day === "0" && data.usage.weekdays.night === "0" && data.usage.weekdays.other === "0" && data.usage.weekend.day === "0" && data.usage.weekend.night === "0" && data.usage.weekend.other === "0") {
        errors.push($(".usage-table"));
    }

    if(data.boilerEfficiency === ""){
        errors.push($("#boilerEfficiency").closest(".input"));
    }

    if(errors.length > 0){
        $(".actions .submit").addClass("error");
        errors.forEach(function(input){
            input.addClass("error");
        });

        alert("There are required fields which need to be filled out.");
    } else {
        data["action"] = "addCHPUnit";
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify(data)},
            success: function (data) {
                $("#lightsList tbody").append(data);

                $(".usage-table input").val(0);
                $("#workWeeks").val(52);
                $(".lightingForm.embed-form").slideToggle("slow");

                $(".actions-bar-container .error-mask").removeClass("active");

                if($("#lightsList tbody tr").length > 0) {
                    $(".listNoData").remove();
                }

                checkTechnologyData();
            }
        });
    }
});


//#region Lighting Page

body.on('click', '#addLight', function(e) {
    //Clear inputs
    resetLightingForm();

    //Set embed form to create mode
    $(".create-lighting-specific").show();
    $(".edit-lighting-specific").hide();

    //Open embed form
    $(".lightingForm.embed-form").slideDown("slow");
    $(".listNoData").slideDown("slow");
});

// Save light modal
body.on('click', '#saveLight', function(e) {
    e.preventDefault();
    let light = lightsModalSerialize();
    let errors = [];

    if(light.fixtureType === "Select an option" && light.fittingType !== "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    switch(light.fittingType){
        case "Select an option":
            errors.push($("#fittingType").closest(".input"));
            break;
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            if(light.tubeType === "Select an option"){
                errors.push($("#tubeType").closest(".input"));
            }
            if(light.length === "Select an option"){
                errors.push($("#length").closest(".input"));
            }
            if(light.ballast === "Select an option"){
                errors.push($("#ballast").closest(".input"));
            }
            break;
        default:
            if(light.wattage === "Select an option"){
                errors.push($("#wattage").closest(".input"));
            }
            if(light.lampCapType === "Select an option"){
                errors.push($("#lampCapType").closest(".input"));
            }
            break;
    }

    if(light.fixtureType === "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    if(light.upgradeType === "Select an option"){
        errors.push($("#upgradeType").closest(".input"));
    }

    //Sensors and detectors
    if(light.daylightSensors < 0 || light.daylightSensors === ""){
        errors.push($("#daylightSensorQuantity").closest(".input"));
    }
    if(light.presenceDetectors < 0 || light.presenceDetectors === ""){
        errors.push($("#presenceDetectorsQuantity").closest(".input"));
    }
    if((light.presenceDetectors !== 0 || light.daylightSensors!== 0) && light.roomType === 0){
        errors.push($("#roomType").closest(".input"));
    }

    //Daily usage
    if((light.standardQuantity === "0" || light.standardQuantity === "") && (light.emergencyQuantity === "0" || light.emergencyQuantity === "")){
        errors.push($("#standardQuantity").closest(".input"));
    }

    if(light.colourTemperature === "Select an option"){
        errors.push($("#colourTemperature").closest(".input"));
    }

    //Add up all of the usage inputs
    let totalUsage = zeroNotNon($("input#mon-fri-day-rate").val()) + zeroNotNon($("input#mon-fri-night-rate").val()) + zeroNotNon($("input#sat-sun-day-rate").val()) + zeroNotNon($("input#sat-sun-night-rate").val());

    if(totalUsage === 0){
        errors.push($(".usage-table"));
    }

    if(errors.length > 0){
        alert("There are required fields which need to be filled out.");
    } else {
        light["action"] = "addLight";
        if(light.standardQuantity === ""){
            light.standardQuantity = 0;
        }
        if(light.emergencyQuantity === ""){
            light.emergencyQuantity = 0;
        }

        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify(light)},
            success: function (data) {
                $("#lightsList tbody").append(data);
                $(".lightingForm.embed-form").slideToggle("slow");
                resetLightingForm();
                checkTechnologyData();
            }
        });
    }
});

// Update light modal
body.on('click', '#updateLight', function(e) {
    e.preventDefault();
    let light = lightsModalSerialize();
    let errors = [];

    if(light.fixtureType === "Select an option" && light.fittingType !== "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    switch(light.fittingType){
        case "Select an option":
            errors.push($("#fittingType").closest(".input"));
            break;
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            if(light.tubeType === "Select an option"){
                errors.push($("#tubeType").closest(".input"));
            }
            if(light.length === "Select an option"){
                errors.push($("#length").closest(".input"));
            }
            if(light.ballast === "Select an option"){
                errors.push($("#ballast").closest(".input"));
            }
            break;
        default:
            if(light.wattage === "Select an option"){
                errors.push($("#wattage").closest(".input"));
            }
            if(light.lampCapType === "Select an option"){
                errors.push($("#lampCapType").closest(".input"));
            }
            break;
    }

    if(light.fixtureType === "Select an option"){
        errors.push($("#fixtureType").closest(".input"));
    }

    if(light.upgradeType === "Select an option"){
        errors.push($("#upgradeType").closest(".input"));
    }

    if((light.standardQuantity === "0" || light.standardQuantity === "") && (light.emergencyQuantity === "0" || light.emergencyQuantity === "")){
        errors.push($("#standardQuantity").closest(".input"));
    }

    if(light.colourTemperature === "Select an option"){
        errors.push($("#colourTemperature").closest(".input"));
    }

    //Sensors and detectors
    if(light.daylightSensors < 0 || light.daylightSensors === ""){
        errors.push($("#daylightSensorQuantity").closest(".input"));
    }
    if(light.presenceDetectors < 0 || light.presenceDetectors === ""){
        errors.push($("#presenceDetectorsQuantity").closest(".input"));
    }
    if((light.presenceDetectors !== 0 || light.daylightSensors!== 0) && light.roomType === 0){
        errors.push($("#roomType").closest(".input"));
    }

    if(light.usage.weekdays.day === "0" && light.usage.weekdays.night === "0" && light.usage.weekdays.other === "0" && light.usage.weekend.day === "0" && light.usage.weekend.night === "0" && light.usage.weekend.other === "0") {
        errors.push($(".usage-table"));
    }

    if(errors.length > 0){
        alert("There are required fields which need to be filled out.");
    } else {
        light["action"] = "updateLight";
        if(light.standardQuantity === ""){
            light.standardQuantity = 0;
        }
        if(light.emergencyQuantity === ""){
            light.emergencyQuantity = 0;
        }

        light.id = $(".embed-form #updateLight").data("light-id");

        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify(light)},
            success: function (data) {
                //Set embed form to create mode
                $(".create-lighting-specific").show();
                $(".edit-lighting-specific").hide();

                //Add new entry
                $("#lightsList tbody").append(data);

                //Remove old version
                $("#lightsList .deleteLightingRow[data-id=" + light.id + "]").closest("tr").remove();

                //Toogle mbed form
                $(".lightingForm.embed-form").slideToggle("slow");
                resetLightingForm();
                checkTechnologyData();
            }
        });
    }
});

// Delete light record
body.on('click', '.deleteLightingRow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this light?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "removeLight",
                    id: $(this).data("id")
            })},
            success: function(){
                checkTechnologyData();
            }
        });
        $(this).closest("tr").remove();
        if($("#lightsList tbody tr").length < 1){
            $(".panel").append("<style>#lightsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing light fixtures</h3><h4>and we will automatically find you the correct upgrades.</h4><button class=\"action\" id=\"addLight\">Add Light</button></div>")
        }
    }
});

// Duplicate light record
body.on('click', '.duplicateLightingRow', function(e) {
    //Set embed form to edit mode
    $(".create-lighting-specific").show();
    $(".edit-lighting-specific").hide();

    //Close embed form (if open)
    $(".lightingForm.embed-form").slideToggle("slow");
    $(".listNoData").slideToggle("slow");

    //Clear form inputs
    resetLightingForm();

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "duplicateLight",
                id: $(this).data("id")
            })},
        success: function (data) {
            let fields = JSON.parse(data);

            //Open embed form
            $(".lightingForm.embed-form").slideDown("slow");
            $(".listNoData").slideDown("slow");

            //Remove field wizard classes
            $(".embed-form .input.next").removeClass("next");
            $(".embed-form .input.disabled").removeClass("disabled");

            //Insert data into fields
            $(".dropdown#fittingType .button .selected").text(fields.fittingType);
            $(".dropdown#fixtureType .button .selected").text(fields.fixtureType);
            $(".dropdown#upgradeType .button .selected").text(fields.upgradeType);
            $(".dropdown#tubeType .button .selected").text(fields.tubeType);
            $(".dropdown#length .button .selected").text(fields.length);
            $(".dropdown#wattage .button .selected").text(fields.wattage);
            $(".dropdown#ballast .button .selected").text(fields.ballast);
            $(".dropdown#lampCapType .button .selected").text(fields.lampCapType);

            $(".dropdown#colourTemperature .button .selected").text(fields.colourTemperature);
            $("#standardQuantity").val(fields.standardQuantity);

            //Insert usage table data
            $("#workWeeks").val(fields.workweeks);
            $(".usage-table tbody #mon-fri-day-rate").val(fields.usage.weekdays.day);
            $(".usage-table tbody #mon-fri-night-rate").val(fields.usage.weekdays.night);
            $(".usage-table tbody #sat-sun-day-rate").val(fields.usage.weekend.day);
            $(".usage-table tbody #sat-sun-night-rate").val(fields.usage.weekend.night);

            //Emergency quantity
            if(fields.emergencyQuantity !== 0){
                $('#emergencyQuantityToggle').prop('checked', true);
                $("#emergencyQuantity").show();
                $("#emergencyQuantity").prop('disabled', false);
                $("#emergencyQuantity").val(fields.emergencyQuantity);
            }

            //Room name field
            if(fields.roomName !== ""){
                $('#enterRoomReference').prop('checked', true);
                $("#roomReference").show();
                $("input[type=text]#roomReference").prop('disabled', false);
                $("input[type=text]#roomReference").val(fields.roomName);
            }

            //Show the relevant fields
            switch(fields.fittingType){
                case "Select an option":
                    $(".not-fluoro").fadeOut("slow")
                    $(".fluoro-only").fadeOut("slow")
                    $(".dropdown#fixtureType").closest(".input").addClass("disabled").removeClass("next");
                    $(".dropdown#fittingType").closest(".input").addClass("next");
                    break;
                case "Fluoro-Single":
                case "Fluoro-Twin":
                case "Fluoro-Triple":
                case "Fluoro-Quad":
                    $(".fluoro-only").fadeIn("slow")
                    $(".not-fluoro").fadeOut("slow")
                    $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                    break;
                default:
                    $(".fluoro-only").fadeOut("slow")
                    $(".not-fluoro").fadeIn("slow")
                    $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                    break;
            }

            updateLightingForm(true);
        }
    });
});

// Edit light record
body.on('click', '.editLightingRow', function(e) {
    //Set embed form to edit mode
    $(".create-lighting-specific").hide();
    $(".edit-lighting-specific").show();

    //Close embed form (if open)
    $(".lightingForm.embed-form").slideToggle("slow");
    $(".listNoData").slideToggle("slow");

    //Clear form inputs
    resetLightingForm();

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "duplicateLight",
                id: $(this).data("id")
            })},
        success: function (data) {
            let fields = JSON.parse(data);

            //Open embed form
            $(".lightingForm.embed-form").slideDown("slow");
            $(".listNoData").slideDown("slow");

            //Remove field wizard classes
            $(".embed-form .input.next").removeClass("next");
            $(".embed-form .input.disabled").removeClass("disabled");

            $(".embed-form #updateLight").data("light-id", fields.id);

            //Insert data into fields
            $(".dropdown#fittingType .button .selected").text(fields.fittingType);
            $(".dropdown#fixtureType .button .selected").text(fields.fixtureType);
            $(".dropdown#upgradeType .button .selected").text(fields.upgradeType);
            $(".dropdown#tubeType .button .selected").text(fields.tubeType);
            $(".dropdown#length .button .selected").text(fields.length);
            $(".dropdown#wattage .button .selected").text(fields.wattage);
            $(".dropdown#ballast .button .selected").text(fields.ballast);
            $(".dropdown#lampCapType .button .selected").text(fields.lampCapType);

            $(".dropdown#colourTemperature .button .selected").text(fields.colourTemperature);
            $("#standardQuantity").val(fields.standardQuantity);

            //Insert usage table data
            $("#workWeeks").val(fields.workweeks);
            $(".usage-table tbody #mon-fri-day-rate").val(fields.usage.weekdays.day);
            $(".usage-table tbody #mon-fri-night-rate").val(fields.usage.weekdays.night);
            $(".usage-table tbody #sat-sun-day-rate").val(fields.usage.weekend.day);
            $(".usage-table tbody #sat-sun-night-rate").val(fields.usage.weekend.night);

            //Emergency quantity
            if(fields.emergencyQuantity !== 0){
                $('#emergencyQuantityToggle').prop('checked', true);
                $("#emergencyQuantity").show();
                $("#emergencyQuantity").prop('disabled', false);
                $("#emergencyQuantity").val(fields.emergencyQuantity);
            }

            //Room name field
            if(fields.roomName !== ""){
                $('#enterRoomReference').prop('checked', true);
                $("#roomReference").show();
                $("input[type=text]#roomReference").prop('disabled', false);
                $("input[type=text]#roomReference").val(fields.roomName);
            }

            //Show the relevant fields
            switch(fields.fittingType){
                case "Select an option":
                    $(".not-fluoro").fadeOut("slow")
                    $(".fluoro-only").fadeOut("slow")
                    $(".dropdown#fixtureType").closest(".input").addClass("disabled").removeClass("next");
                    $(".dropdown#fittingType").closest(".input").addClass("next");
                    break;
                case "Fluoro-Single":
                case "Fluoro-Twin":
                case "Fluoro-Triple":
                case "Fluoro-Quad":
                    $(".fluoro-only").fadeIn("slow")
                    $(".not-fluoro").fadeOut("slow")
                    $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                    break;
                default:
                    $(".fluoro-only").fadeOut("slow")
                    $(".not-fluoro").fadeIn("slow")
                    $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
                    break;
            }

            updateLightingForm(true);
        }
    });
});

// Delete Roof Insulation record
body.on('click', '.deleteRoofInsulationRow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this entry?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "removeRoofInsulation",
                    id: $(this).data("id")
                })},
            success: function(){
                checkTechnologyData();
            }
        });
        $(this).closest("tr").remove();
        if($("#lightsList tbody tr").length < 1){
            $(".panel").append("<style>#lightsList thead tr th:last-child {min-width: 10px;}</style><div class=\"listNoData\"><h3>Add your existing roof areas</h3><h4>and we will automatically find you the correct upgrades.</h4><button class=\"action\" id=\"addLight\">Add Area</button></div>")
        }
    }
});

// Delete CHP Unit record
body.on('click', '.deleteCHPUnitRow', function(e) {
    let deleteRow = confirm("Are you sure you want to remove this CHP Unit?");
    if(deleteRow === true){
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "removeCHPUnit",
                    id: $(this).data("id")
                })},
            success: function(data){
                checkTechnologyData();
            }
        });
        $(this).closest("tr").remove();
        if($("#lightsList tbody tr").length < 1){
            $(".panel").append("<div class=\"listNoData\"> <h3>Please enter the estimated operating hours</h3> <h4>and we will automatically find you the best CHP unit.</h4> <button class=\"action\" id=\"addLight\">Add Hours</button> </div>")
        }
    }
});


//Lighting fields
body.on('click', '.dropdown#fittingType .dropdown-content ul li', function(e) {
    //();

    $(".dropdown#fittingType").closest(".input").removeClass("next");
    $(".dropdown#fixtureType").closest(".input").addClass("next");

    $(".dropdown#fixtureType .button .selected").text("Select an option");

    $(".dropdown#tubeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#tubeType .button .selected").text("Select an option");

    $(".dropdown#length").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#length .button .selected").text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#wattage").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#wattage .button .selected").text("Select an option");

    $(".dropdown#lampCapType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");



    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    switch($(this).text()){
        case "Select an option":
            $(".not-fluoro").fadeOut("slow")
            $(".fluoro-only").fadeOut("slow")
            $(".dropdown#fixtureType").closest(".input").addClass("disabled").removeClass("next");
            $(".dropdown#fittingType").closest(".input").addClass("next");
            break;
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            $(".fluoro-only").fadeIn("slow")
            $(".not-fluoro").fadeOut("slow")
            $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
            break;
        default:
            $(".fluoro-only").fadeOut("slow")
            $(".not-fluoro").fadeIn("slow")
            $(".dropdown#fixtureType").closest(".input").removeClass("disabled");
            break;
    }

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "fixtureList",
                details: {
                    fittingType: $(this).text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            options.unshift("Select an option");
            let html = "";
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });
            $(".dropdown#fixtureType .simplebar-content").html(html);
        }
    });
});

body.on('click', '.dropdown#fixtureType .dropdown-content ul li', function(e) {

    $(".dropdown#fixtureType").closest(".input").removeClass("next");

    $(".dropdown#tubeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#tubeType .button .selected").text("Select an option");

    $(".dropdown#length").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#length .button .selected").text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#wattage").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#wattage .button .selected").text("Select an option");

    $(".dropdown#lampCapType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");


    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#tubeType").closest(".input").removeClass("disabled").addClass("next");
        $(".dropdown#wattage").closest(".input").removeClass("disabled").addClass("next");


        switch($(".input .dropdown#fittingType .button .selected").text()){
            case "Fluoro-Single":
            case "Fluoro-Twin":
            case "Fluoro-Triple":
            case "Fluoro-Quad":
                $.ajax({
                    type: 'POST',
                    url: window.location.href,
                    data: { data: JSON.stringify({
                            action: "tubeTypeList",
                            details: {
                                fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                                fixtureType: $(this).text()
                            }
                        })},
                    success: function (data) {
                        let options = JSON.parse(data);
                        let html = "";
                        options.forEach(function(entry) {
                            if(entry === "T5"){
                                html = "<li>" + entry + "</li>" + html;
                            } else {
                                html = html + "<li>" + entry + "</li>";
                            }
                        });
                        html = "<li>Select an option</li>" + html;
                        $(".dropdown#tubeType .simplebar-content").html(html);
                    }
                });
                break;
            default:
                $.ajax({
                    type: 'POST',
                    url: window.location.href,
                    data: { data: JSON.stringify({
                            action: "wattageList",
                            details: {
                                fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                                fixtureType: $(this).text()
                            }
                        })},
                    success: function (data) {
                        let options = JSON.parse(data);
                        options.unshift("Select an option");
                        let html = "";
                        options.forEach(function(entry) {
                            html = html + "<li>" + entry + "</li>";
                        });
                        $(".dropdown#wattage .simplebar-content").html(html);
                    }
                });
                break;
        }

    } else {
        $(".dropdown#tubeType").closest(".input").addClass("disabled");
        $(".dropdown#tubeType .button .selected").text("Select an option");
        $(".dropdown#wattage").closest(".input").addClass("disabled");
        $(".dropdown#wattage .button .selected").text("Select an option");
        $(".dropdown#fixtureType").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#tubeType .dropdown-content ul li', function(e) {

    $(".dropdown#tubeType").closest(".input").removeClass("next");
    $(".dropdown#length").closest(".input").addClass("next");

    $(".dropdown#length .button .selected").text("Select an option");


    $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#length").closest(".input").removeClass("disabled");
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "lengthList",
                    details: {
                        fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                        fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                        tubeType: $(this).text()
                    }
                })},
            success: function (data) {
                let options = JSON.parse(data);
                options.unshift("Select an option");
                let html = "";
                options.forEach(function(entry) {
                    html = html + "<li>" + entry + "</li>";
                });
                $(".dropdown#length .simplebar-content").html(html);
            }
        });
    } else {
        $(".dropdown#length").closest(".input").addClass("disabled");
        $(".dropdown#length .button .selected").text("Select an option");
        $(".dropdown#tubeType").closest(".input").addClass("next");

    }
});

body.on('click', '.dropdown#length .dropdown-content ul li', function(e) {
    $(".dropdown#length").closest(".input").removeClass("next");
    $(".dropdown#ballast").closest(".input").addClass("next");

    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");

    if($(this).text() !== "Select an option"){
        $(".dropdown#ballast").closest(".input").removeClass("disabled");
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "ballastList",
                    details: {
                        fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                        fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                        tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                        length: $(this).text()
                    }
                })},
            success: function (data) {
                let options = JSON.parse(data);
                options.unshift("Select an option");
                let html = "";
                options.forEach(function(entry) {
                    html = html + "<li>" + entry + "</li>";
                });
                $(".dropdown#ballast .simplebar-content").html(html);
            }
        });
    } else {
        $(".dropdown#ballast").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#ballast .button .selected").text("Select an option");
        $(".dropdown#length").closest(".input").addClass("next");

    }
});

body.on('click', '.dropdown#ballast .dropdown-content ul li', function(e) {
    $(".dropdown#ballast").closest(".input").removeClass("next");
    $(".dropdown#upgradeType").closest(".input").addClass("next");

    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#upgradeType").closest(".input").removeClass("disabled");
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "upgradeTypeList",
                    details: {
                        fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                        fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                        tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                        length: $(".input .dropdown#length .button .selected").text(),
                        ballast: $(this).text()
                    }
                })},
            success: function (data) {
                let options = JSON.parse(data);
                options.unshift("Select an option");
                let html = "";
                options.forEach(function(entry) {
                    html = html + "<li>" + entry + "</li>";
                });
                $(".dropdown#upgradeType .simplebar-content").html(html);
            }
        });
    } else {
        $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#ballast").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#wattage .dropdown-content ul li', function(e) {

    $(".dropdown#wattage").closest(".input").removeClass("next");
    $(".dropdown#lampCapType").closest(".input").addClass("next");

    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#lampCapType").closest(".input").removeClass("disabled");
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "lampCapList",
                    details: {
                        fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                        fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                        wattage: $(this).text()
                    }
                })},
            success: function (data) {
                let options = JSON.parse(data);
                options.unshift("Select an option");
                let html = "";
                options.forEach(function(entry) {
                    html = html + "<li>" + entry + "</li>";
                });
                $(".dropdown#lampCapType .simplebar-content").html(html);
            }
        });
    } else {
        $(".dropdown#lampCapType").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#wattage").closest(".input").addClass("next");
    }
});

body.on('click', '.dropdown#lampCapType .dropdown-content ul li', function(e) {

    $(".dropdown#lampCapType").closest(".input").removeClass("next");
    $(".dropdown#upgradeType").closest(".input").addClass("next");


    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");

    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled").removeClass("next");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
    emergencyQuantity.val("").prop("disabled", true);

    $(".usage-table").removeClass("error");


    if($(this).text() !== "Select an option"){
        $(".dropdown#upgradeType").closest(".input").removeClass("disabled");
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({
                    action: "upgradeTypeList",
                    details: {
                        fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                        fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                        wattage: $(".input .dropdown#wattage .button .selected").text(),
                        lampCapType: $(this).text()
                    }
                })},
            success: function (data) {
                let options = JSON.parse(data);
                options.unshift("Select an option");
                let html = "";
                options.forEach(function(entry) {
                    html = html + "<li>" + entry + "</li>";
                });
                $(".dropdown#upgradeType .simplebar-content").html(html);
            }
        });
    } else {
        $(".dropdown#upgradeType").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#lampCapType").closest(".input").addClass("next");
    }
})

body.on('click', '.dropdown#upgradeType .dropdown-content ul li', function(e) {
    $(".dropdown#upgradeType").closest(".input").removeClass("next");

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "quantityTypes",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                    tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                    length: $(".input .dropdown#length .button .selected").text(),
                    ballast: $(".input .dropdown#ballast .button .selected").text(),
                    wattage: $(".input .dropdown#wattage .button .selected").text(),
                    lampCapType: $(".input .dropdown#lampCapType .button .selected").text(),
                    upgradeType: $(this).text()
                }
            })},
        success: function (data) {
            data = JSON.parse(data);
            let standardQuantity = $("input#standardQuantity");
            let emergencyQuantity = $("input#emergencyQuantity");
            if(data.includes("standard")){
                standardQuantity.prop("disabled", false);
                standardQuantity.closest(".input").removeClass("disabled").addClass("next");

                emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
            } else {
                standardQuantity.prop("disabled", true);
                standardQuantity.closest(".input").addClass("disabled").removeClass("next");

                if(data.includes("emergency")){
                    emergencyQuantity.closest(".input").removeClass("disabled").addClass("next");
                } else {
                    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
                }
            }

            if(data.includes("emergency")){
                $("#emergencyQuantityToggle").removeAttr('disabled');
                if(!$(".emergencyQuantityInput").hasClass("disabled")){
                    emergencyQuantity.removeAttr('disabled');
                }
            } else {
                let toggle = $("#emergencyQuantityToggle");
                toggle.prop("disabled", true);
                toggle.prop('checked', false);
                emergencyQuantity.hide();
                emergencyQuantity.prop('disabled', true);
            }
        }
    });
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "colourTemperatures",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                    tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                    length: $(".input .dropdown#length .button .selected").text(),
                    ballast: $(".input .dropdown#ballast .button .selected").text(),
                    wattage: $(".input .dropdown#wattage .button .selected").text(),
                    lampCapType: $(".input .dropdown#lampCapType .button .selected").text(),
                    upgradeType: $(this).text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            options.unshift("Select an option");
            let html = "";
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });
            $(".dropdown#colourTemperature .simplebar-content").html(html);
        }
    });
});

body.on('input', 'input#standardQuantity', function(e) {
    if($(this).val()){
        $(this).closest(".input").removeClass("next");

        if($("input#emergencyQuantity").prop("disabled")){
            $(".dropdown#colourTemperature").closest(".input").removeClass("disabled").addClass("next");
        } else {
            $("input#emergencyQuantity").closest(".input").removeClass("disabled").addClass("next");
        }
    } else {
        $(this).closest(".input").addClass("next");
        $("input#emergencyQuantity").closest(".input").addClass("disabled").removeClass("next");
        $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");

    }
});

body.on('input', 'input#emergencyQuantity', function(e) {
    if($(this).val()){
        $(this).closest(".input").removeClass("next");
        $(".dropdown#colourTemperature").closest(".input").removeClass("disabled").addClass("next");

    } else {
        $(this).closest(".input").addClass("next");
        $(".dropdown#colourTemperature").closest(".input").addClass("disabled").removeClass("next");
    }
});

body.on('click', '.dropdown#colourTemperature .dropdown-content ul li', function(e) {
    if($(this).text() !== "Select an option") {
        $(this).closest(".input").removeClass("next");
        let totalUsage = zeroNotNon($("input#mon-fri-day-rate").val()) + zeroNotNon($("input#mon-fri-night-rate").val()) + zeroNotNon($("input#sat-sun-day-rate").val()) + zeroNotNon($("input#sat-sun-night-rate").val());
        if(totalUsage < 1){
            $(".usage-table").addClass("error");
        } else {
            $(".usage-table").removeClass("error");

        }

    } else {
        $(this).closest(".input").addClass("next");
        $(".usage-table").removeClass("error");
    }
});

body.on('input', '.usage-table input', function(e) {
    let totalUsage = zeroNotNon($("input#mon-fri-day-rate").val()) + zeroNotNon($("input#mon-fri-night-rate").val()) + zeroNotNon($("input#sat-sun-day-rate").val()) + zeroNotNon($("input#sat-sun-night-rate").val());
    if(totalUsage < 1){
        $(".usage-table").addClass("error");
    } else {
        $(".usage-table").removeClass("error");
    }
});

body.on('input', '.form-pages .input-field input', function(e) {
    $(this).closest(".input-field").removeClass("error");
});


// Modal Mon-Fri Light Usage
body.on('change', '#mon-fri-day-rate', function(e) {
    if(parseInt($(this).val()) < 0){
        $(this).val(0); return;
    }

    let dayRate = $('#mon-fri-day-rate');
    let nightRate = $('#mon-fri-night-rate');
    let otherRate = $('#mon-fri-other-rate');

    let total = (parseInt(dayRate.val()) || 0) + (parseInt(nightRate.val()) || 0) + (parseInt(otherRate.val()) || 0);

    if(total > 24){
        let hours = 24 - (parseInt(nightRate.val()) || 0) - (parseInt(otherRate.val()) || 0);

        if(hours > 24){
            hours = 24;
        }

        dayRate.val(hours);
    }
});

body.on('change', '#mon-fri-night-rate', function(e) {
    if(parseInt($(this).val()) < 0){
        $(this).val(0); return;
    }

    let dayRate = $('#mon-fri-day-rate');
    let nightRate = $('#mon-fri-night-rate');
    let otherRate = $('#mon-fri-other-rate');

    let total = (parseInt(dayRate.val()) || 0) + (parseInt(nightRate.val()) || 0) + (parseInt(otherRate.val()) || 0);

    if(total > 24){
        let hours = 24 - (parseInt(dayRate.val()) || 0) - (parseInt(otherRate.val()) || 0);

        if(hours > 24){
            hours = 24;
        }

        nightRate.val(hours);
    }
});

body.on('change', '#mon-fri-other-rate', function(e) {
    if(parseInt($(this).val()) < 0){
        $(this).val(0); return;
    }

    let dayRate = $('#mon-fri-day-rate');
    let nightRate = $('#mon-fri-night-rate');
    let otherRate = $('#mon-fri-other-rate');

    let total = (parseInt(dayRate.val()) || 0) + (parseInt(nightRate.val()) || 0) + (parseInt(otherRate.val()) || 0);

    if(total > 24){
        let hours = 24 - (parseInt(dayRate.val()) || 0) - (parseInt(nightRate.val()) || 0);

        if(hours > 24){
            hours = 24;
        }

        otherRate.val(hours);
    }
});

// Modal Sat-Sun Light Usage
body.on('change', '#sat-sun-day-rate', function(e) {
    if(parseInt($(this).val()) < 0){
        $(this).val(0); return;
    }

    let dayRate = $('#sat-sun-day-rate');
    let nightRate = $('#sat-sun-night-rate');
    let otherRate = $('#sat-sun-other-rate');


    let total = (parseInt(dayRate.val()) || 0) + (parseInt(nightRate.val()) || 0) + (parseInt(otherRate.val()) || 0);

    if(total > 24){
        let hours = 24 - (parseInt(nightRate.val()) || 0) - (parseInt(otherRate.val()) || 0);

        if(hours > 24){
            hours = 24;
        }

        dayRate.val(hours);
    }
});

body.on('change', '#sat-sun-night-rate', function(e) {
    if(parseInt($(this).val()) < 0){
        $(this).val(0); return;
    }

    let dayRate = $('#sat-sun-day-rate');
    let nightRate = $('#sat-sun-night-rate');
    let otherRate = $('#sat-sun-other-rate');

    let total = (parseInt(dayRate.val()) || 0) + (parseInt(nightRate.val()) || 0) + (parseInt(otherRate.val()) || 0);

    if(total > 24){
        let hours = 24 - (parseInt(dayRate.val()) || 0)- (parseInt(otherRate.val()) || 0);

        if(hours > 24){
            hours = 24;
        }

        nightRate.val(hours);
    }
});

body.on('change', '#sat-sun-other-rate', function(e) {
    if(parseInt($(this).val()) < 0){
        $(this).val(0); return;
    }

    let dayRate = $('#sat-sun-day-rate');
    let nightRate = $('#sat-sun-night-rate');
    let otherRate = $('#sat-sun-other-rate');

    let total = (parseInt(dayRate.val()) || 0) + (parseInt(nightRate.val()) || 0) + (parseInt(otherRate.val()) || 0);

    if(total > 24){
        let hours = 24 - (parseInt(dayRate.val()) || 0)- (parseInt(nightRate.val()) || 0);

        if(hours > 24){
            hours = 24;
        }

        otherRate.val(hours);
    }
});

//#endregion


//#region Details Page

//Country electricity average toggle




body.on('change', '#electricityCountryAverage', function(e) {
    let dayRate = $('#dayRate');
    let nightRate = $('#nightRate');

    if($(this).is(":checked")) {
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({action: "countryAverageElectricity", country: $(".wtp-dropdown#country .button .selected").text()})},
            success: function(data){
                dayRate.prop("disabled", true).val(data);
                nightRate.prop("disabled", true).val(data);
            }
        });
    } else {
        dayRate.prop("disabled", false);
        nightRate.prop("disabled", false);
    }
});

//Country gas average toggle
body.on('change', '#gasCountryAverage', function(e) {
    let gasRate = $('#gasRate');

    if($(this).is(":checked")) {
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: { data: JSON.stringify({action: "countryAverageGas", country: $(".wtp-dropdown#country .button .selected").text()})},
            success: function(data){
                gasRate.prop("disabled", true).val(data);
            }
        });
    } else {
        gasRate.prop("disabled", false);
    }
});

body.on('click', '.dropdown#alternativeFuelType ul li', function(e) {
    let selectedValue = $(".dropdown#alternativeFuelType .button .selected").text();

    if(selectedValue === "None"){
        $("#alternativeRate").closest(".input").hide();
        $(".oil-lpg-related").hide();
    } else {
        $("#alternativeRate").closest(".input").show();
        $(".oil-lpg-related").show();
    }
});



//#endregion

body.on('click', '.report-generator-panel .buttons .next', function(e) {
    //Get current page number
    const currentPage = $(".report-generator-panel .page.show").data("page");

    //Hide current page
    $(".report-generator-panel .page.show").removeClass("show");

    //Show next page
    $(".report-generator-panel .page[data-page=" + (currentPage + 1) + "]").addClass("show");

    //Is there next page?
    let highestPageNumber = currentPage;
    $(".report-generator-panel .page").each(function(){
       if($(this).data("page") > highestPageNumber){
           highestPageNumber = $(this).data("page");
       }
    });
    if(currentPage + 2 <= highestPageNumber){
        $(".report-generator-panel .buttons .next").removeClass("hidden");
        $(".report-generator-panel .buttons .finish").addClass("hidden");
    } else {
        $(".report-generator-panel .buttons .next").addClass("hidden");
        $(".report-generator-panel .buttons .finish").removeClass("hidden");

    }

    //Is there a previous page
    if(currentPage > 0){
        $(".report-generator-panel .buttons .previous").removeClass("hidden");
    } else {
        $(".report-generator-panel .buttons .previous").addClass("hidden");
    }

    //Remove focus from button
    $(this).blur();

    //Form check
    professionalReportGeneratorCheck();
});

body.on('click', '.report-generator-panel .buttons .previous', function(e) {
    //Get current page number
    const currentPage = $(".report-generator-panel .page.show").data("page");

    //Hide current page
    $(".report-generator-panel .page.show").removeClass("show");

    //Show next page
    $(".report-generator-panel .page[data-page=" + (currentPage - 1) + "]").addClass("show");

    //Show next page button
    $(".report-generator-panel .buttons .next").removeClass("hidden");

    //Hide finish button
    $(".report-generator-panel .buttons .finish").addClass("hidden");

    //Is there a previous page
    let lowestPageNumber = currentPage;
    $(".report-generator-panel .page").each(function(){
        if($(this).data("page") < lowestPageNumber){
            lowestPageNumber = $(this).data("page");
        }
    });
    if(currentPage - 2 >= lowestPageNumber){
        $(".report-generator-panel .buttons .previous").removeClass("hidden");
    } else {
        $(".report-generator-panel .buttons .previous").addClass("hidden");
    }


    //Remove focus from button
    $(this).blur();

    //Form check
    professionalReportGeneratorCheck();
});


body.on('click', '.report-generator-panel .page .dropdown .dropdown-content li', function(e) {
    //Form check
    professionalReportGeneratorCheck();
});

body.on('click', '.report-generator-panel .buttons .finish', function(e) {

    //Hide current page
    $(".report-generator-panel .page.show").removeClass("show");

    //Show finish page
    $(".report-generator-panel .finish-page").addClass("show");

    //Hide action bar
    $(".report-generator-panel .buttons").hide();

    //Hide panel title
    $(".report-generator-panel .title").first().hide();

    //Remove focus from button
    $(this).blur();

    //Submit form data
    const formData = professionalReportGeneratorData();

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "reportData",
                data: formData
            }),
        },
        success: function (data) {
            let response = JSON.parse(data);
            if(response.status === "success"){
                window.location.search = "?retrieve=" + response.report_uuid;
                $(".report-generator-panel .title").first().hide();
            } else {
                alert("There was an error trying to create your report, please try again.");
            }
        }
    });
});

body.on('click', '.report-generator-panel .additional-surveys .survey-input-rows .add-survey', function(e) {
    let surveyIDInput = $(".report-generator-panel .additional-surveys .survey-input-rows input[name=surveyID]");

    //Survey id
    const surveyID = surveyIDInput.val();

    //Check survey id
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "checkSurveyID",
                id: surveyID
            })
        },
        success: function (data) {
            //Handle response
            switch(data){
                case "success":
                    //Reset survey id field
                    surveyIDInput.val("");

                    //Add survey entry
                    $(".report-generator-panel .additional-surveys .survey-list").append("<li><input type=\"text\" value=\"#" + surveyID + "\" readonly><button class=\"delete-survey\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"48\" height=\"48\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"icon feather feather-x\"><line x1=\"18\" y1=\"6\" x2=\"6\" y2=\"18\"></line><line x1=\"6\" y1=\"6\" x2=\"18\" y2=\"18\"></line></svg>\n</button></li>");

                    //Hide error
                    $(".additional-surveys-error").removeClass("show");
                    break;
                case "not_found":
                    //Change text
                    $(".additional-surveys-error").text("No survey exists with that id.")

                    //Show error
                    $(".additional-surveys-error").addClass("show");
                    break;
                case "no_access":
                    //Change text
                    $(".additional-surveys-error").text("No don't have access to that survey.")

                    //Show error
                    $(".additional-surveys-error").addClass("show");
                    break;
            }
        }
    });




});

body.on('click', '.report-generator-panel .additional-surveys .survey-list .delete-survey', function(e) {
    $(this).closest("li").remove();
});

body.on('change', '.report-generator-panel input#asYes', function(e) {
    $(".report-generator-panel .additional-surveys").addClass("show");
});

body.on('change', '.report-generator-panel input#asNo', function(e) {
    $(".report-generator-panel .additional-surveys").removeClass("show");
});

body.on('change', '.report-generator-panel input#mdYes', function(e) {
    $(".report-generator-panel .manual-data").addClass("show");
});

body.on('change', '.report-generator-panel input#mdNo', function(e) {
    $(".report-generator-panel .manual-data").removeClass("show");
});

body.on('click', '.report-generator-panel .manual-data .survey-input-rows .add-entry', function(e) {
    const moduleName = $("#mdModuleName").val();
    const annualkWh = $("#mdAnnualkWh").val();
    const annualSavingkWh = $("#mdAnnualSavingkWh").val();
    const kWhRate = $("#mdkWhRate").val();
    const capex = $("#mdCAPEX").val();

    //Validate fields
    if(moduleName === "" || annualkWh === "" || annualSavingkWh === "" || kWhRate === "" || capex === ""){
        //Show missing field error
        $(".manual-data-error").addClass("show");
        return;
    } else {
        //Hide error message
        $(".manual-data-error").removeClass("show");
    }

    //Clear fields values
    $("#mdModuleName").val("");
    $("#mdAnnualkWh").val("");
    $("#mdAnnualSavingkWh").val("");
    $("#mdkWhRate").val("");
    $("#mdCAPEX").val("");

    //Add entry to table
    $(".manual-data-list").append("<tr class='data-entry'><td>" + moduleName + "</td><td>" + annualkWh + "</td><td>" + annualSavingkWh + "</td><td>" + kWhRate + "</td><td>" + capex + "</td><td><button class='delete-entry'><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-trash-2\"><polyline points=\"3 6 5 6 21 6\"/><path d=\"M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2\"/><line x1=\"10\" y1=\"11\" x2=\"10\" y2=\"17\"/><line x1=\"14\" y1=\"11\" x2=\"14\" y2=\"17\"/></svg></button></td></tr>");

    //Remove empty message
    $(".manual-data-list .empty-state").remove();

    professionalReportGeneratorCheck();
});

body.on('click', '.report-generator-panel .manual-data .manual-data-list .data-entry .delete-entry', function(e) {
    //Get confirmation that the user wants to remove the entry
    let confirmation = confirm("Are you sure you want to delete this entry?");

    if(confirmation){
        //Remove the entry from table
        $(this).closest(".data-entry").remove();

        //Check to see if the empty state should be added back (i.e. there are no longer any entries in the table)
        if($(".report-generator-panel .manual-data .manual-data-list .data-entry").length < 1){
            //Add empty state
            $(".manual-data-list").append("<tr><th colspan='5' class='empty-state'>No manual data added.</th></tr>");
        }

        professionalReportGeneratorCheck();
    }
});

body.on('change', '.report-generator-panel #manualDegreeDaysData', function(e) {
    const checkbox = $(this).is(":checked");
    const fields = $(".report-generator-panel #manualDegreeDaysFields");
    if(checkbox){
        //Show fields
        fields.show();
    } else {
        //Hide fields
        fields.hide();
    }
});


body.on('input', '.report-generator-panel input', function(e) {
    professionalReportGeneratorCheck();
});

$('.report-generator-panel').ready(function() {
    professionalReportGeneratorCheck();

    //Open the nearest row needing data
    let opened = false;
    $(".report-generator-panel .info-rows .info-row .head .status-icon.fail").each(function(){
        if(!$(this).hasClass("hide") && !opened){
            $(this).closest(".info-row").addClass("open");
            opened = true;
        }
    });
});

//Import Boilers

body.on('click', 'button#importBoilers', function(e) {
    $(".modal[data-modal='importBoilers']").addClass("open");
});

body.on('click', '.modal.importBoilers .boilerInfo', function(e) {
    if(typeof e.target.type === "undefined" || e.target.type !== "checkbox"){
        $(this).find("input[type=checkbox]").prop("checked", !$(this).find("input[type=checkbox]").prop("checked"));
        let checkedCount = $(".modal.importBoilers table input[type=checkbox]:checked").length;
        $(".modal.importBoilers #importBoilersDone").text(`Import Boilers (${checkedCount})`);
    }
});

body.on('click', 'button#importBoilersDone', function(e) {
    let boilers = [];
    let importBoilersModal = $(".modal.importBoilers");

    $("#boilerImportList tbody tr").each(function(index) {
        if($(this).find("input[type=checkbox]").prop("checked") === true){
            boilers.push(index);
        }
    });

    let data = {
        action: "boilerImport",
        boilers: boilers,
        receivingModule: importBoilersModal.data("module")
    };

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {data: JSON.stringify(data)},
        success: function (data) {
            if($(".panel.page-content table").first().children("tbody").find("tr").length < 1){
                $(".listNoData").remove();
            }
            $(".panel.page-content table tbody").first().append(data);
            checkTechnologyData();
        }
    });

    $(".modal[data-modal='importBoilers']").removeClass("open");
    $(".modal.importBoilers table input[type=checkbox]:checked").prop("checked", false);
    $(".modal.importBoilers #importBoilersDone").text(`Import Boilers (0)`);
    importBoilerRefresh(importBoilersModal.data("module"));
});

body.on('click', 'button#installRate', function(e) {
    $(".modal.installRates[data-modal='" + $(this).data("type") + "InstallRates']").addClass("open");
});

body.on('click', '.calc-fields-button', function(e) {
    $(".modal[data-modal='" + $(this).data("type") + "CalcFields']").addClass("open");
});

body.on('click', '.buy-upgrades, .buy-install-upgrades', function(e) {
    //Check if this request included installation services
    let installIncluded = 0;
    if($(this).hasClass("buy-install-upgrades")){
        installIncluded = 1;
    }

    //Hide scroll bar on body
    $("body").addClass("no-scroll");

    //Set module name in text
    $(".modal[data-modal='supplierPermission'] .modal-content .details .notice .moduleName").text($(this).data("name"));

    //Update module code field
    $(".modal[data-modal='supplierPermission'] input[name=moduleCode]").val($(this).data("code"));

    //Set the modal install service variable
    $(".modal[data-modal='supplierPermission'] input[name=installationIncluded]").val(installIncluded);

    //Open supplier permission modal
    $(".modal[data-modal='supplierPermission']").addClass("open");
});

body.on('click', '.supplier-additional-services-contact', function(e) {

    //Hide scroll bar on body
    $("body").addClass("no-scroll");

    //Set the modal install service variable
    $(".modal[data-modal='solarSupplierAdditionalServices'] input[name=installationIncluded]").val(0);

    //Open supplier permission modal
    $(".modal[data-modal='solarSupplierAdditionalServices']").addClass("open");
});
body.on('click', '.modal.supplier-permission-modal #approveSupplierPermission', function(e) {
    //Retrieve module code
    const moduleCode = $(".modal[data-modal='supplierPermission'] input[name=moduleCode]").val();

    //Retrieve installation service status
    let installationInclude = $(".modal[data-modal='supplierPermission'] input[name=installationIncluded]").is(":checked") ? 1 : 0;

    //Change button text
    $(this).html("Loading...");

    //Disable button
    $(this).prop('disabled', true);

    //Get the current survey's id
    const urlParams = new URLSearchParams(window.location.search);
    const surveyID = urlParams.get('id');

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "supplierContact",
                surveyId: surveyID,
                moduleCode: moduleCode,
                installationIncluded: installationInclude,
            })
        },
        success: function(data){
            //Allow scroll bar on body
            $("body").removeClass("no-scroll");

            let approveSupplierButton = $(".modal[data-modal='supplierPermission'] .modal-footer #approveSupplierPermission");

            if(data === "success"){
                //Show alert
                $(".modal[data-modal='supplierPermission']").addClass("success");

                //Change button text back to default
                approveSupplierButton.html("Approve");

                //Re-enable button
                approveSupplierButton.prop('disabled', false);

                //Enable supplier contact alert
                $(".moduleResults[data-technology-name=" + moduleCode + "] .supplier-permission-granted").addClass("show");

                //Hide buy buttons
                $(".moduleResults[data-technology-name=" + moduleCode + "] .primary-button.buy-upgrades").addClass("hide");
                $(".moduleResults[data-technology-name=" + moduleCode + "] .primary-button.buy-install-upgrades").addClass("hide");
            } else {
                //Close main modal
                $(".modal[data-modal='supplierPermission']").removeClass("open");

                setTimeout(function(){
                    //Change button text back to default
                    approveSupplierButton.html("Approve");

                    //Re-enable button
                    approveSupplierButton.prop('disabled', false);
                }, 1000);
            }
        }
    });

});

body.on('click', '.modal.supplier-permission-modal #supplierPermissionModalActions .cancel, .modal[data-modal="supplierPermission"] .success-alert .dismiss-button', function(e) {
    //Close modal
    $(".modal[data-modal='supplierPermission']").removeClass("success").removeClass("open");

    //Allow scroll bar on body
    $("body").removeClass("no-scroll");
});



body.on('click', '.multi-supplier-contact-button', function(e) {
    //Hide scroll bar on body
    $("body").addClass("no-scroll");

    //Update module code field
    $(".modal[data-modal='multiSupplierPermission'] input[name=moduleCode]").val($(this).data("code"));

    //Get supplier list
    let suppliers = [];
    $(this).closest(".upgrades-block").find(".module-supplier-list .supplier").each(function(i) {
        suppliers.push($(this).text());
    });

    //Clear any existing supplier checkboxes
    $(".multi-supplier-permission-modal .supplier-list").html("");

    //Module type
    if(typeof $(this).data("module-type") !== undefined && $(this).data("module-type") === "custom"){
        //Hide supplier checkboxes and label
        $(".multi-supplier-permission-modal .supplier-list-label, .multi-supplier-permission-modal .supplier-list").hide();

        //Hide normal supplier contact wording
        $(".multi-supplier-permission-modal .normal-supplier-text").hide();
    } else {
        //Show supplier checkboxes and label
        $(".multi-supplier-permission-modal .supplier-list-label, .multi-supplier-permission-modal .supplier-list").show();

        //Show normal supplier contact wording
        $(".multi-supplier-permission-modal .normal-supplier-text").show();

        //Add suppliers' checkboxes
        for (let i = 0; i < suppliers.length; i++) {
            //Check to see if supplier's checkbox should be clicked as it was the original supplier
            if(suppliers[i] === $(this).data("name")){
                $(".multi-supplier-permission-modal .supplier-list").append('<li><label class="checkbox"><input checked type="checkbox" id="multiSupplier' + i + '" name="multiSupplier' + i + '" value="true"><span class="checkmark"></span></label><label class="checkboxText" for="multiSupplier' + i + '">' + suppliers[i] + '</label></li>');
            } else {
                $(".multi-supplier-permission-modal .supplier-list").append('<li><label class="checkbox"><input type="checkbox" id="multiSupplier' + i + '" name="multiSupplier' + i + '" value="true"><span class="checkmark"></span></label><label class="checkboxText" for="multiSupplier' + i + '">' + suppliers[i] + '</label></li>');
            }
        }
    }

    //Set module name in text
    $(".modal[data-modal='multiSupplierPermission'] .modal-content .details .notice .moduleName").text($(this).data("name"));

    //Update module code field
    $(".modal[data-modal='multiSupplierPermission'] input[name=moduleCode]").val($(this).data("code"));


    //Open supplier permission modal
    $(".modal[data-modal='multiSupplierPermission']").addClass("open");
});

body.on('click', '.modal.multi-supplier-permission-modal #approveMultiSupplierPermission', function(e) {
    //Retrieve module code
    const moduleCode = $(".modal.multi-supplier-permission-modal input[name=moduleCode]").val();

    //Change button text
    $(this).html("Loading...");

    //Disable button
    $(this).prop('disabled', true);

    //Retrieve list of suppliers
    let suppliersList = [];
    $(".multi-supplier-contact-button[data-code=" + moduleCode + "]").closest(".upgrades-block").find(".module-supplier-list .supplier").each(function(i) {
        suppliersList.push($(this).text());
    });

    //Retrieve selected suppliers
    let selectedSuppliers = [];
    $(".modal.multi-supplier-permission-modal .supplier-list li").each(function(i) {
        let checkbox = $(this).find("input[type=checkbox]");
        if(checkbox.is(":checked")){
            const supplierNumber = checkbox.attr("name").replace("multiSupplier", "");
            const supplierName = suppliersList[supplierNumber];
            if(supplierName !== "nosupplier"){
                selectedSuppliers.push(supplierName);
            }
        }
    });

    //Get the current survey's id
    const urlParams = new URLSearchParams(window.location.search);
    const surveyID = urlParams.get('id');

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "multiSupplierContact",
                surveyId: surveyID,
                moduleCode: moduleCode,
                suppliers: selectedSuppliers
            })
        },
        success: function(data){
            //Allow scroll bar on body
            $("body").removeClass("no-scroll");

            let approveSupplierButton = $(".supplier-permission-modal .modal-footer #approveSupplierPermission");

            if(data === "success"){
                //Show alert
                $(".modal.supplier-permission-modal").addClass("success");

                //Change button text back to default
                approveSupplierButton.html("Approve");

                //Re-enable button
                approveSupplierButton.prop('disabled', false);

                //Enable supplier contact alert
                $(".moduleResults[data-technology-name=" + moduleCode + "] .supplier-permission-granted").addClass("show");

                //Hide buy buttons
                $(".moduleResults[data-technology-name=" + moduleCode + "] .primary-button.buy-upgrades").addClass("hide");
                $(".moduleResults[data-technology-name=" + moduleCode + "] .primary-button.buy-install-upgrades").addClass("hide");
            } else {
                //Close main modal
                $(".modal.supplier-permission-modal").removeClass("open");

                setTimeout(function(){
                    //Change button text back to default
                    approveSupplierButton.html("Approve");

                    //Re-enable button
                    approveSupplierButton.prop('disabled', false);
                }, 1000);
            }

            //Refresh page
            location.reload();
        }
    });

});

body.on('click', '.modal.multi-supplier-permission-modal #multiSupplierPermissionModalActions .cancel', function(e) {
    //Close modal
    $(".modal.multi-supplier-permission-modal").removeClass("success").removeClass("open");

    //Allow scroll bar on body
    $("body").removeClass("no-scroll");
});

body.on('change', '.module-form input[type=checkbox]', function(e) {
    if($(this).attr('name') === "heatingSystemToggle" || $(this).attr('name') === "coolingSystemToggle"){
        $("input[name=heatingSystemToggle], input[name=coolingSystemToggle]").closest(".field.error").removeClass("error");
    }

    //Remove any errors
    $(this).closest(".field.error").removeClass("error");
});

body.on('click', '.carbon-button.button', function(e) {
    //Hide scroll bar on body
    $("body").addClass("no-scroll");
    
    //Open supplier permission modal
    $(".modal[data-modal='carbonOffsetPermission']").addClass("open");
});

body.on('click', '.modal.carbon-offset-permission-modal #carbonOffsetPermissionModalActions .cancel', function(e) {
    //Close modal
    $(".modal.carbon-offset-permission-modal").removeClass("success").removeClass("open");

    //Allow scroll bar on body
    $("body").removeClass("no-scroll");
});


body.on('change', '#useManualDegreeDays', function(e) {
    let degreeDaysManual = $('#degreeDaysManual');

    if($(this).is(":checked")) {
        degreeDaysManual.prop("disabled", false).closest(".block").removeClass("hidden");
        $('#standardDegreeDaysManual').prop("disabled", false);
    } else {
        degreeDaysManual.prop("disabled", true).closest(".block").addClass("hidden");
        $('#standardDegreeDaysManual').prop("disabled", true);
    }
});

body.on('change', '#tcConfirm', function(e) {
    if($(this).is(":checked")) {
        $(".actions-bar-container .buttons-row .next").removeAttr("disabled");
    } else {
        $(".actions-bar-container .buttons-row .next").attr("disabled", true);
    }
});

body.on('click', '.additional-cost-edit', function(e) {
    //Set module in modal data
    const module = $(this).data('module');
    $(".modal.additional-costs-modal").data("module", module);

    //Set module name in modal
    const moduleDisplayName = $(this).data('module-display-name');
    $(".modal.additional-costs-modal .module-name").text(moduleDisplayName);

    //Set current additional cost
    const additionalCost = $(this).data('current-value');
    $('.modal.additional-costs-modal .modal-content .input input.additional-cost').val(additionalCost)

    //Open modal
    $(".modal[data-modal='additional-costs']").addClass("open");
});

body.on('click', '.modal.additional-costs-modal #additionalCostsModalActions .cancel', function(e) {
    //Close modal
    $(".modal.additional-costs-modal").removeClass("success").removeClass("open");

    //Allow scroll bar on body
    $("body").removeClass("no-scroll");
});

body.on('click', '.modal.additional-costs-modal #additionalCostsModalActions .submit', function(e) {
    //Change button text
    $(this).html("Saving...");

    //Disable button
    $(this).prop('disabled', true);

    //Get the current survey's id
    const urlParams = new URLSearchParams(window.location.search);
    const surveyID = parseInt(urlParams.get('id'));

    //Get module
    const module = $(".modal.additional-costs-modal").data("module");

    //Get fields
    let additionalCosts = parseFloat($('.modal.additional-costs-modal .modal-content .input input.additional-cost').val());

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "editAdditionalCosts",
                surveyId: surveyID,
                module: module,
                additionalCosts: additionalCosts,
            })
        },
        success: function(response){
            if(response.success){
                window.location.reload();
            } else {
                console.error(response)
            }
        }
    });
});


body.on('click', '.results-selector .technologies .item', function(e) {
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".moduleResults[data-technology-name='" + $(this).data("technology-name") + "']").offset().top - 16
    }, 500);
});

body.on('click', 'button#saveInstallRates', function(e) {
    e.preventDefault()
    let rates = [];
    let module = $(this).data("module");
    let standardRate = $(".installRates[data-modal=" + module + "InstallRates] .standard-rate");
    let button = $(this);

    //Disable button
    button.prop("disabled", true);
    button.text("Saving, please wait...");

    switch (module) {
        case "lighting":
            let lightingRates = [];

            //Get lighting install rates
            $(".modal.installRates[data-modal='lightingInstallRates'] form[data-module='lighting']").children(".install-rate").each(function(i, obj) {
                lightingRates.push({
                    fitting: $(this).data("fitting-type"),
                    new_replace: $(this).find("input.new-replace").val(),
                    retrofit: $(this).find("input.retrofit").val()
                });
            });

            //Update database
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveLightingInstallRates",
                        rates: lightingRates,
                        module: module
                    })},
                success: function(data){
                    //Refresh page
                    location.reload();
                }
            });
            break;
        case "bmu":
            let bmuRates = {};

            $(`form[data-module=${module}]`).children(".install-rate").each(function(i, obj) {
                let value = $(this).find("input.standard-rate").val();

                if(value !== $(this).find("input.standard-rate").data("existing-value")){
                    bmuRates[$(this).data("unit-id")] = {
                        cost: value
                    }
                }
            });

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveBMUInstallRates",
                        rates: bmuRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "chp":
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveCHPInstallRates",
                        rate: $(" .installRates form[data-module='chp'] .standard-rate").val(),
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "heatPump":
            let heatPumpRates = [];

            $(".installRates[data-modal=heatPumpInstallRates] .install-rate").each(function(index) {
                heatPumpRates.push({
                    upgradeId: $(this).data("upgrade-id"),
                    cost: $(this).find(".standard-rate").val()
                });
            });

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveHeatPumpInstallRates",
                        rates: heatPumpRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "commercialHeatPump":
            let commercialHeatPumpRates = [];

            $(".installRates[data-modal=commercialHeatPumpInstallRates] .install-rate").each(function(index) {
                commercialHeatPumpRates.push({
                    upgradeId: $(this).data("upgrade-id"),
                    cost: $(this).find(".standard-rate").val()
                });
            });

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveCommercialHeatPumpInstallRates",
                        rates: commercialHeatPumpRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "endoTherm":
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveEndoThermInstallRates",
                        rate: standardRate.val(),
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "boilerReplacement":
            let boilerRates = [];

            //Loop fields and form list
            standardRate.each(function(i, obj) {
                //Check to see if the value has changed
                if(parseFloat($(obj).data("existing-value")) !== parseFloat($(obj).val())){
                    boilerRates.push({
                        replacementBoilerId: $(obj).closest(".install-rate").data("replacement-boiler-id"),
                        newValue: $(obj).val()
                    });
                }
            });

            //If no updates required
            if(boilerRates.length === 0){
                location.reload();
            } else {
                $.ajax({
                    type: 'POST',
                    url: window.location.href,
                    data: { data: JSON.stringify({
                            action: "saveBoilerReplacementInstallRates",
                            rates: boilerRates,
                            module: module
                        })},
                    success: function(data){
                        location.reload();
                    }
                });
            }
            break;
        case "solarPower":
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveSolarPowerInstallRates",
                        rate: standardRate.val(),
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "roofInsulation":
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveRoofInsulationInstallRates",
                        rate: standardRate.val(),
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "refrigeration":
            let refrigerationRates = {};

            $(`form[data-module=${module}]`).children(".install-rate").each(function(i, obj) {
                refrigerationRates[$(this).data("unit-type")] = {
                    cost: $(this).find("input.standard-rate").val(),
                }
            });

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveRefrigerationInstallRates",
                        rates: refrigerationRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "energyMonitoring":
            let monitortingRates = {};

            //Get rates
            $(`form[data-module=${module}]`).children(".install-rate").each(function(i, obj) {
                monitortingRates[$(this).data("unit-type")] = {
                    rate: $(this).find("input.standard-rate").val()
                }
            });

            //Save rates
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveEnergyMonitoringRates",
                        rates: monitortingRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "voltageOptimisation":
            let voltageOptimisationRates = {};

            $(`form[data-module=${module}]`).children(".install-rate").each(function(i, obj) {
                let value = $(this).find("input.standard-rate").val();

                if(value !== $(this).find("input.standard-rate").data("existing-value")){
                    voltageOptimisationRates[$(this).data("unit-id")] = {
                        cost: value
                    }
                }
            });

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveVoltageOptimisationInstallRates",
                        rates: voltageOptimisationRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
        case "smartTRV":
            let installCostInput = $(".install-rate[data-unit-id=installRate] input.standard-rate");
            let simMonthlyCostInput = $(".install-rate[data-unit-id=simMonthlyCost] input.standard-rate");
            let annualLicenseCostPerRadiatorInput = $(".install-rate[data-unit-id=annualLicenseCostPerRadiator] input.standard-rate");

            let smartTRVExistingRates = {
                installCost: installCostInput.data("existing-value"),
                simMonthlyCost: simMonthlyCostInput.data("existing-value"),
                annualLicenseCostPerRadiator: annualLicenseCostPerRadiatorInput.data("existing-value")
            };

            let smartTRVRates = {
                installCost: installCostInput.val(),
                simMonthlyCost: simMonthlyCostInput.val(),
                annualLicenseCostPerRadiator: annualLicenseCostPerRadiatorInput.val()
            };

            if(JSON.stringify(smartTRVRates) === JSON.stringify(smartTRVExistingRates)){
                location.reload();
            }

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "saveSmartTRVInstallRates",
                        rates: smartTRVRates,
                        module: module
                    })},
                success: function(data){
                    location.reload();
                }
            });
            break;
    }
});


body.on('click', '.report-payment-panel #coupon-apply-button', function(e) {
    let couponInput = $(".report-payment-panel input[name=coupon]");

    //Remove any existing error texts
    $(".report-payment-panel .coupon-form .error").removeClass("show");

    if(couponInput.val() === "") return;

    //Hide apply button
    $(".report-payment-panel .coupon-form .apply-button").addClass("hide");
    //Show loading button
    $(".report-payment-panel .coupon-form .loading-coupon").removeClass("hide");

    //Check survey id
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "checkCouponCode",
                code: couponInput.val()
            })
        },
        success: function (data) {
            //Hide loading button
            $(".report-payment-panel .coupon-form .loading-coupon").addClass("hide");
            //Show apply button
            $(".report-payment-panel .coupon-form .apply-button").removeClass("hide");

            data = JSON.parse(data);
            //Check to see if the given code is valid
            if(data.code_valid){
                //Remove any existing discount lines
                $(".report-payment-panel .payment-line.discount").remove();

                //Get default sub-total
                let subtotal = parseInt($(".report-payment-panel .payment-line.sub-total .figure").data("start-value"));

                //Currency formatter
                let currencyFormat = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'GBP',
                });

                let discountAmount = 0;
                //Apply discount
                if(data.amount_off !== null){
                    discountAmount = data.amount_off;
                } else if(data.percent_off !== null) {
                    discountAmount = subtotal * (parseInt(data.percent_off) / 100);
                }

                subtotal -= parseInt(discountAmount);

                //Set new subtotal
                $(".report-payment-panel .sub-total .figure").html(currencyFormat.format(subtotal/100));

                //Set new vat
                $(".report-payment-panel .vat .figure").html(currencyFormat.format(subtotal/100*0.2));

                //Set new vat
                $(".report-payment-panel .total-due .figure").html(currencyFormat.format(subtotal/100*1.2));

                //Set new discount line
                const html = "<div class='payment-line discount'><span>Discount Code</span><span>-" + currencyFormat.format(discountAmount/100) + "</span></div>"
                $(html).insertBefore(".report-payment-panel .sub-total");

                //Hide apply button
                $(".report-payment-panel .coupon-form .apply-button").addClass("hide");
                //Show the delete coupon button
                $(".report-payment-panel .coupon-form .delete-coupon").removeClass("hide");
                //Stop the user from changing the coupon input text
                couponInput.prop('disabled', true);
            } else {
                couponInput.val("");
                $(".report-payment-panel .coupon-form .error").addClass("show");
                $(".report-payment-panel .payment-line.discount").remove();
            }
        }
    });

});

body.on('click', '.report-payment-panel .coupon-form .delete-coupon', function(e) {
    let couponInput = $(".report-payment-panel input[name=coupon]");
    //Hide delete button
    $('.report-payment-panel .coupon-form .delete-coupon').addClass("hide");
    //Show apply button
    $(".report-payment-panel .coupon-form .apply-button").removeClass("hide");
    //Remove any existing discount lines
    $(".report-payment-panel .payment-line.discount").remove();
    //Enable input field
    couponInput.prop('disabled', false);
    //Empty input field
    couponInput.val("");

    //Currency formatter
    let currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
    });

    //Revert fields back to their starting values
    $(".report-payment-panel .sub-total .figure").html(currencyFormat.format($(".report-payment-panel .sub-total .figure").data("start-value")/100));
    $(".report-payment-panel .vat .figure").html(currencyFormat.format($(".report-payment-panel .vat .figure").data("start-value")/100));
    $(".report-payment-panel .total-due .figure").html(currencyFormat.format($(".report-payment-panel .total-due .figure").data("start-value")/100));

});

body.on('click', '.report-payment-panel .checkout-button', function(e) {
    //Make sure that the user can't multi-click the button
    if($(this).html() === "Redirecting..."){
        return;
    }

    //Get the applied coupon code (if any)
    let couponCode = $(".report-payment-panel .coupon-form input[name=coupon]:disabled").val();
    if(typeof couponCode === "undefined"){
        couponCode = "";
    }

    //Get the UUID of the report being purchased
    const reportUUID = $(".report-generator-panel input[name=uuid]").val();

    //Enable redirection animations
    $(this).html("Redirecting...");

    //Retrieve the Stripe Checkout URL
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "generateStripeCheckout",
                couponCode: couponCode,
                reportUUID: reportUUID
            })},
        success: function(data){
            //Redirect to Stripe
            window.location.href = data;
        }
    });
});

body.on('click', '.report-generator-panel .stripe-checkout-alert .dismiss', function(e) {
    $(".report-generator-panel .stripe-checkout-alert").remove();
});



body.on('click', 'button#saveAssumptions', function(e) {
    e.preventDefault()
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "saveAssumptions",
                discountRate: $(this).closest(".modal").find("input[name=discountRate]").val()/100,
                electricityIncreaseFactor: $(this).closest(".modal").find("input[name=elecIncreaseFactor]").val()/100,
                gasIncreaseFactor: $(this).closest(".modal").find("input[name=gasIncreaseFactor]").val()/100,
                maintenanceFactor: $(this).closest(".modal").find("input[name=maintenanceFactor]").val(),
        })},
        success: function(data){
            location.reload();
        }
    });
});

body.on('click', '.collapse-button', function(e) {
    let collapse = $(".collapse[data-collapse=" + $(this).data("target-collapse") + "]");
    if(!collapse.hasClass("open")){
        $(this).children(".open-text").hide();
        $(this).children(".closed-text").show();
        rotate($(this).children("svg"), 180)
    } else {
        $(this).children(".open-text").show();
        $(this).children(".closed-text").hide();
        rotate($(this).children("svg"), 0)
    }
    collapse.toggleClass("open").slideToggle("fast");
});

body.on('change', '#includeAdditionalFigureHeatPump', function(e) {
    //Elements
    let checkbox = $(this);

    //Disable input
    checkbox.prop('disabled', true);

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "includeAdditionalFigureHeatPump",
                surveyId: $("#surveyId").val(),
                checked: checkbox.is(":checked")
            })
        },
        success: function(data) {
            //Refresh page
            window.location.reload();
        }
    });
});

body.on('change', '#includeAdditionalFigureCommercialHeatPump', function(e) {
    //Elements
    let checkbox = $(this);

    //Disable input
    checkbox.prop('disabled', true);

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "includeAdditionalFigureCommercialHeatPump",
                surveyId: $("#surveyId").val(),
                checked: checkbox.is(":checked")
            })
        },
        success: function(data) {
            //Refresh page
            window.location.reload();
        }
    });
});

body.on('click', '.embed-form .cancel', function(e) {
    $(this).closest(".embed-form")
});

body.on('click', '#enterRoomReference', function(e) {
    if($("#enterRoomReference").is(":checked")){
        $(".input#roomReference").show();
    } else {
        $(".input#roomReference").hide();
        $(".input input#roomReference").val("");
    }
});

body.on('click', '#checkPropertyOwner', function(e) {
    if($("#checkPropertyOwner").is(":checked")){
        $(".block#ownerEmailBlock").show();
    } else {
        $(".block#ownerEmailBlock").hide();
        $(".input input#ownerEmail").val("");
    }
});

body.on('input', '.inputSuggestionFieldInput', function(e) {
    switch($(this).data("name")){
        case "replacementBoilerOutput":
            let identifier = "";
            if($(".inputSuggestionFieldInput[data-name='replacementBoilerModelIdentifier']").val() !== "None") {
                identifier = $(".inputSuggestionFieldInput[data-name='replacementBoilerModelIdentifier']").val();
            }

            autoSuggestionField($(this).data("name"), {
                manufacturer: $(".inputSuggestionFieldInput[data-name='replacementBoilerManufacturer']").val(),
                modelName: $(".inputSuggestionFieldInput[data-name='replacementBoilerModelName']").val(),
                modelIdentifier: identifier,
                value: $(this).val()
            });
            break;
        case "replacementBoilerModelName":
            autoSuggestionField($(this).data("name"), {
                manufacturer: $(".inputSuggestionFieldInput[data-name='replacementBoilerManufacturer']").val(),
                value: $(this).val()
            });
            break;
        case "replacementBoilerModelIdentifier":
            autoSuggestionField($(this).data("name"), {
                manufacturer: $(".inputSuggestionFieldInput[data-name='replacementBoilerManufacturer']").val(),
                modelName: $(".inputSuggestionFieldInput[data-name='replacementBoilerModelName']").val(),
                value: $(this).val()
            });
            break;
        default:
            autoSuggestionField($(this).data("name"), $(this).val());
            break;
    }
});

body.on('focusin', '.inputSuggestionFieldInput', function(e) {
    boilerFormCheck();
    switch($(this).data("name")){
        case "replacementBoilerOutput":
            let identifier = "";
            if($(".inputSuggestionFieldInput[data-name='replacementBoilerModelIdentifier']").val() != "None") {
                identifier = $(".inputSuggestionFieldInput[data-name='replacementBoilerModelIdentifier']").val();
            }
            autoSuggestionField($(this).data("name"), {
                manufacturer: $(".inputSuggestionFieldInput[data-name='replacementBoilerManufacturer']").val(),
                modelName: $(".inputSuggestionFieldInput[data-name='replacementBoilerModelName']").val(),
                modelIdentifier: identifier,
                value: $(this).val()
            });
            break;
        case "replacementBoilerModelName":
            autoSuggestionField($(this).data("name"), {
                manufacturer: $(".inputSuggestionFieldInput[data-name='replacementBoilerManufacturer']").val(),
                value: $(this).val()
            });
            break;
        case "replacementBoilerModelIdentifier":
            autoSuggestionField($(this).data("name"), {
                manufacturer: $(".inputSuggestionFieldInput[data-name='replacementBoilerManufacturer']").val(),
                modelName: $(".inputSuggestionFieldInput[data-name='replacementBoilerModelName']").val(),
                value: $(this).val()
            });
            break;
        default:
            autoSuggestionField($(this).data("name"), $(this).val());
            break;
    }

});

body.on('focusout', '.inputSuggestionFieldInput', function(e) {
    window.setTimeout(function() {
        if(!$(e.target).is(".inputSuggestionField[data-name='" + $(this).data("name") + "']")){
            $(".inputSuggestionField[data-name='" + $(this).data("name") + "']").hide();
        }
    }, 100);

});

body.on('click', '.dropdown#boilerFuelType .button', function(e){
    boilerFormCheck();
});

body.on('input', '.subpage.active[data-subpage-name="method-selector"] input#boilerQuantity', function(e){
    if($(this).val() !== ""){
        if($(".boilerInputForm").data("boiler-form-module") === "endoTherm"){
            boilerFormCheck();
        } else {
            $('.subpage.active[data-subpage-name="method-selector"] .option input[type="radio"]').prop("disabled", false);
        }
    } else {
        if($(".boilerInputForm").data("boiler-form-module") === "endoTherm"){
            boilerFormCheck();
        } else {
            $('.subpage.active[data-subpage-name="method-selector"] .option input[type="radio"]').prop("disabled", true);
        }
    }
});

body.on('click', '.subpage.active[data-subpage-name="method-selector"] .dropdown#boilerSystemType li', function(e){
    if($('.subpage.active[data-subpage-name="method-selector"] .dropdown#boilerSystemType .button .selected').text() !== "Select an option") {
        $('.subpage.active[data-subpage-name="method-selector"] .option input[type="radio"]').prop("disabled", false);
    } else {
        $('.subpage.active[data-subpage-name="method-selector"] .option input[type="radio"]').prop("disabled", true);
    }
});


body.on('click', function(e) {
    if(!$(e.target).parent().is(".inputSuggestionField") && !$(e.target).is(".inputSuggestionFieldInput")){
        $(".inputSuggestionField").hide();
    }
});

body.on('click', '.inputSuggestionField li', function(e) {
    let input = $(".inputSuggestionFieldInput[data-name='" + $(this).parent().data("name") + "']");
    input.val($(this).text());
    input.parent().removeClass("error");
    $(".inputSuggestionField[data-name='" + $(this).parent().data("name") + "']").hide();
});

body.on('input', '.boilerManufacturerAuto', function(e) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "boilerModelList",
                value: $(this).val()
            })
        },
        success: function(data) {
            if(data === "<span>No results found</span>"){
                $("#boilerModelName").parent(".input").addClass("disabled");
                $("#boilerModelName .selected").text("Select an option");
                $("#boilerOutput").parent(".input").addClass("disabled");
                $("#boilerOutput .selected").text("Select an option");

            } else {
                $(".dropdown#boilerModelName .simplebar-content").html(data);
                $("#boilerModelName").parent(".input").removeClass("disabled");
                $("#boilerOutput").parent(".input").addClass("disabled");
                $("#boilerOutput .selected").text("Select an option");
            }
        }

    });
});

body.on('click', '.dropdown#boilerModelName .dropdown-content ul li', function(e) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "boilerOutputList",
                manufacturer: $("#boilerManufacturer").val(),
                model: $(this).text()
            })
        },
        success: function(data) {
            if(data === "<span>No results found</span>"){
                $("#boilerOutput").parent(".input").addClass("disabled");
                $("#boilerOutput .selected").text("Select an option");

            } else {
                $(".dropdown#boilerOutput .simplebar-content").html(data);
                $("#boilerOutput .selected").text("Select an option");
                $("#boilerOutput").parent(".input").removeClass("disabled");
            }
        }
    });
});

$(".items .item").on("mouseover", function () {
    if(!$(this).closest(".items").hasClass("disabled")){
        $(this).children(".module-alert").show();
    }
});

$(".items .item").on("mouseout", function () {
    $(this).children(".module-alert").hide();
});



body.on('input', '#insulationThicknessSlider', function(e) {
    let text = "Unknown";
    switch($(this).val()){
        case "0":
            text = "0 mm";
            break;
        case "1":
            text = "10 mm";
            break;
        case "2":
            text = "50 mm";
            break;
        case "3":
            text = "100 mm";
            break;
        case "4":
            text = "150 mm";
            break;
        case "5":
            text = "200 mm";
            break;
        case "6":
            text = "250 mm";
            break;
        case "7":
            text = "270 mm";
            break;
    }
    $('#insulationThicknessValue').text(text);
});

body.on('click', '.dropdown#hasReport .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#hasReport .button .selected").text();
    if (selected === "Yes"){
        $('.hasReportInputs').show();
    } else if (selected === "No"){
        $('.hasReportInputs').hide();
    } else {
        $('.hasReportInputs').hide();
    }
});

body.on('click', '.dropdown#knownFloorArea .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#knownFloorArea .button .selected").text();
    if (selected === "Yes"){
        $('.knownFloorAreaInputs').show();
    } else if (selected === "No"){
        $('.knownFloorAreaInputs').hide();
    } else {
        $('.knownFloorAreaInputs').hide();
    }
});

body.on('click', '.dropdown#propertyType .dropdown-content ul li', function(e) {
    const propertyType = $(".dropdown#propertyType .button .selected").text();
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "propertyBedrooms",
                details: {
                    propertyType: propertyType
                }
            })
        },
        success: function(data) {
            let bedroomBtnSelectedElement = $(".dropdown#bedroomNumber .button .selected");

            //Parse response form request
            const options = JSON.parse(data);

            //Add the default 'Select an option' placeholder to the top of the dropdown menu
            let html = "<li>Select an option</li>";

            //Put the options into HTML form
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });

            //Add the HTML options to the dropdown menu on the page
            $(".dropdown#bedroomNumber .simplebar-content").html(html);

            //Get the currently selected option
            const selected = bedroomBtnSelectedElement.text();

            //Change the currently selected option if it is no longer available and isn't a placeholder (i.e. user has selected a small property type)
            if(!options.includes(parseInt(selected)) && selected !== "Select an option"){
                bedroomBtnSelectedElement.text("Select an option");
            }
        }
    });
});

body.on('click', '.dropdown#governmentSubsidies .dropdown-content ul li', function(e) {
    let selected = $(".dropdown#governmentSubsidies .button .selected").text();
    if (selected === "Other (Manually Enter Rate)"){
        $('.usingManualSubsidies').show();
    } else {
        $('.usingManualSubsidies').hide();
    }
});

//#region Solar PV

body.on('input', '#roofSlopeSlider', function(e) {
    $('#roofSlopeDegree').text($(this).val() + "°");

    let step = 0;
    if($(this).val() > 0){
        step = $(this).val()/5;
    }

    $('#roofSlopeImage').css("background-position-y", (9500 - (step*500) - 100));

});

body.on('input', '#shadingSlider', function(e) {
    let text = "Unknown";
    switch($(this).val()){
        case "0":
            text= "More than 80% of unobstructed sky";
            break;
        case "1":
            text = "60-80% of unobstructed sky";
            break;
        case "2":
            text = "20-60% of unobstructed sky";
            break;
        case "3":
            text = "Less than 20% of unobstructed sky";
            break;
    }

    $('#roofShadingImage').css("background-position-y", (200 + ($(this).val()*200)));
    $('#shadingValue').text(text);
});


body.on('click', '.module-form .form-progress .stage-button', function(e) {
    //Check to see if stage is disabled
    if($(this).hasClass("disabled")){
        return;
    }

    let currentPage = $(".module-form .form-pages").data("current-page");

    if(currentPage > $(this).data("page")){
        moduleFormChangePage($(this).data("page"));
    } else {
        moduleFormInputPageChange($(this).data("page"));
    }
});

body.on('click', '.module-form .form-actions .button.next', function(e) {
    let currentPage = $(".module-form .form-pages").data("current-page");
    let nextPage = currentPage + 1;

    //Check to see if next stage/page is disabled
    if($(`.module-form .form-progress .stage-button[data-page=${nextPage}], .module-form .form-progress-v2 .stage-button[data-page=${nextPage}]`).hasClass("disabled")){
        let nextPageFound = false;
        //Find the next active page
        $(".module-form .form-progress .stage-button:not(.disabled), .module-form .form-progress-v2 .stage-button:not(.disabled)").each(function(i, obj) {
            //Check to see if page number is less than the current page
            if($(obj).data("page") > currentPage && !nextPageFound){
                nextPageFound = true;
                nextPage = $(obj).data("page");
            }
        });
    }

    checkTechnologyData();
    moduleFormInputPageChange(nextPage);
});


body.on('click', '.report-action .dismiss, .report-action .options .no', function(e) {
    $(this).closest(".report-action").hide();
});

body.on('click', '.report-action .options .yes', function(e) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    window.location.href = "/report?id=" + params["id"];
});

body.on('click', '.module-form .form-actions .button.previous', function(e) {
    let currentPage = $(".module-form .form-pages").data("current-page");
    let lastPage = currentPage-1;

    if(currentPage <= 1){
        return;
    }

    //Check to see if next stage/page is disabled
    if($(`.module-form .form-progress .stage-button[data-page=${lastPage}], .module-form .form-progress-v2 .stage-button[data-page=${lastPage}]`).hasClass("disabled")){
        let lastPageFound = false;
        //Find the next active page
        $($(".module-form .form-progress .stage-button:not(.disabled), .module-form .form-progress-v2 .stage-button:not(.disabled)").get().reverse()).each(function(i, obj) {
            //Check to see if page number is less than the current page
            if($(obj).data("page") < currentPage && !lastPageFound){
                lastPageFound = true;
                lastPage = $(obj).data("page");
            }
        });
    }

    moduleFormChangePage(lastPage);
});

body.on('click', '.form-pages .page .dropdown-content li', function(e) {
    if($(this).text() !== "Select an option"){
        $(this).closest(".dropdown").removeClass("error");
    }
});

body.on('input', '.form-pages .page input', function(e) {
    if($(this).text() !== ""){
        $(this).removeClass("error");
    }
});


//#endregion



body.on("click", ".report-generator-panel .info-row .body-toggle, .report-generator-panel .info-row .head", function(){
    let row = $(this).closest(".info-row");
    let toggle = $(this).find(".body-toggle");

    if($(this).hasClass(".body-toggle")){
        toggle = $(this);
    }

    //Check to see if the info row is currently open
   if(row.hasClass("open")){
       //Close row
       animateRotate(toggle, 180, 360, 250);
       row.children(".body").slideUp(250, function() {
           row.removeClass("open");
       });
   } else {
       //Close all open rows
       let openRow = $(".report-generator-panel .info-row.open");
       animateRotate($(".report-generator-panel .info-row.open .body-toggle"), 180, 360, 250);
       openRow.children(".body").slideUp(250, function() {
           openRow.removeClass("open");
       });
       //Open Row
       animateRotate(toggle, 0, 180, 250);
       row.children(".body").slideDown(250, function() {
           row.addClass("open");
       });
   }
});











//Boiler Input Field Wizard Triggers

body.on('input', '.boilerInputForm .input input', function(){
    boilerInputFieldWizard();
});

body.on('click', '.boilerInputForm .input .inputSuggestionField li', function(){
    boilerInputFieldWizard();
});

body.on('click', '.boilerInputForm .input .dropdown .dropdown-content ul li', function(){
    boilerInputFieldWizard();
});

body.on('click', '#building-heat-loss-calculator #saveExternalElement', function(e) {
    const values = {
        elementName: $('#building-heat-loss-calculator input[name=elementName]').val(),
        uValue: $('#building-heat-loss-calculator input[name=uValue]').val(),
        length: $('#building-heat-loss-calculator input[name=length]').val(),
        widthHeight: $('#building-heat-loss-calculator input[name=widthHeight]').val(),
        number: $('#building-heat-loss-calculator input[name=number]').val()
    }

    let hasErrors = false;

    if(values.elementName === ""){
        $('#building-heat-loss-calculator input[name=elementName]').parent(".input").addClass("error");
        hasErrors = true;
    }

    if(values.uValue === ""){
        $('#building-heat-loss-calculator input[name=uValue]').parent(".input").addClass("error");
        hasErrors = true;
    }

    if(values.length === ""){
        $('#building-heat-loss-calculator input[name=length]').parent(".input").addClass("error");
        hasErrors = true;
    }

    if(values.widthHeight === ""){
        $('#building-heat-loss-calculator input[name=widthHeight]').parent(".input").addClass("error");
        hasErrors = true;
    }

    if(values.number === ""){
        $('#building-heat-loss-calculator input[name=number]').parent(".input").addClass("error");
        hasErrors = true;
    }

    if(hasErrors) return;

    //Clear inputs
    $('#building-heat-loss-calculator input[name=elementName]').val("");
    $('#building-heat-loss-calculator input[name=uValue]').val("");
    $('#building-heat-loss-calculator input[name=length]').val("");
    $('#building-heat-loss-calculator input[name=widthHeight]').val("");
    $('#building-heat-loss-calculator input[name=number]').val("");

    //Add to table
    $("#externalElementsTable tbody").append(`<tr><td>${values.elementName}</td><td>${values.uValue}</td><td>${values.length}</td><td>${values.widthHeight}</td><td>${values.number}</td><td><button class="deleteExternalElementRow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></td></tr>`);

    //Check to see if the whitespace holder should be disabled
    if($("#externalElementsTable tbody tr").length > 0){
        $(".external-elements-table-whitespace-holder").hide();
    }
});

body.on('click', '#building-heat-loss-calculator .deleteExternalElementRow', function(e) {
    if (confirm("Are you sure you want to delete this row?") == true) {
        $(this).closest("tr").remove();

        //Check to see if the whitespace holder should be enabled
        if($("#externalElementsTable tbody tr").length < 1){
            $(".external-elements-table-whitespace-holder").show();
        }
    }
});

body.on('input', '#building-heat-loss-calculator .input.error input', function(e) {
    $(this).parent(".input").removeClass("error");
});

body.on('click', '.purchased-alert .dismiss', function(e) {
    //Remove parameter from URL
    let url = window.location.href.toLowerCase().replace(/.purchased=.*/g, "");
    window.history.pushState({}, "", url);

    //Hide alert
    $(this).parent().hide();
});




// Other element clicks - KEEP AT BOTTOM
$(document).on('click', function (e) {
    // Dropdowns
    $(".dropdown .dropdown-content.open").removeClass("open");
    $(".menu .menu-content.open").removeClass("open");
});

$(document).ready(function(){
    checkActionBar();

    let technologiesWidth = 0;
    $(".results-selector .technologies .item").each(function() {
        technologiesWidth += $(this).outerWidth();
    });

    if(technologiesWidth > $(".results-selector").width()){
        $(".results-selector .left-button").show();
        $(".results-selector .right-button").show();
    }

    $('.technology-nav .nav-items .item').first().addClass("active");
    if($(".items .item.active").length > 0){
        $(".not-edited-help").text("");
        $(".actions .next").removeClass("disabled");
        $(".actions .next-button-text").removeClass("disabled");
        $(".actions-bar-container .bot-guide").addClass("disabled");
    }
});


$("body").on("click", ".back-to-top", function(){
    $("html, body").animate({ scrollTop: 0 }, "slow");
})

$("body").on("click", ".results-selector .right-button", function(){
    document.querySelector(".results-selector .technologies").scrollTo($(".results-selector .technologies").scrollLeft() + 150,0);
})

$("body").on("click", ".results-selector .left-button", function(){
    document.querySelector(".results-selector .technologies").scrollTo($(".results-selector .technologies").scrollLeft() - 150,0);
})

//Back to top button
$(window).on("scroll load", function () {
    backToTopCheck();
});


body.on('click', '.info-panel .button-bar .assessor-button', function(e) {
    //Pre-entered message
    const message = "I am interested in having a ESOS assessment.";

    //Set fields
    let firstName = $('.modal.contact-modal .modal-content .input input[name=firstName]');
    let lastName = $('.modal.contact-modal .modal-content .input input[name=lastName]');
    let emailAddress = $('.modal.contact-modal .modal-content .input input[name=emailAddress]');
    let messageContent = $('.modal.contact-modal .modal-content .input textarea[name=messageContent]');

    //Reset fields
    firstName.val(firstName.data('default'));
    lastName.val(lastName.data('default'));
    emailAddress.val(emailAddress.data('default'));
    messageContent.val(message);

    //Open contact modal
    $(".modal[data-modal='contact']").addClass("open");
});

body.on('click', '.info-panel .button-bar .carbon-button', function(e) {
    //Pre-entered message
    const message = "I am interested in purchasing carbon offsets.";

    //Set fields
    let firstName = $('.modal.contact-modal .modal-content .input input[name=firstName]');
    let lastName = $('.modal.contact-modal .modal-content .input input[name=lastName]');
    let emailAddress = $('.modal.contact-modal .modal-content .input input[name=emailAddress]');
    let messageContent = $('.modal.contact-modal .modal-content .input textarea[name=messageContent]');

    //Reset fields
    firstName.val(firstName.data('default'));
    lastName.val(lastName.data('default'));
    emailAddress.val(emailAddress.data('default'));
    messageContent.val(message);

    //Open contact modal
    $(".modal[data-modal='contact']").addClass("open");
});

body.on('click', '.survey-edit-button', function(e) {
    //Open modal
    $(".modal.credit-purchase-modal[data-purchase-item='survey-edit']").addClass("open");
});

body.on('click', '.survey-simple-report-download', function(e) {
    if($(this).hasClass("purchased")) return;

    //Open modal
    $(".modal.credit-purchase-modal[data-purchase-item='simple-report']").addClass("open");
});

body.on('click', '.info-panel .button-bar .report-button', function(e) {
    if($(this).hasClass("purchased")) return;

    //Open modal
    $(".modal.credit-purchase-modal[data-purchase-item='professional-report']").addClass("open");
});

body.on('click', '.modal.credit-purchase-modal .modal-footer .submit', function(e) {
    //Get elements
    let submitButton = $(this)
    let modal = submitButton.closest(".modal");

    //Get the name of the item being purchases
    let purchase = modal.data('purchase-item');

    if(purchase === "survey-edit"){
        window.location.href = "?editSurvey=" + $("#surveyId").val();
        return;
    }

    //Disable button
    submitButton.prop('disabled', true);

    //Change button text
    submitButton.text("Purchasing...");

    //Clear error message
    modal.find(".error-block").addClass("hide");
    modal.find(".error-block .description").text("");

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "creditPurchase",
                surveyId: $("#surveyId").val(),
                item: purchase
            })
        },
        success: function (data) {
            //Re-enable button
            submitButton.prop('disabled', false);

            //Set the button text back
            submitButton.text("Purchase");

            if(data.success){
                //Redirect to purchase URL
                window.location.href = data.url;
            } else {
                //Show error message
                modal.find(".error-block").removeClass("hide");
                modal.find(".error-block .description").text(data.message);
            }
        }
    });
});

body.on('click', '.modal.contact-modal #contactModalActions .cancel', function(e) {
    //Close modal
    $(".modal.contact-modal").removeClass("success").removeClass("open");

    //Allow scroll bar on body
    $("body").removeClass("no-scroll");
});

body.on('change', '#toggleEmissionsConsumptionFields', function(e) {
    //Get if checked
    const checked = $(this).prop('checked');

    if(checked){
        //Show fields
        $("#emissionsConsumptionFields").show();
    } else {
        //Hide fields
        $("#emissionsConsumptionFields").hide();
    }
});

body.on('click', '.modal.contact-modal #contactModalActions #submitContactModal', function(e) {
    //Change button text
    $(this).html("Loading...");

    //Disable button
    $(this).prop('disabled', true);

    //Get the current survey's id
    const urlParams = new URLSearchParams(window.location.search);
    const surveyID = urlParams.get('id')

    //Get fields
    let firstName = $('.modal.contact-modal .modal-content .input input[name=firstName]').val();
    let lastName = $('.modal.contact-modal .modal-content .input input[name=lastName]').val();
    let emailAddress = $('.modal.contact-modal .modal-content .input input[name=emailAddress]').val();
    let messageContent = $('.modal.contact-modal .modal-content .input textarea[name=messageContent]').val();

    console.log("clcikyyyy");

    //Send AJAX Request
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "contactForm",
                surveyId: surveyID,
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                messageContent: messageContent
            })
        },
        success: function(data){
            console.log(data);

            //Allow scroll bar on body
            $("body").removeClass("no-scroll");

            let submitButton = $(".contact-modal .modal-footer #submitContactModal");

            if(data.response === "success"){
                //Hide error
                $(".contact-modal .modal-content .error-area .error-banner").hide();

                //Show alert
                $(".modal.contact-modal").addClass("success");

                //Change button text back to default
                submitButton.html("Approve");

                //Re-enable button
                submitButton.prop('disabled', false);

                //Clear fields
                $('.modal.contact-modal .modal-content .input input[name=firstName]').val("")
                $('.modal.contact-modal .modal-content .input input[name=lastName]').val("");
                $('.modal.contact-modal .modal-content .input input[name=emailAddress]').val("");
                $('.modal.contact-modal .modal-content .input textarea[name=messageContent]').val("");
            } else {
                //Remove alert
                $(".modal.contact-modal").removeClass("success");

                switch(data.errorType){
                    case "fields": //There is an issue with one or more of the fields (i.e. empty when required)
                        //Check to see errors have actually been given
                        if(data.errors.length > 0){
                            $(".contact-modal .modal-content .error-area .error-banner").show(); //Show error
                            $(".contact-modal .modal-content .error-area .error-banner ul").empty(); //Clear error list
                        }

                        for(let i = 0; i < data.errors.length; i++){
                            if(data.errors[i].error === "empty"){
                                $(".contact-modal .modal-content .error-area .error-banner ul").append("<li>" + data.errors[i].displayName + " field is empty</li>");
                            }
                        }

                        //Change button text back to default
                        submitButton.html("Approve");

                        //Re-enable button
                        submitButton.prop('disabled', false);
                        break;
                    case "creationError": //An error when trying to save the new contact message

                        break;
                }

                // setTimeout(function(){
                //     //Change button text back to default
                //     submitButton.html("Approve");
                //
                //     //Re-enable button
                //     submitButton.prop('disabled', false);
                // }, 1000);
            }
        }
    });

});


//#region Functions


function zeroNotNon(number) {
    if(number){
        return parseInt(number);
    } else {
        return 0;
    }
}

function autoSuggestionField(name, value) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: name + "Feed",
                value: value
            })
        },
        success: function(data) {
            $(".inputSuggestionField").hide();
            let suggestionField = $(".inputSuggestionField[data-name='" + name + "']");
            suggestionField.html(data);
            if(suggestionField.find('span.feed-error').length !== 0){
                suggestionField.addClass("error");
            } else {
                suggestionField.removeClass("error");
            }
            suggestionField.show();
        }
    });
}

function backToTopCheck(){
    let currentScroll = $(this).scrollTop();
    if (currentScroll >= ($(window).height()/2)){
        $(".back-to-top").show();
    } else {
        $(".back-to-top").hide();
    }
}

function rotate(object, degree) {
    object.css({WebkitTransform: 'rotate(' + degree + 'deg)'});
    object.css({'-moz-transform': 'rotate(' + degree + 'deg)'});
}

function searchDropdown(input) {
    let dropdownNoContent = $(".dropdown .dropdown-content ul .no-data");
    dropdownNoContent.addClass("hidden");
    let value = input.val().toLowerCase().trim();
    let found = 0;
    $(".dropdown-content.searchable ul li").show().filter(function() {
        if($(this).text().toLowerCase().trim().indexOf(value) === -1){
            return true;
        } else {
            found++
            return false;
        }
    }).hide();
    if(found < 1){
        dropdownNoContent.removeClass("hidden");
    }
}

function lightsModalSerialize(){
    let data = {};

    data["roomName"] = $(".input input#roomReference").val();

    //Standard Row
    data["fittingType"] = $(".dropdown#fittingType .button .selected").text();
    data["fixtureType"] = $(".dropdown#fixtureType .button .selected").text();

    //Floro
    data["tubeType"] = $(".dropdown#tubeType .button .selected").text();
    data["length"] = $(".dropdown#length .button .selected").text();
    data["ballast"] = $(".dropdown#ballast .button .selected").text();

    //Not Floro
    data["wattage"] = $(".dropdown#wattage .button .selected").text();
    data["lampCapType"] = $(".dropdown#lampCapType .button .selected").text();

    // Standard Row
    data["upgradeType"] = $(".dropdown#upgradeType .button .selected").text();
    data["standardQuantity"] = $("#standardQuantity").val();
    data["emergencyQuantity"] = $("#emergencyQuantity").val();
    data["colourTemperature"] = $(".dropdown#colourTemperature .button .selected").text();

    //Sensors and detectors
    if($("#daylightSensorQuantityToggle").prop("checked")){
        data["daylightSensors"] = parseInt($("#daylightSensorQuantity").val());
    } else {
        data["daylightSensors"] = 0;
    }
    if($("#presenceDetectorsQuantityToggle").prop("checked")){
        data["presenceDetectors"] = parseInt($("#presenceDetectorsQuantity").val());
    } else {
        data["presenceDetectors"] = 0;
    }
    data["roomType"] = $(".dropdown#roomType .button .selected").data("value");

    //Daily Usage
    data["usage"] = {};
    data["usage"]["weekdays"] = {};
    data["usage"]["weekend"] = {};
    data["workweeks"] = $("input#workWeeks").val();

    //Weekdays
    data["usage"]["weekdays"]["day"] = $("#mon-fri-day-rate").val();
    data["usage"]["weekdays"]["night"] = $("#mon-fri-night-rate").val();

    //Weekend
    data["usage"]["weekend"]["day"] = $("#sat-sun-day-rate").val();
    data["usage"]["weekend"]["night"] = $("#sat-sun-night-rate").val();

    switch(data["fittingType"]){
        case "Fluoro-Single":
        case "Fluoro-Twin":
        case "Fluoro-Triple":
        case "Fluoro-Quad":
            data["fittingCode"] = data["fittingType"] + " " + data["tubeType"] + " " + data["length"] + " " + data["ballast"] + " 0 " + data["fixtureType"];
            break;
        default:
            if(data["lampCapType"] === "Not applicable"){
                data["fittingCode"] = data["fittingType"] + " " + data["wattage"] + " " + data["fixtureType"];
            } else {
                data["fittingCode"] = data["fittingType"] + " " + data["wattage"] + " " + data["lampCapType"] + " " + data["fixtureType"];
            }
            break;
    }

    return data;
}

function chpUnitModalSerialize(){
    let data = {};

    data["boilerEfficiency"] = $("#boilerEfficiency").val();


    // Daily Usage
    data["usage"] = {};
    data["usage"]["weekdays"] = {};
    data["usage"]["weekend"] = {};
    data["workweeks"] = $("input#workWeeks").val();

    // Weekdays
    data["usage"]["weekdays"]["day"] = $("#mon-fri-day-rate").val();
    data["usage"]["weekdays"]["night"] = $("#mon-fri-night-rate").val();

    // Weekend
    data["usage"]["weekend"]["day"] = $("#sat-sun-day-rate").val();
    data["usage"]["weekend"]["night"] = $("#sat-sun-night-rate").val();

    return data;
}

function replacementBoilerSerialize(){
    let data = {};

    if($("#boilerModelIdentifier").val() === "None") $("#boilerModelIdentifier").val("");

    data["boilerMethod"] = $(".boilerInputForm").data("boiler-method");

    switch(data["boilerMethod"]){
        case "1":
            data["boilerData"] = {
                "manufacturer": $("#boilerManufacturer").val(),
                "modelName": $("#boilerModelName").val(),
                "modelIdentifier": $("#boilerModelIdentifier").val(),
                "output": $('.subpage[data-subpage-name="method-1"] #boilerOutput').val(),
                "fuelType": $('.subpage[data-subpage-name="method-1"] .dropdown#boilerFuelType .button .selected').text(),
                "heatingUsage": $(".dropdown#boilerHeatingUsage .button .selected").text(),
                "quantity": $('.subpage[data-subpage-name="method-1"] #boilerQuantity').val()
            }
            break;
        case "2":
            data["boilerData"] = {
                "seasonalEfficiency": $("#seasonalEfficiency").val(),
                "manufacturer": $("#boilerManufacturer").val(),
                "modelName": $("#boilerModelName").val(),
                "modelIdentifier": $("#boilerModelIdentifier").val(),
                "output": $('.subpage[data-subpage-name="method-selector"] #boilerOutput').val(),
                "fuelType": $('.subpage[data-subpage-name="method-selector"] .dropdown#boilerFuelType .button .selected').text(),
                "heatingUsage": $(".dropdown#boilerHeatingUsage .button .selected").text(),
                "quantity": $('.subpage[data-subpage-name="method-selector"] #boilerQuantity').val()
            }
            break;
        case "3":
            data["boilerData"] = {
                "efficiency100": $("#boilerManufacturerEfficiency100").val(),
                "efficiency30": $("#boilerManufacturerEfficiency30").val(),
                "manufacturer": $("#boilerManufacturer").val(),
                "modelName": $("#boilerModelName").val(),
                "modelIdentifier": $("#boilerModelIdentifier").val(),
                "output": $('.subpage[data-subpage-name="method-selector"] #boilerOutput').val(),
                "fuelType": $('.subpage[data-subpage-name="method-selector"] .dropdown#boilerFuelType .button .selected').text(),
                "heatingUsage": $(".dropdown#boilerHeatingUsage .button .selected").text(),
                "quantity": $('.subpage[data-subpage-name="method-selector"] #boilerQuantity').val()
            }
            break;
        case "4":
            data["boilerData"] = {
                "existingBoilerInputLoad100": $("#existingBoilerInputLoad100").val(),
                "existingBoilerOutputLoad100": $("#existingBoilerOutputLoad100").val(),
                "existingBoilerInputLoad30": $("#existingBoilerInputLoad30").val(),
                "existingBoilerOutputLoad30": $("#existingBoilerOutputLoad30").val(),
                "existingBoilerInputOutputDetails": $(".dropdown#existingBoilerInputOutputDetails .button .selected").text(),
                "manufacturer": $("#boilerManufacturer").val(),
                "modelName": $("#boilerModelName").val(),
                "modelIdentifier": $("#boilerModelIdentifier").val(),
                "output": $('.subpage[data-subpage-name="method-selector"] #boilerOutput').val(),
                "fuelType": $('.subpage[data-subpage-name="method-selector"] .dropdown#boilerFuelType .button .selected').text(),
                "heatingUsage": $(".dropdown#boilerHeatingUsage .button .selected").text(),
                "quantity": $('.subpage[data-subpage-name="method-selector"] #boilerQuantity').val()
            }
            break;
        case "5":
            data["boilerData"] = {
                "energyRating": $(".dropdown#existingBoilerEnergyRating .button .selected").text(),
                "manufacturer": $("#boilerManufacturer").val(),
                "modelName": $("#boilerModelName").val(),
                "modelIdentifier": $("#boilerModelIdentifier").val(),
                "output": $('.subpage[data-subpage-name="method-selector"] #boilerOutput').val(),
                "fuelType": $('.subpage[data-subpage-name="method-selector"] .dropdown#boilerFuelType .button .selected').text(),
                "heatingUsage": $(".dropdown#boilerHeatingUsage .button .selected").text(),
                "quantity": $('.subpage[data-subpage-name="method-selector"] #boilerQuantity').val()
            }
            break;
    }



    //Identify which module the boiler input is for
    switch($(".boilerInputForm").data("boiler-form-module")){
        case "bmu":
            data["action"] = "addBMUBoiler";
            break;
        case "boilerReplacement":
            data["action"] = "addReplacementBoiler";
            break;
        case "endoTherm":
            data["action"] = "addEndoThermBoiler";

            if(data["boilerMethod"] === "1"){
                data["boilerData"]["systemType"] = $('.subpage[data-subpage-name="method-1"] .dropdown#boilerSystemType .button .selected').text();
            } else {
                data["boilerData"]["systemType"] = $('.subpage[data-subpage-name="method-selector"] .dropdown#boilerSystemType .button .selected').text();
            }

            break;
        default:
            data["action"] = "addReplacementBoiler";
            break;
    }


    data["boilerAlias"] = $('#boilerAlias').val();

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify(data)},
        success: function (data) {

            switch($(".boilerInputForm").data("boiler-form-module")){
                case "bmu":
                    $("#bmuList tbody").append(data);
                    if($("#bmuList tbody tr").length > 0) {
                        $(".listNoData").remove();

                        //Remove action bar mask
                        $(".actions-bar-container .error-mask").removeClass("active");
                        //();
                    }
                    break;
                case "boilerReplacement":
                    $("#boilerReplacementList tbody").append(data);
                    if($("#boilerReplacementList tbody tr").length > 0) {
                        $(".listNoData").remove();

                        //Remove action bar mask
                        $(".actions-bar-container .error-mask").removeClass("active");
                        //();
                    }
                    break;
                case "endoTherm":
                    $("#endoThermList tbody").append(data);
                    if($("#endoThermList tbody tr").length > 0) {
                        $(".listNoData").remove();

                        //Remove action bar mask
                        $(".actions-bar-container .error-mask").removeClass("active");
                        //();
                    }
                    break;
            }

            $(".input input#boilerAlias").val("");
            $(".input input#boilerManufacturer").val("");
            $(".input input#boilerModelName").val("");
            $(".input input#boilerModelIdentifier").val("");
            $(".input input#boilerQuantity").val("");

            $(".input input#boilerOutput").val("");

            $(".dropdown#boilerFuelType .button .selected").text("Select an option");
            $(".dropdown#boilerSystemType .button .selected").text("Select an option");

            $(".input input#seasonalEfficiency").val("");
            $(".input input#boilerManufacturerEfficiency100").val("");
            $(".input input#boilerManufacturerEfficiency30").val("");
            $(".dropdown#boilerManufacturerEfficiencyBase .button .selected").text("Select an option");
            $(".input input#existingBoilerInputLoad100").val("");
            $(".input input#existingBoilerOutputLoad100").val("");
            $(".input input#existingBoilerInputLoad30").val("");
            $(".input input#existingBoilerOutputLoad30").val("");
            $(".dropdown#existingBoilerInputOutputDetails .button .selected").text("Select an option");
            $(".dropdown#existingBoilerEnergyRating .button .selected").text("Select an option");
            $(".input input#assessmentPostcode").val("");
            $(".input input#assessmentStart").val("");
            $(".input input#assessmentEnd").val("");
            $(".input input#assessmentStartDD").val("");
            $(".input input#assessmentEndDD").val("");
            $(".input input#assessmentDegreeDays").val("0");

            $(".boilerInputForm").data("form-page", "existing-boiler-input");

            $(".inner-embed-form-page[data-page-name='existing-boiler-input']").addClass("active")
            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage").removeClass("active");
            $(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='boiler-entry-options']").addClass("active");

            $(".boilerInputForm.embed-form").slideToggle("slow");
            boilerInputFieldWizard();
            checkTechnologyData();
        }
    });
}

function boilerFormCheck(){

    //Only perform the boiler form check on the first page
    if(!$(".inner-embed-form-page[data-page-name='existing-boiler-input'] .subpage[data-subpage-name='method-1']").hasClass("active")){
        return;
    }

    let boilerData = {
        "manufacturer": $(".subpage.active #boilerManufacturer").val(),
        "modelName": $(".subpage.active #boilerModelName").val(),
        "modelIdentifier": $(".subpage.active #boilerModelIdentifier").val(),
        "output": $(".subpage.active #boilerOutput").val(),
    }


    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "boilerFormCheck",
                data: boilerData
            })
        },
        success: function (data) {
            data = JSON.parse(data);

            if(data.formValid === false){
                $(".no-data-alert").addClass("show");
                $(".no-data-mask").addClass("show");
            }
        }
    });
}

function importBoilerRefresh(module){
    importBoilerUpdate(module);
    importBoilerCount(module, function (count){
        if(count > 0){
            $(".importBoilers").removeClass("hidden");
        } else {
            $(".importBoilers").addClass("hidden");
        }
    })
}

function importBoilerUpdate (module) {
    let data = {
        action: "boilerImportUpdate",
        receivingModule: module
    }
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {data: JSON.stringify(data)},
        success: function (data) {
            $(".importBoilers .modal-content #boilerImportList tbody").html(data);
        }
    });
}

function importBoilerCount(module, callback) {
    let data = {
        action: "boilerImportCount",
        receivingModule: module
    }
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {data: JSON.stringify(data)},
        success: function (data) {
            callback(data);
        }
    });
}

function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function checkActionBar(){
    if(!$(".actions-bar-container").hasClass("follow")) return;

    let barContainer = $(".actions-bar-container");
    let bar = $(".actions-bar-container .actions-bottom-bar");


    if(barContainer.length < 1 || bar.length < 1){
        return;
    }

    if(actionBarLocation === 0 || !barContainer.hasClass("fixed")) actionBarLocation = Math.round(bar.offset().top);
    if(actionBarLocation > $(document).height()){
        actionBarLocation = Math.round(bar.offset().top);
    }

    if(actionBarLocation > ($(window).scrollTop() + $(window).height() - bar.height())){
        if(!barContainer.hasClass("fixed")){
            actionBarLocation = Math.round(bar.offset().top);
            barContainer.addClass("fixed");
            barContainer.addClass("container");
            $("#content-last").css("padding-bottom", bar.height() + "px")
        }
    } else {
        if(barContainer.hasClass("fixed")){
            barContainer.removeClass("fixed");
            barContainer.removeClass("container");
            $("#content-last").css("padding-bottom", "0px")
        }
    }
}

function animateRotate(element, startAngle, endAngle, duration) {
    $({deg: startAngle}).animate({deg: endAngle}, {
        duration: duration,
        step: function(now) {
            element.css({
                transform: 'rotate(' + now + 'deg)'
            });
        }
    });
}

function professionalReportGeneratorCheck() {
    const pageNum = $(".report-generator-panel .page.show").data("page");

    //Specific page logic
    switch(pageNum){
        case 1:
            //Add the error classes to inputs

            //Classic input types (text, number and date)
            let classicInputs = $('.report-generator-panel input[type=text], .report-generator-panel input[type=number], .report-generator-panel input[type=date]');
            classicInputs.each(function() {
                //Make sure the field isn't optional
                if ($(this).closest(".info-row").hasClass("optional")) return;

                //Skip manual degree day fields
                if ($(this).parents("#manualDegreeDaysFields").length > 0) {
                    //Check to see if manual degree days data is being used
                    if (!$("#manualDegreeDaysData").is(":checked")) {
                        $(this).parent().removeClass("error");
                        return;
                    }
                }

                if ($(this).val() === "") {
                    $(this).parent().addClass("error");
                    $(this).parent().removeClass("warning");
                } else if ($(this).is("#annualElectricityConsumption") && $(this).val() < 1) {
                    $(this).parent().addClass("warning");
                    $(this).parent().removeClass("error");
                } else {
                    $(this).parent().removeClass("error");
                    $(this).parent().removeClass("warning");
                }
            });

            //Dropdowns
            let dropdowns = $('.report-generator-panel .dropdown .button .selected');
            dropdowns.each(function(){
                //Make sure the field isn't optional
                if($(this).closest(".info-row").hasClass("optional")) return;

                let field = $(this).closest(".dropdown-field");
                if(field.hasClass("dropdown-placeholder")){
                    field.addClass("error");
                } else {
                    if($(this).innerText === ""){
                        field.addClass("error");
                    } else {
                        field.removeClass("error");
                    }
                }
            });

            let errors = false;

            //Info Row Status Checks
            $('.report-generator-panel .info-row').each(function(){
                let hasErrors = false;
                let hasWarnings = false;
                let fields = $(this).find(".field");

                fields.each(function(){
                    if($(this).hasClass("error")){
                        hasErrors = true;
                    }
                    if($(this).hasClass("warning")){
                        hasWarnings = true;
                    }
                });

                //Check if the info row has errors
                if(hasErrors){
                    //Show fail status icon
                    $(this).find(".status-icon.success").addClass("hide");
                    $(this).find(".status-icon.optional").addClass("hide");
                    $(this).find(".status-icon.fail").removeClass("hide");
                } else if (hasWarnings){
                    //Show optional status icon
                    $(this).find(".status-icon.success").addClass("hide");
                    $(this).find(".status-icon.optional").removeClass("hide");
                    $(this).find(".status-icon.fail").addClass("hide");
                } else {
                    //Show success status icon
                    $(this).find(".status-icon.success").removeClass("hide");
                    $(this).find(".status-icon.optional").addClass("hide");
                    $(this).find(".status-icon.fail").addClass("hide");
                }

                //Record if any errors have happened
                if(!errors && hasErrors){
                    errors = true;
                }
            });

            //Show to disabled the next button
            if(errors){
                $(".report-generator-panel .buttons .next").addClass("disabled");
            } else {
                $(".report-generator-panel .buttons .next").removeClass("disabled");
            }
            break;
        case 2:
            //Get input value
            let eepValue = $("input[name='energy-efficiency-policy']:checked").val();

            if(typeof eepValue == "undefined"){
                $(".report-generator-panel .buttons .next").addClass("disabled");
            } else {
                $(".report-generator-panel .buttons .next").removeClass("disabled");
            }
            break;
        case 3:
            //Get input value
            let hcValue = $("input[name='heating-control']:checked").val();

            if(typeof hcValue == "undefined"){
                $(".report-generator-panel .buttons .next").addClass("disabled");
            } else {
                if(hcValue === "yes"){
                    //Show system type selection
                    $(".report-generator-panel .heating-control-system-selection").show();

                    const systemSelection = $(".report-generator-panel #heatingControlSystemSelection .button .selected").text();

                    if(systemSelection === "Select an option"){
                        //Disable next button
                        $(".report-generator-panel .buttons .next").addClass("disabled");

                        //Hide inputs
                        $(".report-generator-panel .heating-control-system-heating").hide();
                        $(".report-generator-panel .heating-control-system-cooling").hide();
                    } else {
                        switch(systemSelection){
                            case "Heating only":
                                $(".report-generator-panel .heating-control-system-heating").show();
                                $(".report-generator-panel .heating-control-system-cooling").hide();
                                break;
                            case "Cooling only":
                                $(".report-generator-panel .heating-control-system-heating").hide();
                                $(".report-generator-panel .heating-control-system-cooling").show();
                                break;
                            case "Heating and Cooling":
                                $(".report-generator-panel .heating-control-system-heating").show();
                                $(".report-generator-panel .heating-control-system-cooling").show();
                                break;
                            default:
                                $(".report-generator-panel .heating-control-system-heating").hide();
                                $(".report-generator-panel .heating-control-system-cooling").hide();
                                break;

                        }

                        //Enable next button
                        $(".report-generator-panel .buttons .next").removeClass("disabled");
                    }
                } else {
                    //Hide system type selection
                    $(".report-generator-panel .heating-control-system-selection").hide();

                    //Enable next button
                    $(".report-generator-panel .buttons .next").removeClass("disabled");
                }
            }
            break;
        case 4:
            //Get input value
            let bbValue = $("input[name='benchmark-building']:checked").val();

            if(typeof bbValue == "undefined"){
                $(".report-generator-panel .buttons .next").addClass("disabled");
            } else {
                $(".report-generator-panel .buttons .next").removeClass("disabled");

            }
            break;
        case 5:
            //Get input value
            let asValue = $("input[name='additional-surveys']:checked").val();

            if(typeof asValue == "undefined"){
                $(".report-generator-panel .buttons .finish").addClass("disabled");
            } else {
                $(".report-generator-panel .buttons .finish").removeClass("disabled");

            }
            break;
        case 6:
            if($('.report-generator-panel input#mdYes').is(':checked')){
                //Manual entry count
                let maCount = $(".manual-data-list tbody .data-entry").length;

                if(maCount > 0){
                    $(".report-generator-panel .buttons .finish").removeClass("disabled");
                } else {
                    $(".report-generator-panel .buttons .finish").addClass("disabled");
                }
            } else {
                $(".report-generator-panel .buttons .finish").removeClass("disabled");
            }
            break;
    }
}

function professionalReportGeneratorData() {
    let data = {};

    //Base
    data["id"] = $("#surveyId").val();

    //Page 1
    //Heating Info
    if($("#manualDegreeDaysData").is(":checked")){
        data["manualDegreeDays"] = true;
        data["degreeDays"] = $("#manualDegreeDays").val();
        data["standardisedDegreeDays"] = $("#standardisedDegreeDays").val();
    } else {
        data["manualDegreeDays"] = false;
    }
    data["annualGasConsumption"] = $("#annualGasConsumption").val();
    data["unitGasPricePaid"] = $("#unitGasPricePaid").val();

    //Electricity Consumption
    data["periodStart"] = $("#startPeriod").val();
    data["periodEnd"] = $("#endPeriod").val();
    data["annualElectricityConsumption"] = $("#annualElectricityConsumption").val();
    data["unitPricePaid"] = $("#unitPricePaid").val();

    //Building Information
    data["addressLineOne"] = $("#addressLineOne").val();
    data["addressLineTwo"] = $("#addressLineTwo").val();
    data["city"] = $("#city").val();
    data["postcode"] = $("#postcode").val();
    data["country"] = $(".dropdown#country .button .selected").text();

    data["buildingType"] = $(".dropdown#buildingType .button .selected").text();
    data["floorArea"] = $("#floorArea").val();

    //Page 2
    data["energyEfficiencyPolicy"] = $("input[name='energy-efficiency-policy']:checked").val();

    //Page 3
    data["heatingControl"] = $("input[name='heating-control']:checked").val();
    data["heatingControlSystem"] = $(".dropdown#heatingControlSystemSelection .button .selected").text();
    data["heatingControlSystemHeatingDemand"] = $("input#heatingControlSystemHeatingDemand").val();
    data["heatingControlSystemHeatingSavingsRate"] = $("input#heatingControlSystemHeatingSavingsRate").val();
    data["heatingControlSystemCoolingDemand"] = $("input#heatingControlSystemCoolingDemand").val();
    data["heatingControlSystemCoolingSavingsRate"] = $("input#heatingControlSystemCoolingSavingsRate").val();

    //Page 4
    data["benchmarkBuilding"] = $("input[name='benchmark-building']:checked").val();

    //Page 5
    data["additionalSurveys"] = $("input[name='additional-surveys']:checked").val();

    if(data["additionalSurveys"] === "yes"){
        let surveys = [];
        //Get a list of additional survey's entered
        $(".report-generator-panel .additional-surveys .survey-list input[type=text]").each(function (){
            surveys.push($(this).val().replace('#', ''));
        });
        data["additionalSurveysList"] = surveys;
    }

    //Page 6
    data["manualData"] = $("input[name='manual-data']:checked").val();

    if(data["manualData"] === "yes"){
        let manualData = [];
        //Get a list of additional survey's entered
        $(".report-generator-panel .manual-data .manual-data-list .data-entry").each(function (){
            let rawEntry = [];

            //Compile cells into an array
            $(this).children("td").each(function () {
                rawEntry.push($(this).html());
            });

            //Format array
            let entry = {
                "moduleName": rawEntry[0],
                "annualConsumption": rawEntry[1],
                "annualSavings": rawEntry[2],
                "unitRate": rawEntry[3],
                "capex": rawEntry[4],
            }
            manualData.push(entry);
        });
        data["manualDataList"] = manualData;
    }

    return data;
}

function reportPaymentStatusCheck(){
    let stopCheck = false;
    setInterval(function(){
        if (stopCheck){
            return;
        }
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: {
                data: JSON.stringify({
                    action: "checkPaymentStatus",
                    reportUUID: $(".report-block input[name=uuid]").val()
                })
            },
            success: function(data) {
                switch(data){
                    case "unpaid":
                        break;
                    case "paid":
                        window.location.search = "retrieve=" + $(".report-block input[name=uuid]").val();
                        stopCheck = true;
                        break;
                }
            }
        });
    }, 5000);
}

function resetLightingForm(){
    //Remove any masks on the action bar
    $(".actions-bar-container .error-mask").removeClass("active");

    $(".input#roomReference").hide();
    $(".input input#roomReference").val("");
    $("#enterRoomReference").prop("checked", false);

    $("#emergencyQuantity").hide();
    $("#emergencyQuantity").prop('disabled', false);
    $("#emergencyQuantity").val("");


    $(".dropdown#fittingType .button .selected").text("Select an option");

    $(".dropdown#fixtureType").closest(".input").addClass("disabled");
    $(".dropdown#fixtureType .button .selected").text("Select an option");

    $(".dropdown#tubeType").closest(".input").addClass("disabled");
    $(".dropdown#tubeType .button .selected").text("Select an option");

    $(".dropdown#length").closest(".input").addClass("disabled");
    $(".dropdown#length .button .selected").text("Select an option");

    $(".dropdown#ballast").closest(".input").addClass("disabled");
    $(".dropdown#ballast .button .selected").text("Select an option");

    $(".dropdown#lampCapType").closest(".input").addClass("disabled");
    $(".dropdown#lampCapType .button .selected").text("Select an option");

    $(".dropdown#wattage").closest(".input").addClass("disabled");
    $(".dropdown#wattage .button .selected").text("Select an option");

    $(".dropdown#upgradeType").closest(".input").addClass("disabled");
    $(".dropdown#upgradeType .button .selected").text("Select an option");

    $(".dropdown#colourTemperature").closest(".input").addClass("disabled");
    $(".dropdown#colourTemperature .button .selected").text("Select an option");


    let standardQuantity = $("input#standardQuantity");
    standardQuantity.closest(".input").addClass("disabled");
    standardQuantity.val("").prop("disabled", true);

    let emergencyQuantity = $("input#emergencyQuantity");
    emergencyQuantity.closest(".input").addClass("disabled");
    emergencyQuantity.val("").prop("disabled", true);
    $('#emergencyQuantityToggle').prop('checked', false);

    $(".lightingForm .fluoro-only").slideUp("slow");
    $(".lightingForm .not-fluoro").slideUp("slow");

    //Sensors and detectors

    //Uncheck toggles
    $("#presenceDetectorsQuantityToggle, #daylightSensorQuantityToggle").prop('checked', false);

    //Hide and reset quantity fields
    $("#daylightSensorQuantity, #presenceDetectorsQuantity").hide().val("");

    //Hide room type
    $(".roomTypeInput").hide();

    //Reset room type
    $("#roomType .button .selected").text("Select an option");


    $(".usage-table input").val("");
    if($("#lightsList tbody tr").length > 0) {
        $(".listNoData").remove();
    }

    $("#workWeeks").val(52);
}

function htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

function updateLightingForm(openAll = false) {
    //Fixture list
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "fixtureList",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            options.unshift("Select an option");
            let html = "";
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });
            $(".dropdown#fixtureType .simplebar-content").html(html);
        }
    });

    //Wattage
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "wattageList",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            if(options.length === 0) return;

            options.unshift("Select an option");
            let html = "";
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });
            $(".dropdown#wattage .simplebar-content").html(html);

            //Lamp Cap Type
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "lampCapList",
                        details: {
                            fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                            fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                            wattage: $(".input .dropdown#wattage .button .selected").text()
                        }
                    })},
                success: function (data) {
                    let options = JSON.parse(data);
                    options.unshift("Select an option");
                    let html = "";
                    options.forEach(function(entry) {
                        html = html + "<li>" + entry + "</li>";
                    });
                    $(".dropdown#lampCapType .simplebar-content").html(html);
                }
            });
        }
    });

    //Tube Type
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "tubeTypeList",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            if(options.length === 0) return;
            let html = "";
            options.forEach(function(entry) {
                if(entry === "T5"){
                    html = "<li>" + entry + "</li>" + html;
                } else {
                    html = html + "<li>" + entry + "</li>";
                }
            });
            options.unshift("Select an option");
            $(".dropdown#tubeType .simplebar-content").html(html);

            //Length
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "lengthList",
                        details: {
                            fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                            fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                            tubeType: $(".input .dropdown#tubeType .button .selected").text()
                        }
                    })},
                success: function (data) {
                    let options = JSON.parse(data);
                    options.unshift("Select an option");
                    let html = "";
                    options.forEach(function(entry) {
                        html = html + "<li>" + entry + "</li>";
                    });
                    $(".dropdown#length .simplebar-content").html(html);
                }
            });

            //Ballast
            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: { data: JSON.stringify({
                        action: "ballastList",
                        details: {
                            fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                            fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                            tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                            length: $(".input .dropdown#length .button .selected").text()
                        }
                    })},
                success: function (data) {
                    let options = JSON.parse(data);
                    options.unshift("Select an option");
                    let html = "";
                    options.forEach(function(entry) {
                        html = html + "<li>" + entry + "</li>";
                    });
                    $(".dropdown#ballast .simplebar-content").html(html);
                }
            });

        }
    });

    //Upgrade Type
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "upgradeTypeList",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                    tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                    length: $(".input .dropdown#length .button .selected").text(),
                    ballast: $(".input .dropdown#ballast .button .selected").text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            let html = "";
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });
            $(".dropdown#upgradeType .simplebar-content").html(html);
        }
    });

    //Quantity Types
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "quantityTypes",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                    tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                    length: $(".input .dropdown#length .button .selected").text(),
                    ballast: $(".input .dropdown#ballast .button .selected").text(),
                    wattage: $(".input .dropdown#wattage .button .selected").text(),
                    lampCapType: $(".input .dropdown#lampCapType .button .selected").text(),
                    upgradeType: $(".input .dropdown#upgradeType .button .selected").text()
                }
            })},
        success: function (data) {
            data = JSON.parse(data);
            let standardQuantity = $("input#standardQuantity");
            let emergencyQuantity = $("input#emergencyQuantity");
            if(data.includes("standard")){
                standardQuantity.prop("disabled", false);
                standardQuantity.closest(".input").removeClass("disabled").addClass("next");

                if(data.includes("emergency")){
                    emergencyQuantity.closest(".input").removeClass("disabled").addClass("next");
                } else {
                    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
                }
            } else {
                standardQuantity.prop("disabled", true);
                standardQuantity.closest(".input").addClass("disabled").removeClass("next");

                if(data.includes("emergency")){
                    emergencyQuantity.closest(".input").removeClass("disabled").addClass("next");
                } else {
                    emergencyQuantity.closest(".input").addClass("disabled").removeClass("next");
                }
            }

            if(data.includes("emergency")){
                $("#emergencyQuantityToggle").removeAttr('disabled');
                if(!$(".emergencyQuantityInput").hasClass("disabled")){
                    emergencyQuantity.removeAttr('disabled');
                }

                if(openAll && $(".emergencyQuantityInput").val()){
                    $("#emergencyQuantityToggle").click();
                }
            } else {
                let toggle = $("#emergencyQuantityToggle");
                toggle.prop("disabled", true);
                toggle.prop('checked', false);
                emergencyQuantity.hide();
                emergencyQuantity.prop('disabled', true);
            }
        }
    });

    //Colour Temperatures
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: { data: JSON.stringify({
                action: "colourTemperatures",
                details: {
                    fittingType: $(".input .dropdown#fittingType .button .selected").text(),
                    fixtureType: $(".input .dropdown#fixtureType .button .selected").text(),
                    tubeType: $(".input .dropdown#tubeType .button .selected").text(),
                    length: $(".input .dropdown#length .button .selected").text(),
                    ballast: $(".input .dropdown#ballast .button .selected").text(),
                    wattage: $(".input .dropdown#wattage .button .selected").text(),
                    lampCapType: $(".input .dropdown#lampCapType .button .selected").text(),
                    upgradeType: $(".input .dropdown#upgradeType .button .selected").text()
                }
            })},
        success: function (data) {
            let options = JSON.parse(data);
            options.unshift("Select an option");
            let html = "";
            options.forEach(function(entry) {
                html = html + "<li>" + entry + "</li>";
            });
            $(".dropdown#colourTemperature .simplebar-content").html(html);
        }
    });
}

// function checkTechnologyData(moveToDetails = false){
//     //No technologies
//     if($(".technology-nav .slide-container .item").length < 1){
//         //Update error message
//         $(".actions-bar-container .actions-bottom-bar .message-bar .error-alert p").text("At least one technology must be selected.");
//
//         //Show error message
//         $(".actions-bar-container .actions-bottom-bar .message-bar").removeClass("hide");
//
//         //Disable next button
//         $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", true);
//         return;
//     }
//
//     $.ajax({
//         type: 'POST',
//         url: window.location.href,
//         data: {
//             data: JSON.stringify({
//                 action: "technologyCheck"
//             })
//         },
//         success: function(data) {
//             if(data.allFilled === false){
//                 //Get the first technology's name
//                 let techName = $(".technology-nav .slide-container .swiper-slide .item[data-technology='" + data.missingTechnologies[0] + "'] h2").text();
//
//                 //Remove any errors from technology slider nav
//                 $(".technology-nav .slide-container .swiper-slide .item.error").removeClass("error");
//
//                 //Add error class to all empty techs
//                 const emptyTechCount = data.missingTechnologies.length;
//                 for(let i = 0; i < emptyTechCount; i++){
//                     $(".technology-nav .slide-container .swiper-slide .item[data-technology='" + data.missingTechnologies[i] + "']").addClass("error");
//                 }
//
//                 if(emptyTechCount > 1){
//                     //Update error message
//                     $(".actions-bar-container .actions-bottom-bar .message-bar .error-alert p").text("Multiple technologies are empty. Enter data into all technologies to continue.");
//                 } else {
//                     //Update error message
//                     $(".actions-bar-container .actions-bottom-bar .message-bar .error-alert p").text(techName + " is empty. Enter data into the empty technology to continue.");
//                 }
//
//                 //Show error message
//                 $(".actions-bar-container .actions-bottom-bar .message-bar").removeClass("hide");
//
//                 //Disable next button
//                 $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", true);
//             } else {
//                 //Hide error message
//                 $(".actions-bar-container .actions-bottom-bar .message-bar").addClass("hide");
//
//                 //Enable next button
//                 $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", false);
//
//                 //Remove any errors from technology slider nav
//                 $(".technology-nav .slide-container .swiper-slide .item.error").removeClass("error");
//
//
//                 //Redirect user
//                 if(moveToDetails){
//                     loadContent("details?headless=true", "./details", "Details - Savings Calculator WhatsThePayback");
//                 }
//             }
//         }
//     });
// }


//#endregion












